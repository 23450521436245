import React from "react";
import PropTypes from "prop-types";
import { formatMessage, Main, PaneLayout } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../routes";
import ErrorPage from "../ErrorPage";
import WorkDetails from "./WorkDetails/WorkDetails";
import WorkList from "./WorkList/WorkList";
import {useHomeBreadcrumb} from "./WorkSummary/WorkSummary";
import { useParams } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import {
    useActiveImplementation,
    useActiveOrganization
} from "../../providers/UserProvider";
import useWorkSearchParams from "../components/useWorkSearchParams";

export const useWorkListBreadcrumb = () => {
    const params = useParams();
    const org = useActiveOrganization() || {};
    const impl = useActiveImplementation() || {};
    const [searchParams] = useWorkSearchParams();
    return {
        label: formatMessage({
                id: "workList.breadcrumb",
                defaultMessage: "Case List"},
            {orgName: org.name, implName: impl.name}
        ),
        url: buildUrl(routesKeys.WORK_LIST, params, searchParams)
    }
};

export const useWorkDetailsBreadcrumb = () => {
    const params = useParams();
    const [searchParams] = useWorkSearchParams();
    return {
        label: formatMessage({
                id: "workDetails.breadcrumb",
                defaultMessage: "Work ID - {workId}"},
                {workId: params.workId}
        ),
        url: buildUrl(routesKeys.WORK_DETAILS, params, searchParams)
    };
};

const WorkView = (props) => {
    const {
        isValidURL,
    } = props;
    const params = useParams();
    const {workId} = params;
    const breadcrumbs = [
        useHomeBreadcrumb(),
        useWorkListBreadcrumb()
    ];

    const workDetailsBreadcrumb = useWorkDetailsBreadcrumb();
    if(workId) {
        breadcrumbs.push(workDetailsBreadcrumb);
    }
    if(!isValidURL) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }

    let body = (
        <PaneLayout>
            <WorkList {...props} />
            <WorkDetails {...props} />
        </PaneLayout>
    );

    return (
        <PageLayout>
            <Main
                breadcrumbs={breadcrumbs}
                fullWidth={true}
                fullHeight={true}
            >
                {body}
            </Main>
        </PageLayout>
    );
};

WorkView.propTypes = {
    organization: PropTypes.object,
    implementation: PropTypes.object,
    isLoading: PropTypes.bool,
};

const Work = (props) => {
    const org = useActiveOrganization();
    const impl = useActiveImplementation();
    const state = {
        isValidURL: !!org && !!impl,
    };
    return (
        <WorkView View {...props} {...state} />
    );
};

Work.secured = {
    implKey: "adas"
};
export default Work;