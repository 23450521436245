import React from "react";
import StatusChip from "../components/StatusChip";
import config from "../../../../config";
import { buildUrl } from "../../../../routes";
import routeKeys from "../../../../routes";
import Notes from "../components/Notes";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Check";
import CopyIcon from "@mui/icons-material/FileCopy";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { Text, formatMessage, SmartLink, InfoChip, SectionExpando, useNotifications, makeSxStyles } from "@armus/armus-dashboard";
import {safeDateFormat} from "../../../lib/dateHelpers";
import VerificationStatusChip from "../components/VerificationStatusChip";
import { useWorkDetailsTransition, transitionToTypes } from "../../../providers/WorkDetailsTransitionProvider";

const formatSSN = (ssn) => {
    if(!ssn) {
        return null;
    }
    return ssn.substr(0,3) + "-" + ssn.substr(3,2) + "-" + ssn.substr(5);
};

const DataItem = ({title, value}) => {
    const notifications = useNotifications();
    if(value === undefined || value === null || value === "") {
        return null;
    }
    const canCopy = typeof value === "string" && !!navigator && !!navigator.clipboard && !!navigator.clipboard.writeText;
    const onCopy = () => {
        if(canCopy) {
            navigator.clipboard.writeText(value);
            notifications.onPush({
                key: "copy", 
                message: `Successfully copied "${title}" value.`, 
                type: "info"
            });
        }
    }
    return (
        <Box className={"DataItem"}>
            <Box className={"DataItemTitle"}>{title}:</Box>
            <Box className={"DataItemValue" + (canCopy ? " canCopy" : "")} onClick={onCopy}>
                {value}
                {canCopy && <span onClick={onCopy} className="copyButton">&nbsp;<IconButton size="small"><CopyIcon fontSize="small" /></IconButton></span>}
            </Box>
        </Box>
    );
};

const useDataItemEditStyles = makeSxStyles((theme) => ({
    root: {
        position: "relative",
        height: 50,
        verticalAlign: "middle",
        "& input": {
            width: "calc( 100% - 80px)",
        },
        "& .DataItemValue": {
            marginTop: 12,
        }
    },
    editValue: {
        display: "inline-block",
        marginRight: theme.spacing(1),
        cursor: "pointer",
    },
    editAction: {
        display: "inline-block",
    },
    actions: {
        position: "absolute",
        top: theme.spacing(1),
        right: 0,
        width: 65,
        paddingLeft: 5,
        borderLeft: "1px solid " + theme.palette.Boxider,
        height: 37.28,
        "& button": {
            marginTop: 5
        }
    }
}));
const DataItemEditable = ({canEdit, title, value, onEdit, onChange}) => {
    const classes = useDataItemEditStyles();
    const [state, setState] = React.useState({isEditing: false, value: value});
    if(!canEdit) {
        return (
            <DataItem
                title={title}
                value={value}
            />
        );
    }
    if(value === null) {
        return null;
    }
    
    const handleEditClick = () => {
        onEdit && onEdit();
        if(onChange) {
            setState({...state, isEditing: true, value: value})
        }
    }

    if(canEdit && state.isEditing) {
        return (
            <Box className={"DataItem"}>
                <Box className={"DataItemTitle"}>{title}:</Box><br />
                <Box className={""}>
                    <Box sx={classes.root}>
                        <Text
                            descriptor={{
                                id: title, label: "", config: {dense: true}}}
                            value={state.value}
                            onChange={(e, v) => setState({...state, value: v})}
                        />
                        <Box sx={classes.actions}>
                            <IconButton
                                key="save"
                                title={formatMessage({
                                    id: "workDetails.dataItemEditable.saveButton.titleLabel",
                                    defaultMessage: "Save"
                                })}
                                size={"small"}
                                color={"primary"}
                                onClick={() => {
                                    onChange(state.value);
                                    setState({...state, isEditing: false});
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                            <IconButton
                                key="cancel"
                                title={formatMessage({
                                    id: "workDetails.dataItemEditable.cancelButton.titleLabel",
                                    defaultMessage: "Cancel"
                                })}
                                size={"small"}
                                color={"default"}
                                onClick={() => setState({...state, isEditing: false, value: value})}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
    return (
        <Box className={"DataItem"}>
            <Box className={"DataItemTitle"}>{title}:</Box>
            <Box className={"DataItemValue"}>
                <Box sx={classes.editValue}>
                    <Link
                        key="edit"
                        title={formatMessage({
                            id: "workDetails.dataItemEditable.editButton.titleLabel",
                            defaultMessage: "Edit"
                        })}
                        onClick={handleEditClick}
                    >
                        {value} <EditIcon />
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};


const WorkDetailsBody = (props) => {
    const {
        classes,
        workDetails,
        isMyWork,
        orgKey,
        implKey,
        searchParams,
        canAbandon,
        onAbandon,
        onAddNote,
        readOnly,
        variant
    } = props;
    const {onStartTransition} = useWorkDetailsTransition();
    return (
        <Box sx={classes.content}>
            <Box p={2} pl={1}>
                <SectionExpando
                    className="workDetailsWorkInfo"
                    title={formatMessage({
                        id: "workDetails.body.section.workDetails.titleLabel",
                        defaultMessage: "Work Details"
                    })}
                    mb={3}
                    indentContent={2}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.workStatusLabel",
                                    defaultMessage: "Work Status"
                                })}
                                value={<StatusChip status={workDetails.status} size={"xs"}
                                />}
                            />
                            <DataItemEditable
                                canEdit={isMyWork && !readOnly && variant === "abstractor"}
                                title={formatMessage({
                                    id: "workDetails.body.registryGeneratedId.titleLabel",
                                    defaultMessage: workDetails.abstractable.registry.registryGeneratedIdLabel || "Registry Generated ID"
                                })}
                                onEdit={() => {
                                    onStartTransition(transitionToTypes.EDIT_REGISTRY_GENERATED_ID);
                                }}
                                value={workDetails.registryGeneratedId}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.verificationStatusLabel",
                                    defaultMessage: "Verification Status"
                                })}
                                value={<VerificationStatusChip status={workDetails.verificationStatus} size={"xs"}
                                />}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.verificationMethod",
                                    defaultMessage: "Verification Method"
                                })}
                                value={workDetails.abstractable.verificationMethod === "MANUAL" ? "Manual" : "Automatic"}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.type",
                                    defaultMessage: "Type"
                                })}
                                value={(
                                    <React.Fragment>
                                        <Link href={workDetails.expandedUrl} target={"_blank"}>
                                            {workDetails.type} <LinkIcon />
                                        </Link>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sx={classes.workUser}>
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.AbstractorLabel",
                                    defaultMessage: "Abstractor"
                                })}
                                value={
                                    workDetails.assignedAbstractor ? (
                                        canAbandon && !readOnly ? (
                                            <Link
                                                sx={classes.abandonButton}
                                                onClick={onAbandon}
                                                title={formatMessage({
                                                    id: "workDetails.body.abandonButton.titleLabel",
                                                    defaultMessage: "Abandon Work."
                                                })}
                                            >
                                                <AccountIcon/>&nbsp;{workDetails.assignedAbstractor.username}&nbsp;<CloseIcon/>
                                            </Link>
                                        ) : (
                                            <span><AccountIcon/>&nbsp;{workDetails.assignedAbstractor.username}</span>
                                        )
                                    ):(
                                        <span sx={classes.unassignedUser}>
                                            <AccountIcon/>&nbsp;Unassigned
                                        </span>
                                    )
                                }
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.createDateLabel",
                                    defaultMessage: "Create Date"
                                })}
                                value={safeDateFormat(workDetails.createdAt, config.ui.dateTimeFormat)}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.visibleAfter",
                                    defaultMessage: "Visible After"
                                })}
                                value={safeDateFormat(workDetails.visibleAfter, undefined, true)}
                            />
                        </Grid>
                    </Grid>
                </SectionExpando>
                <SectionExpando
                    className="workDetailsCaseInfo"
                    title={formatMessage({
                        id: "workDetails.body.section.caseSummary.titleLabel",
                        defaultMessage: "Case Summary"
                    })}
                    mb={3}
                    indentContent={2}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.organizationLabel",
                                    defaultMessage: "Organization"
                                })}
                                value={workDetails.associatedCase.organization.longname}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.patientStatusLabel",
                                    defaultMessage: "Patient Status"
                                })}
                                value={
                                    <InfoChip
                                        color={"default"}
                                        title={workDetails.associatedCase.patientStatus}
                                        label={workDetails.associatedCase.patientStatus}
                                        variant={"outlined"}
                                        size={"xs"}
                                    />
                                }
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.firstNameLabel",
                                    defaultMessage: "First Name"
                                })}
                                value={workDetails.associatedCase.firstName}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.middleNameLabel",
                                    defaultMessage: "Middle Name"
                                })}
                                value={workDetails.associatedCase.middleName}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.lastNameLabel",
                                    defaultMessage: "Last Name"
                                })}
                                value={workDetails.associatedCase.lastName}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.ssnLabel",
                                    defaultMessage: "SSN"
                                })}
                                value={formatSSN(workDetails.associatedCase.ssn)}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.foreignSourceIdLabel",
                                    defaultMessage: "Foreign Source ID"
                                })}
                                value={workDetails.associatedCase.foreignSourceId}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.mrnLabel",
                                    defaultMessage: "MRN"
                                })}
                                value={workDetails.associatedCase.mrn}
                            />
                            <DataItem
                                title={
                                    formatMessage({
                                        id: "workDetails.body.data.hospitalNPILabel",
                                        defaultMessage: workDetails.associatedCase.organization.sourceGroupIDLabel || "Hospital NPI"
                                    })}
                                value={workDetails.associatedCase.sourceGroupId}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.admitDateLabel",
                                    defaultMessage: "Admit Date"
                                })}
                                value={safeDateFormat(workDetails.associatedCase.admitDate, undefined, true)}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.admitTimeLabel",
                                    defaultMessage: "Admit Time"
                                })}
                                value={
                                    safeDateFormat(workDetails.associatedCase.admitDate+"T"+workDetails.associatedCase.admitTime+".000Z", config.ui.timeFormat, true)
                                }
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.dischargeDateLabel",
                                    defaultMessage: "Discharge Date"
                                })}
                                value={safeDateFormat(workDetails.associatedCase.dischargeDate, undefined, true)}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.dischargeTimeLabel",
                                    defaultMessage: "Discharge Time"
                                })}
                                value={
                                    safeDateFormat(workDetails.associatedCase.dischargeDate+"T"+workDetails.associatedCase.dischargeTime+".000Z", config.ui.timeFormat, true)
                                }
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.initialCodingDateLabel",
                                    defaultMessage: "Initial Coding Date"
                                })}
                                value={safeDateFormat(workDetails.associatedCase.initialCodingDate, undefined, true)}
                            />
                            <DataItem
                                title={formatMessage({
                                    id: "workDetails.body.data.lastCodingDateLabel",
                                    defaultMessage: "Last Coding Date"
                                })}
                                value={safeDateFormat(workDetails.associatedCase.lastCodingDate, undefined, true)}
                            />
                        </Grid>
                    </Grid>
                </SectionExpando>
                {workDetails.siblingList.length > 0 && (
                    <SectionExpando
                        className="workDetailsSiblings"
                        title={formatMessage({
                            id: "workDetails.body.section.otherWork.titleLabel",
                            defaultMessage: "Other work in this case"
                        })}
                        mt={0}
                        mb={3}
                        indentContent={2}
                    >
                        {workDetails.siblingList.map((it) => {
                            return (
                                <Grid container key={it.id} sx={classes.siblingRow}>
                                    <Grid item xs={5}>
                                        <SmartLink
                                            to={buildUrl(routeKeys.WORK_DETAILS, {orgKey, implKey, workId: it.id}, searchParams)}
                                        >
                                            {it.abstractableLongname}
                                        </SmartLink>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <StatusChip status={it.status} size={"xs"} />
                                        {it.verificationStatus !== "PENDING_REVIEW" && <VerificationStatusChip status={it.verificationStatus} size={"xs"} />}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {it.assignedAbstractor ? (
                                            <span><AccountIcon/>&nbsp;{it.assignedAbstractor.username}</span>
                                        ):(
                                            <span sx={classes.unassignedUser}>
                                                    <AccountIcon/>&nbsp;Unassigned
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </SectionExpando>
                )}
                <Box className="workDetailsNotes">
                    <Notes
                        title={formatMessage({
                            id: "workDetails.body.section.workNotes.titleLabel",
                            defaultMessage: "Work Notes"
                        })}
                        items={workDetails.notes}
                        onAdd={onAddNote}
                        canAdd={isMyWork && !readOnly}
                        status={workDetails.status}
                    />
                    <Notes
                        title={formatMessage({
                            id: "workDetails.body.section.caseNotes.titleLabel",
                            defaultMessage: "Case Notes"
                        })}
                        items={workDetails.associatedCase.notes}
                        canAdd={false}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default WorkDetailsBody;