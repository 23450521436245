import React from "react";
import ErrorBoundary from "./views/components/ErrorBoundary";
import OuterTemplate from "./OuterTemplate";
import {Router} from "react-router-dom";
import {
    IntlProvider,
    DashboardProvider,
    defaultThemes,
    DatePickersProvider,
    DialogsProvider,
    NotificationsProvider,
    OnboardingProvider
} from "@armus/armus-dashboard";
import messages from "../messages";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import history from "./lib/history";
import UserProvider from "./providers/UserProvider";
import QueryProvider from "./providers/QueryProvider";
import "./index.scss";
/*
    Set up all context providers
*/
const App = () => (
    <IntlProvider locale="en" messages={messages}>
        <DashboardProvider themes={defaultThemes}>
            <Router history={history}>
                <NotificationsProvider>
                    <QueryProvider>
                        <DatePickersProvider>
                            <DialogsProvider>
                                <CssBaseline />
                                <ErrorBoundary>
                                    <UserProvider>
                                        <OnboardingProvider appKey="armus-adam">
                                            <OuterTemplate />
                                        </OnboardingProvider>
                                    </UserProvider>
                                </ErrorBoundary>
                            </DialogsProvider>
                        </DatePickersProvider>
                    </QueryProvider>
                </NotificationsProvider>
            </Router>
        </DashboardProvider>
    </IntlProvider>
);

export default App;