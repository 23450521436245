import React, { createContext, useContext } from "react";
import { useParams } from "react-router";
import { useAdminOrganizationMutation } from "../lib/mutations";
import { useAdminOrganizationQuery, useAdminRegistriesQuery } from "../lib/queries";

export const AdminOrganizationContext = createContext({
  __query: {data: [], isSuccess: false},
  __mutation: {},
});



function AdminOrganizationProvider({ children }) {
  const __organizationQuery = useAdminOrganizationQuery();
  const __registryQuery = useAdminRegistriesQuery();
  const __organizationMutation = useAdminOrganizationMutation();
  const ctx = {
    __organizationQuery,
    __organizationMutation,
    __registryQuery,
  };
  return (
    <AdminOrganizationContext.Provider value={ctx}>
      {children}
    </AdminOrganizationContext.Provider>
  );
}

export default AdminOrganizationProvider;

const getOrganization = ({data}, id) => {
  if(id === "new") {
    return {
      id: "new",
      shortname: "",
      sourceGroupIDLabel: "",
      registryParticipation: []
    };
  }
  if(Array.isArray(data)) {
    return data.find((it) => it.id === id);
  }
  return null;
};

export const useAdminOrganization = () => {
  const { organizationId } = useParams();
  const ctx = useContext(AdminOrganizationContext);
  const orgId = organizationId !== "new" ? parseInt(organizationId, 10) : organizationId;
  const [organization, setOrganization] = React.useState(getOrganization(ctx.__organizationQuery, orgId));
  const isSuccess = !ctx.__organizationQuery.isLoading && ctx.__organizationQuery.isSuccess;
  React.useEffect(() => {
    if(isSuccess && orgId !== undefined) {
      if(organization === null || organization?.id !== orgId) {
        setOrganization(getOrganization(ctx.__organizationQuery, orgId));
      }
    }
    else {
      setOrganization(null);
    }
  }, [
    organization,
    ctx.__organizationQuery,
    orgId,
    isSuccess
  ]);
  return {
    organizationId,
    organization: organization,
    setOrganization: setOrganization,
    onSaveOrganization: () => ctx.__organizationMutation.mutate(organization),
    registryQuery: ctx.__registryQuery,
    organizationQuery: ctx.__organizationQuery,
    organizationMutation: ctx.__organizationMutation,
  }
};
