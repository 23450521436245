import React from "react";
import { useLocation } from "react-router-dom";
import history from "../../lib/history";
import qs from "query-string";
import { WorkFiltersContext } from "../../providers/WorkFiltersProvider";

export default function useWorkSearchParams() {
    const {defaultValues, lockedValues} = React.useContext(WorkFiltersContext);
    const {search} = useLocation();

    const updateUrlSearchParams = (query) => {
        Object.keys(query).forEach(key => {
            if(query[key] === "") {
                // remove the empty value filters to keep the url clean.
                delete query[key];
            }
        });
        history.push("?" + qs.stringify(query));
    };

    const value = React.useMemo(() => {
            const params = {
                // Default filter keys and values.
                // pagination
                page: 0,
                size: 25,

                // sorts
                order: "desc",
                orderBy: "maxCreated",

                // filters
                scope: "all",
                admitAfter: "",
                admitBefore: "",
                createdAtAfter: "",
                createdAtBefore: "",
                mrn: "",
                registryGeneratedId: "",
                status: "",
                verificationStatus: "",
                organization: "",
                abstractable: "",
                verificationMethod: "",
                
                // case filters
                allowNoWork: "true",
                
                // spread the default values
                ...defaultValues,
                // spread the client url params here
                ...(qs.parse(search || "") || {}),
                // spread the locked values
                ...lockedValues
            };
            if(params.createdAtAfter !== "") {
                params.createdAtAfter = parseInt(params.createdAtAfter, 10);
            }
            if(params.createdAtBefore !== "") {
                params.createdAtBefore = parseInt(params.createdAtBefore, 10);
            }
            if(params.abstractable !== "") {
                if(!Array.isArray(params.abstractable)) {
                    // should be an array
                    params.abstractable = [params.abstractable];
                }
                // is an array of long ids
                params.abstractable = params.abstractable.map((v) => parseInt(v, 10));
            }
            if(params.organization !== "") {
                if(!Array.isArray(params.organization)) {
                    // should be an array
                    params.organization = [params.organization];
                }
            }
            params.page = parseInt(params.page, 10);
            params.size = parseInt(params.size, 10);
            return params;
        },
        [search, defaultValues, lockedValues]
    );

    const lockedVlaueKeys = Object.keys(lockedValues);

    let activeFiltersCount = 0;
    [
        "organization",
        "abstractable",
        "admitAfter",
        "admitBefore",
        "createdAtAfter",
        "createdAtBefore",
        "mrn",
        "status",
        "verificationStatus",
        "allowNoWork"
    ].forEach((key) => {
        let v = value[key];
        if(v !== "" && v !== null && !lockedVlaueKeys.includes(key)) {
            activeFiltersCount++;
        }
    });

    const setValue = (key, v) => {
        let valueChanged = false;
        const searchParams = value;
        let spreadValue = {};

        if(typeof key === "object") {
            // multi key value
            spreadValue = key;
            Object.keys(spreadValue).forEach((key) => {
                if(spreadValue[key] !== searchParams[key]) {
                    valueChanged = true;
                }
            });
        }
        else {
            // single key value
            spreadValue[key] = v;
            if (searchParams[key] !== v) {
                valueChanged = true;
            }
            if (key === "orderBy") {
                valueChanged = true;
                spreadValue.order = 'asc';
                if (searchParams.orderBy === spreadValue.orderBy) {
                    if (searchParams.order === 'desc') {
                        // remove sort
                        spreadValue.orderBy = "maxCreated";
                        spreadValue.order = "asc";
                    }
                    else if (searchParams.order === 'asc') {
                        spreadValue.order = 'desc';
                    }
                }
            }
            if(key !== "page") {
                spreadValue.page = 0;
            }
        }


        if(valueChanged) {
            const query = {
                ...searchParams,
                ...spreadValue,
                // overwrite the locked values
                ...lockedValues
            };
            updateUrlSearchParams(query);
        }
    };
    return [value, activeFiltersCount, setValue];
}