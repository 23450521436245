import config from "../../config";
import moment from "moment-timezone";

export const safeDateFormat = (d, format = config.ui.dateFormat, notimezone = false) => {
    const md = moment(d || null);
    if(md.isValid()) {
        if(notimezone) {
            return md.utc().add(md.utcOffset(), 'm').format(format);
        }
        return md.format(format);
    }
    return null;
};


export const getMonthNameFromInt = (month, longName = true) => {
    // months are 0-11
    const date = new Date(2000, month, 1);  // 2000-{month}-01
    return date.toLocaleString('default', { month: longName ? 'long' : 'short' });
};