import React from "react";
import { formatMessage, Select, Spinner, DatePicker, makeSxStyles } from "@armus/armus-dashboard";
import { useWorkFiltersQuery } from "../../../lib/queries";
import useReportingSearchParams
    from "../../components/useReportingSearchParams";
import Grid from "@mui/material/Grid/Grid";
import config from "../../../../config";
import moment from "moment-timezone";

const useStyles = makeSxStyles((theme) => ({
    root: {
    },
}));

const ReportingFiltersView = (props) => {
    const {
        filterData,
        isLoading,
        searchParams,
        onSetFilter,
    } = props;
    const classes = useStyles();

    const handleDateChange = (key, value) => {
        if(!moment(value).isValid()) {
            // invalid date
            value = "";
        }
        else {
            value = moment(value).format("YYYY-MM-DD");
        }
        onSetFilter(key, value);
    };

    if(isLoading) {
        return (
            <Spinner />
        );
    }
    return (
        <Grid container spacing={1} sx={classes.root}>
            <Grid item xs={2}>
                <Select
                    descriptor={{
                        id: "abstractable",
                        label: formatMessage({id: "reporting.filters.typeInputLabel", defaultMessage: "Type"}),
                        config: {
                            multivalued: true,
                            dense: true
                        }
                    }}
                    value={searchParams.abstractable}
                    data={filterData.abstractables || []}
                    onChange={(e, v) => onSetFilter("abstractable", v)}
                />
            </Grid>
            <Grid item xs={2}>
                <Select
                    descriptor={{
                        id: "dateType",
                        label: formatMessage({id: "reporting.filters.dateTypeInput", defaultMessage: "Date Type"}),
                        config: {
                            multivalued: false,
                            dense: true
                        }
                    }}
                    onChange={(e, v) => onSetFilter("dateType", v)}
                    value={searchParams.dateType || null}
                    data={[
                        {
                            label: "Date Created",
                            value: "createDate",
                        },
                        {
                            label: "Date Last Modified",
                            value: "lastModifiedDate",
                        },
                        {
                            label: "Date That Matters",
                            value: "dateThatMatters",
                        }
                    ]}
                />
            </Grid>
            <Grid item xs={2}>
                <DatePicker
                    descriptor={{
                        id: "afterDate",
                        label: formatMessage({id: "reporting.filters.admitAfterInputLabel", defaultMessage: "Admitted Before"}),
                        config: {
                            format: config.ui.dateFormatInput,
                            dense: true,
                            disableTime: true,
                        }
                    }}
                    onChange={(e, v) => handleDateChange("afterDate", v)}
                    value={searchParams.afterDate ? moment(searchParams.afterDate) : null}
                    helperText={""}
                    error={false}
                    disableFuture={true}
                />
            </Grid>
            <Grid item xs={2}>
                <DatePicker
                    descriptor={{
                        id: "beforeDate",
                        label: formatMessage({id: "reporting.filters.beforeDateInputLabel", defaultMessage: "Admitted Before"}),
                        config: {
                            format: config.ui.dateFormatInput,
                            dense: true,
                            disableTime: true,
                        }
                    }}
                    onChange={(e, v) => handleDateChange("beforeDate", v)}
                    value={searchParams.beforeDate ? moment(searchParams.beforeDate) :  null}
                    helperText={""}
                    error={false}
                    disableFuture={true}
                />
            </Grid>
        </Grid>
    );
};

const ReportingFilters = (props) => {
    const [searchParams, activeFiltersCount, onSetFilter] = useReportingSearchParams();
    const filterQuery = useWorkFiltersQuery();
    const state = {
        filterData: filterQuery.data,
        isLoading: filterQuery.isLoading,
        searchParams,
        numberOfAppliedFilters: activeFiltersCount,
        onSetFilter,
    };

    return (
        <ReportingFiltersView
            {...props} {...state}
        />
    );
};

export default ReportingFilters;
