import React from "react";
import { alpha, Grid } from "@mui/material";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import { useWorkFiltersQuery } from "../../../lib/queries";
import { buildFilterInfo } from "../../components/workFilterHelpers";
import { Button, InfoChip, makeSxStyles } from "@armus/armus-dashboard";
import history from "../../../lib/history";

const useStyles = makeSxStyles((theme) => ({
  root: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: alpha(theme.palette.primary.main, 0.5),
    height: 37,
    padding: 0,
    paddingLeft: 2,
    paddingRight: 2,
    overflow: "hidden",
    lineHeight: 2.4,
    color: theme.palette.primary.main,
  },
  removeAllContainer: {
    textAlign: "right",
  },
}));

const WorkListFiltersSummary = (props) => {
  const classes = useStyles();
  const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams(props.defaultValues, props.lockedValues);
  const filterQuery = useWorkFiltersQuery();

  if (filterQuery.isLoading) {
    return null;
  }
  const lockedVlaueKeys = Object.keys(props.lockedValues);
  const filterInfo = buildFilterInfo(filterQuery.data, searchParams);
  const appliedFilters = Object.keys(filterInfo)
    .map((key) => ({ key, ...filterInfo[key] }))
    .filter((it) => it.value.value !== "" && it.visible && !lockedVlaueKeys.includes(it.key));

  return (
    <Grid container sx={classes.root}>
      <Grid item xs={10}>
        {activeFiltersCount} Filters Applied:{" "}
        {appliedFilters.filter(it => it.visible).map((filter, index) => {
          return (
            <InfoChip
              key={filter.label}
              size="small"
              variant="outlined"
              color="primary"
              title={filter.value.label}
              label={
                filter.label +
                ": " +
                (filter.value.label.length > 15
                  ? filter.value.label.substring(0, 15) + "..."
                  : filter.value.label)
              }
              onDelete={() => {
                onSetFilter(filter.key, filter.key === "allowNoWork" ?  "false" : "");
              }}
            />
          );
        })}
      </Grid>
      <Grid item xs={2} sx={classes.removeAllContainer}>
        <Button color={"info"} onClick={() => history.push("?")} dense={true}>
          Reset Filters
        </Button>
      </Grid>
    </Grid>
  );
};

export default WorkListFiltersSummary;
