import React from "react";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormattedMessage, formatMessage, Spinner, Main, SmartLink, useOnboarding, makeSxStyles } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../../routes";
import { getStatusInfo } from "../../components/workFilterHelpers";
import Gauge from "./components/Gauge";
import PageLayout from "../../components/PageLayout";
import WorkCard from "./components/WorkCard";
import { useParams } from "react-router-dom";
import { useWorkSummaryQuery } from "../../../lib/queries";
import {
    useActiveImplementation,
    useActiveOrganization,
    useDisplayName,
} from "../../../providers/UserProvider";
import { summaryViewSteps } from "../../../../onboardingSteps";

const useStyles = makeSxStyles((theme) => ({
    root: {
    },
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    statusPaper: {
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        textAlign: "center",
        "& > div > a": {
            width: "100%",
            display: "inline-block"
        },
        "& > div > a:nth-child(n+2)": {
            width: "50%",
            display: "inline-block"
        }
    },
    registryPaper: {
        padding: theme.spacing(2),
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    workListContainer: {
    },
    workList: {
        padding: theme.spacing(2),
        background: theme.palette.mode === "dark" ? "rgba(0,0,0,.15)" : "rgba(0,0,0,.04)",
        borderRadius: theme.spacing(.5),
    },
    diamond: {
        zIndex: 0,
        position: "absolute",
        top: "CALC(50% - 175px)",
        left: "CALC(50% - 175px)",
        width: 350,
        height: 350,
        border: "3px solid rgba(127,127,127, .15)",
        borderRadius: 350,
    }
}));

export const useHomeBreadcrumb = () => {
    const org = useActiveOrganization() || {};
    const impl = useActiveImplementation() || {};
    const userName = useDisplayName();
    return {
        label: formatMessage({
            id: "home.breadcrumb",
            defaultMessage: "{userName}'s Work Summary"},
            {orgName: org.name, implName: impl.name, userName: userName }
        ),
        url: buildUrl(routesKeys.WORK_SUMMARY, {orgKey: org.key, implKey: impl.key})
    }
};

const HomeView = ({
    isValidURL,
    organization,
    implementation,
    isLoading,
    isLoaded,
    summaryData,
    searchParams,
    userName,
}) => {
    const classes = useStyles();
    const params = useParams();
    const breadcrumb = useHomeBreadcrumb();
    let body;
    if(isLoading) {
        body = (
            <Box height={"100%"}>
                <Spinner/>
            </Box>
        );
    }
    else if(isLoaded) {
        const total = summaryData.total;
        const hasActiveWork = !!summaryData.lastModifiedWork || summaryData.inProgressWork.length > 0 || summaryData.requiresInformationWork.length > 0;
        body = (
                <Grid container spacing={2}>
                    <Grid item xs={7} className={"workActivity"} sx={classes.workListContainer}>
                        {summaryData.failedVerificationWork.length > 0 && <Box mb={3} sx={classes.workList}>
                            <strong>
                                <FormattedMessage
                                    id={"home.failedVerification.title"}
                                    defaultMessage={"Failed Verification Work Items ({count})"} defaultValues={{count: 0}} values={{count: summaryData.failedVerificationWork.length}} />
                            </strong>
                            <br/>
                            {
                                summaryData.failedVerificationWork.filter(
                                        (it, index) => index < 2
                                    )
                                    .map((it) => (
                                        <WorkCard key={it.id} data={it} />
                                    ))
                            }
                            <Box textAlign={"center"} mt={3}>
                                <SmartLink to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork", verificationStatus: "FAILED"})}>
                                    <FormattedMessage
                                        id={"home.failedVerification.continueLinkLabel"}
                                        defaultMessage={"Click here to view all your \"Failed Verification\" Work Items"}
                                    />
                                </SmartLink>
                            </Box>
                        </Box>}
                        {!hasActiveWork && (
                            <Box mb={3} sx={classes.workList}>
                                <strong>You currently do not have any active work items</strong>
                                <br/>
                                <Box textAlign={"center"} mt={12} mb={12}>
                                    <SmartLink to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "unassigned"})}>
                                        Click Here to View All "Unassigned" Work Items
                                    </SmartLink>
                                </Box>
                            </Box>
                        )}

                        {!!summaryData.lastModifiedWork && <Box mb={3} sx={classes.workList}>
                            <React.Fragment>
                                <strong>
                                    <FormattedMessage
                                        id={"home.lastModified.title"}
                                        defaultMessage={"Pick up where you left off"}
                                    />
                                </strong>
                                <br/>
                                <WorkCard data={summaryData.lastModifiedWork} showDetails={true} />
                                <Box textAlign={"center"} mt={3}>
                                    <SmartLink to={buildUrl(routesKeys.WORK_DETAILS, {...params, workId: summaryData.lastModifiedWork.id})}>
                                        <FormattedMessage
                                            id={"home.lastModified.continueLinkLabel"}
                                            defaultMessage={"Click here to continue work on the item above."}
                                        />
                                    </SmartLink>
                                </Box>
                            </React.Fragment>
                        </Box>}
                        {summaryData.inProgressWork.length > 0 && <Box mb={3} sx={classes.workList}>
                            <strong>
                                <FormattedMessage
                                    id={"home.inProgress.title"}
                                    defaultMessage={"In Progress Work Items ({count})"} defaultValues={{count: 0}} values={{count: summaryData.inProgressWork.length}} />
                            </strong>
                            <br/>
                            {
                                summaryData.inProgressWork.filter(
                                        (it, index) => index < 2
                                    )
                                    .map((it) => (
                                        <WorkCard key={it.id} data={it} />
                                    ))
                            }

                            <Box textAlign={"center"} mt={3}>
                                <SmartLink to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork", status: "IN_PROGRESS"})}>
                                    <FormattedMessage
                                        id={"home.inProgress.continueLinkLabel"}
                                        defaultMessage={"Click here to view all your \"In Progress\" Work Items"}
                                    />
                                </SmartLink>
                            </Box>
                        </Box>}
                        {summaryData.newWork.length > 0 && <Box mb={3} sx={classes.workList}>
                            <strong>
                                <FormattedMessage
                                    id={"home.assignedWork.title"}
                                    defaultMessage={"New Work Items ({count})"} defaultValues={{count: 0}} values={{count: summaryData.newWork.length}} />
                            </strong>
                            <br/>
                            {
                                summaryData.newWork.filter(
                                    (it, index) => index < 2
                                )
                                    .map((it) => (
                                        <WorkCard key={it.id} data={it} />
                                    ))
                            }

                            <Box textAlign={"center"} mt={3}>
                                <SmartLink to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork", status: "NEW"})}>
                                    <FormattedMessage
                                        id={"home.assignedWork.continueLinkLabel"}
                                        defaultMessage={"Click here to view your \"New\" Work Items"}
                                    />
                                </SmartLink>
                            </Box>
                        </Box>}
                        {summaryData.requiresInformationWork.length > 0 && <Box mb={3} sx={classes.workList}>
                            <strong>
                                <FormattedMessage
                                    id={"home.requiresInformation.title"}
                                    defaultMessage={"Requires Information Work Items ({count})"} defaultValues={{count: 0}} values={{count: summaryData.requiresInformationWork.length}} />
                            </strong>
                            <br/>
                            {
                                summaryData.requiresInformationWork.filter(
                                        (it, index) => index < 2
                                    )
                                    .map((it) => (
                                        <WorkCard key={it.id} data={it} />
                                    ))
                            }
                            <Box textAlign={"center"} mt={3}>
                                <SmartLink to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork", status: "REQUIRES_INFORMATION"})}>
                                    <FormattedMessage
                                        id={"home.requiresInformation.continueLinkLabel"}
                                        defaultMessage={"Click here to view all your \"Requires Information\" Work Items"}
                                    />
                                </SmartLink>
                            </Box>
                        </Box>}
                    </Grid>
                    <Grid item xs={5}>
                        <Box overflow={"auto"}>
                            <Paper className={" workListQuickLinks"} sx={classes.paper} >
                                <Typography component="h1" variant="h4">
                                    <FormattedMessage
                                        id="home.title"
                                        defaultMessage={"{userName}'s Work Summary"}
                                        values={{orgName: organization.name, implName: implementation.name, userName: userName}}
                                    />
                                </Typography>
                                <Typography component="div" sx={classes.subtitle} variant="subtitle1">
                                    <FormattedMessage
                                        id={"home.subTitle"}
                                        defaultMessage={"Welcome to your work dashboard. Use the lists to the left to pick up where you left off. Use the links below to browse for other work items."}
                                    />
                                </Typography>
                                <ul>
                                    <li>
                                        <SmartLink
                                            to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork"})}
                                        >
                                            <FormattedMessage
                                                id={"home.links.myWork"}
                                                defaultMessage={"My Work"}
                                            />
                                        </SmartLink>
                                    </li>
                                    <li>
                                        <SmartLink
                                            to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "unassigned"})}
                                        >
                                            <FormattedMessage
                                                id={"home.links.unassignedWork"}
                                                defaultMessage={"All Unassigned Work"}
                                            />
                                        </SmartLink>
                                    </li>
                                    <li>
                                        <SmartLink
                                            to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "all"})}
                                        >
                                            <FormattedMessage
                                                id={"home.links.allWork"}
                                                defaultMessage={"All Work"}
                                            />
                                        </SmartLink>
                                    </li>
                                </ul>
                            </Paper>
                            <Paper className={"workProgressAtAGlance"} sx={classes.statusPaper} mt={2}>
                                <Box sx={classes.diamond} />
                                <Box>
                                    {["IN_PROGRESS", "NEW",  "COMPLETE", "REQUIRES_INFORMATION" ].map((key, index) => {
                                        const statusInfo = getStatusInfo(key);
                                        return (
                                            <SmartLink
                                                key={key}
                                                to={buildUrl(routesKeys.WORK_LIST, params, {...searchParams, scope: "mywork", status: key})}
                                            >
                                                <Gauge
                                                    key={key}
                                                    value={summaryData.statusCounts[key]}
                                                    percent={summaryData.statusCounts[key] / total}
                                                    label={statusInfo.label}
                                                    color={statusInfo.color}
                                                    size={"small"}
                                                />
                                            </SmartLink>
                                        );
                                    })}
                                </Box>
                                <Box textAlign={"center"}>
                                    <FormattedMessage
                                        id={"home.gauges.infoLabel"}
                                        defaultMessage={"Work item status over the past 90 days."}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
        );
    }
    else {
        body = (
            <Box textAlign={"center"} mt={6}>
                <FormattedMessage
                    id={"home.errorLoadingLabel"}
                    defaultMessage={"There was a problem loading the home page. Please try again later."}
                />
            </Box>
        );
    }

    return (
        <PageLayout>
            <Main breadcrumbs={[breadcrumb]} fullWidth={true} fullHeight={true}>
                <Box sx={classes.root} overflow="auto" height={"100%"} p={2} pb={2}>
                    {body}
                </Box>
            </Main>
        </PageLayout>
    );
};

HomeView.propTypes = {
    organization: PropTypes.object,
    implementation: PropTypes.object,
    isLoading: PropTypes.bool,
};

const WorkSummary = (props) => {
    const username = useDisplayName();
    const workSummaryQuery = useWorkSummaryQuery();
    const org = useActiveOrganization();
    const impl = useActiveImplementation();
    const state = {
        userName: username,
        organization: org || {},
        implementation: impl || {},
        searchParams: {},
        summaryData: workSummaryQuery.data,
        isLoading: workSummaryQuery.isLoading,
        isLoaded: workSummaryQuery.isSuccess,
    };
    useOnboarding("SummaryView", summaryViewSteps, state.isLoaded);

    return (
        <HomeView {...props} {...state} />
    );
};

WorkSummary.secured = {
    implKey: "adas"
};

export default WorkSummary;