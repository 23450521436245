import React, {
    createContext,
    useContext,
    useState,
    useEffect,
} from "react";
import { useParams } from "react-router-dom";
import {useCreateWorkDetailsTransitionMutation} from "../lib/mutations";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import history from "../lib/history";
import { useActiveImplKey, useActiveOrgKey } from "./UserProvider";
import routeKeys, { buildUrl } from "../../routes";

export const transitionToTypes = {
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETE: "COMPLETE",
    INVALID: "INVALID",
    REQUIRES_INFORMATION: "REQUIRES_INFORMATION",
    SCHEDULE_FOLLOWUP: "SCHEDULE_FOLLOWUP",
    CONTINUE_PROGRESS: "CONTINUE_PROGRESS",
    ABANDON: "ABANDON",
    EDIT_REGISTRY_GENERATED_ID: "EDIT_REGISTRY_GENERATED_ID"
};

const getInitialState = () => {
    return {
        transitionTo: null,
        data: {
            noteToAdd : null,
            otherWorkToStart: [],
            abstractableId: null,
            visibleDate: null,
        },
    };
};

export const WorkDetailsTransitionContext = createContext({
    __state: {}, 
    __setState: () => {}, 
    __mutation: {}
});

export default function WorkDetailsTransitionProvider({children}) {
    const {orgKey, implKey, workId} = useParams();
    const [__state, __setState] = useState(getInitialState());
    const __mutation = useCreateWorkDetailsTransitionMutation();
    const state = {
        __state,
        __setState,
        __mutation,
    };

    // reset transition state when user navigates to new details page.
    useEffect(() => __setState(getInitialState()), [orgKey, implKey, workId]);

    return (
        <WorkDetailsTransitionContext.Provider value={state}>
            {workId ? children : null}
        </WorkDetailsTransitionContext.Provider>
    );
}

export const useWorkDetailsTransition = () => {
    const ctx = useContext(WorkDetailsTransitionContext);
    const state = ctx.__state;
    const setState = ctx.__setState;
    const mutation = ctx.__mutation;
    const {search} = useLocation();
    const orgKey = useActiveOrgKey();
    const implKey = useActiveImplKey();

    const onStartTransition = (transitionTo) => {
        let nextState = {
            ...state,
            transitionTo,
        };
        if(nextState.transitionTo === transitionToTypes.CONTINUE_PROGRESS) {
            // continue is the same as moving to IN_PROGRESS.
            nextState.transitionTo = transitionToTypes.IN_PROGRESS;
        }
        if(nextState.transitionTo === transitionToTypes.IN_PROGRESS) {
            // start and continue progress are immediate operations.
            mutation.mutate(
                nextState,
                {
                    onSuccess: ({data}) => {
                        if (search.indexOf('scope=unassigned') !== -1) {
                            // update the url filters if needed
                            history.push(buildUrl(
                                routeKeys.WORK_DETAILS, {
                                    orgKey,
                                    implKey,
                                    workId: data.id
                                }, search.replace('scope=unassigned', 'scope=all'))
                            );
                        }

                        const unasignedSiblings = data.siblingList.filter(
                            (it) => it.assignedAbstractor === null
                            && moment(it.visibleAfter).diff(moment()) < 0
                            && it.status !== "INVALID"
                        );
                        if(unasignedSiblings.length <= 0) {
                            // no transition needed.
                            nextState = getInitialState();
                        }
                        // keeping as IN_PROGRESS will ask the user if they want
                        // to start work on related works.
                        setState(nextState);
                    }
                }
            );
        }
        else {
            setState(nextState);
        }
    };

    const onChange = (key, value) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,
            }
        });
    };
    const onCancel = () => {
        setState(getInitialState());
    };

    const onSubmit = () => {
        mutation.mutate(
            state,
            {
                onSuccess: (res) => {
                    if (state.transitionTo === transitionToTypes.COMPLETE) {
                        setState({
                             ...getInitialState(),
                             transitionTo: transitionToTypes.SCHEDULE_FOLLOWUP
                         });
                    }
                    else {
                        setState(getInitialState());
                    }
                }
            }
        )
    };

    const onAddNote = (note) => {
        const tempState = getInitialState();
        mutation.mutate(
            {
                ...tempState,
                data: {
                    ...tempState.data,
                    noteToAdd: note
                }
            },
        );
    };

    const onAbandon = () => {
        onStartTransition(transitionToTypes.ABANDON);
    };

    const onChangeRegistryGeneratedId = (v) => {
        const tempState = getInitialState();
        mutation.mutate(
            {
                ...tempState,
                data: {
                    ...tempState.data,
                    registryGeneratedId: v
                }
            },
        );
    };

    return {
        ...state,
        isLoading: mutation.isLoading,
        onStartTransition,
        onChange,
        onCancel,
        onSubmit,
        onAddNote,
        onAbandon,
        onChangeRegistryGeneratedId
    };
};