import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import history from '../../lib/history';

const useSearchState = (defaultValue) => {
  const location = useLocation();
  const search = {
    ...defaultValue,
    ...qs.parse(location.search)
  };
  Object.keys(defaultValue).forEach((key) => {
    switch(typeof defaultValue[key]) {
      case "number":
        search[key] = parseInt(search[key], 10);
        break;
      case "boolean":
        search[key] = search[key] === "true" || search[key] === true ? true : false;
        break;
      case "undefined":
        search[key] = search[key] === "undefined" ? undefined : search[key];
        break;
      case "object":
        if(defaultValue[key] === null && search[key] === 'null') {
          search[key] = null;
        }
        break;
      default:
    }
  });
  const setSearchState = (params = {}) => {
    const out = {};
    Object.keys(params).forEach((key) => {
      out[key] = params[key];
    });
    history.push("?" + qs.stringify(out));
    
  };
  return [search, setSearchState];
};

export default useSearchState;
