
import { Main, makeSxStyles } from "@armus/armus-dashboard";
import { Box, Grid, MenuItem, MenuList, Paper, Typography, Divider } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import routeKeys, { buildUrl } from "../../../routes";
import history from "../../lib/history";
import PageLayout from "../components/PageLayout";
import AdminAbstractor from "./AdminAbstractor/AdminAbstractor";
import AdminOrganization from "./AdminOrganization/AdminOrganization";

const SECTIONS_MAP = {
  "abstractor": AdminAbstractor,
  "organization": AdminOrganization
};
const sections = Object.values(SECTIONS_MAP);


const useStyles = makeSxStyles((theme) => ({
  root: {
  }
}));

const SectionNotFound = () => <>404 NOT FOUND</>;
SectionNotFound.breadcrumb = () => ({
  label: "404 Not Found",
  url: "/armus/adas-admin/"
});

const Admin = () => {
  const classes = useStyles();
  const params = useParams();
  const Content = SECTIONS_MAP[params.section] || SectionNotFound;
  const section = params.section;
  const defaultUrl = buildUrl(routeKeys.ADMIN_ORGANIZATION_LIST, params);
  React.useEffect(() => {
    if(!section) {
      history.push(defaultUrl);
    }
  }, [section, defaultUrl]);

  const breadcrumb = Content.breadcrumb();
  const breadcrumbs = [
    {
      label: "Admin Home",
      url: "/armus/adas-admin/"
    }, 
    breadcrumb
  ];

  return (
    <PageLayout key="admin">
      <Main breadcrumbs={breadcrumbs} fullWidth={true} fullHeight={true}>
          <Box sx={classes.root} overflow="auto" height={"100%"} p={2}>
            <Grid container style={{height: "100%"}}>
              <Grid xs={2}>
                <Box component={Paper}>
                  <Typography variant="h6" component={Box} p={2} pb={1} pt={2}>Admin Menu</Typography>
                  <MenuList>
                    <Divider />
                    {sections.map((section) => {
                      const isActive = section === Content;
                      return (
                        <MenuItem component={Link} selected={isActive} to={section.url} >
                          {section.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Box>
              </Grid>
              <Grid xs={10} style={{height: "100%"}}>
                <Content />
              </Grid>
            </Grid>
          </Box>
      </Main>
    </PageLayout>
  );
}
export default Admin;