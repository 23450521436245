import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import WorkListFilters from "./WorkListFilters";
import WorkListTable from "./WorkListTable";
import { FormattedMessage, makeSxStyles, Pane, useOnboarding } from "@armus/armus-dashboard";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import { workListSteps } from "../../../../onboardingSteps";
import WorkFiltersProvider from "../../../providers/WorkFiltersProvider";
import { Tab, Tabs } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
  root: {},
  header: {
    transition: "0.5s",
    overflow: "hidden",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.mode === "dark" ? "#515151" : "#e0e0e0",
  },
  content: {
    transition: "0.5s",
  },
}));

const WorkListPaneView = (props) => {
  const [state, setState] = React.useState({ showFilters: false });
  const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams();
  const classes = useStyles();
  let filterHeight = state.showFilters ? 279+38 : 117;
  if(activeFiltersCount) {
    filterHeight += 38;
  }

  const setStateKey = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <Pane component={Paper} className="workList">
      <Box sx={classes.header} height={filterHeight}>
        <Box sx={classes.title} p={2} pb={1}>
          <Typography component="h1" variant="h4">
            <FormattedMessage
              id="work.list.title"
              defaultMessage={"Case List"}
            />
          </Typography>
        </Box>
        <WorkListFilters
          show={state.showFilters}
          onOpen={() => setStateKey("showFilters", !state.showFilters)}
          extraFilters={
            <Tabs value={searchParams.scope} onChange={(e, v) => onSetFilter("scope", v)}>
              <Tab label="My Work" value={"mywork"} />
              <Tab label="Unassigned Work" value={"unassigned"} />
              <Tab label="All Work" value={"all"} />
            </Tabs>
          }
        />
      </Box>
      <Box
        sx={classes.content}
        height={"calc(100% - " + filterHeight + "px)"}
      >
        <WorkListTable />
      </Box>
    </Pane>
  );
};

const WorkList = (props) => {
  useOnboarding("WorkListView", workListSteps);

  
  return (
    <WorkFiltersProvider>
      <WorkListPaneView {...props} />
    </WorkFiltersProvider>
  );
};

export default WorkList;
