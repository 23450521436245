import { useDialogs, Select } from "@armus/armus-dashboard";
import React, { useState, useRef } from "react";
import { useAddWorkMutation } from "../../../lib/mutations";
import { useWorkFiltersQuery } from "../../../lib/queries";

const AddWorkContent = ({onSaveRef, caseId, workId}) => {
    const workFiltersQuery = useWorkFiltersQuery();
    const options = (workFiltersQuery.data.abstractables || []).filter(it => it.initial);
    const [state, setState] = useState(null);
    const addWorkMutation = useAddWorkMutation();
    onSaveRef.current = () => {
        // call save with state payload
        if(state === null) {
            return;
        }
        addWorkMutation.mutate({
            abstractableId: state,
            associatedCaseId: caseId,
            workId,
        });
    };
    return (
        <div>
            Please select the type of work you would like to add to this case.
            <Select
                descriptor={{
                    id: "type",
                    label: "Work Type",
                    config: {
                        dense: true,
                        options: options,
                    }
                }}
                value={state}
                onChange={(e, value) => {
                    setState(value);
                }}
            />
        </div>
    );
};

const useAddWork = () => {
    const { onShow } = useDialogs();
    const onSaveRef = useRef();
    return (caseId, workId) => onShow({
        title: `Add Work`,
        actions:[
            {
                title: "Cancel",
                isPrimary: false,
                autoFocus: false,
                onClick: undefined
            },
            {
                title: "Add Work",
                isPrimary: true,
                autoFocus: true,
                onClick: () => {
                    onSaveRef.current();
                }
            },
        ],
        content: (
            <AddWorkContent onSaveRef={onSaveRef} caseId={caseId} workId={workId} />
        )
    })
};

export default useAddWork;