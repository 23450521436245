import { useInvoicingAbstractorsQuery, useInvoicingQuery } from "../../lib/queries";

const createPath = (obj, path = [], value) => {
    const node = path.shift();
    if(path.length === 0) {
        if(!Array.isArray(obj[node])) {
            obj[node] = [];
        }
        obj[node].push(value);
        return obj;
    }
    obj[node] = createPath(obj[node] || {}, path, value);
    return obj;
};

const bucketToArray = (bucket, key) => {
    return Object.keys(bucket).map(key => {
        if(Array.isArray(bucket[key])) {
            return {
                key,
                items: bucket[key]
            }
        }
        return {
            key,
            items: bucketToArray(bucket[key], key),
        };
    });
};

export const createInvoicingBuckets = (data) => {
    const buckets = {};
    data.forEach(item => {
        const registryName = item.work.abstractable.registry.displayName;
        const abstractableId = item.work.abstractable.id;
        const initial = item.work.abstractable.initial;
        const initialBucket = initial ? "initial" : "followup";
        const reportingDate = item.reportingDate;
        const [year, month] = (reportingDate || "").split("-");

        // detail items
        createPath(
            buckets,
            initial ? [registryName, abstractableId, initialBucket, `${year}-${month}-01`] : [registryName, abstractableId, initialBucket, `${year}-${month}-01`],
            item
        );
    });
    return bucketToArray(buckets);
};

export const getRegistryInvoicingSummary = (registryBucket) => {
    const summary = [];
    registryBucket.items.forEach((abstractableBucket) =>
        (abstractableBucket).items.forEach((initialBucket) =>
            (initialBucket).items.forEach((finalBucket) =>
                summary.push({
                    registry: registryBucket.key,
                    abstractableId: abstractableBucket.key,
                    initial: initialBucket.key,
                    item: finalBucket.key,
                    rate: (finalBucket.items[0] || {}).rate || {},
                    quantity: finalBucket.items.length,
                })
            )
        )
    );
    return summary;

};

export const useInvoicing = (section, filters) => {
    const invoicingQuery = useInvoicingQuery(section, filters.org, filters.abstractor, filters.completedYear, filters.completedMonth);
    const absractorsQuery = useInvoicingAbstractorsQuery(section);
    return {
        ...invoicingQuery,
        absractorsQuery,
        data: invoicingQuery.isSuccess ? createInvoicingBuckets(invoicingQuery.data) : [],
        raw: invoicingQuery.isSuccess ? invoicingQuery.data : [],
    };
};
