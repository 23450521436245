import React from "react";
import Paper from "@mui/material/Paper";
import {
    Spinner,
    Pane,
    useOnboarding,
    makeSxStyles,
} from "@armus/armus-dashboard";
import history from "../../../lib/history";
import routeKeys, { buildUrl } from "../../../../routes";
import WorkDetailsNotFound from "./WorkDetailsNotFound";
import WorkDetailsSkeleton from "./WorkDetailsSkeleton";
import WorkDetailsHeader from "./WorkDetailsHeader";
import WorkDetailsBody from "./WorkDetailsBody";
import { useParams } from "react-router-dom";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import WorkDetailsVerificationIssues from "./Verification/WorkDetailsVerificationIssues";
import { WorkDetailsProvider, useWorkDetails, useActiveWorkId } from "../../../providers/WorkDetailsProvider";
import { workDetailsSteps } from "../../../../onboardingSteps";

const minHeaderHeight = 117;
const useStyles = makeSxStyles((theme) => ({
    root: {
        display: "flex",
        flexFlow: "column",
        "& > .MuiPaper-root":{
            overflow: "hidden",
        },
        "& .DataItemTitle": { 
            minWidth: minHeaderHeight, 
            display: "inline-block", 
            marginRight: 1,
            userSelect: "none",
        },
        "& .DataItemValue": {
            display: "inline-block", 
            fontWeight: "bold", 
            whiteSpace: "nowrap",
            "& svg": {
                verticalAlign: "bottom",
            },
            "&.canCopy": {
                cursor: "pointer",
            },
            "& .copyButton": {
                display: "none",
                position: "absolute",
                "& svg": {
                    fontSize: 12,
                    verticalAlign: "top",
                }
            },
            "&:hover .copyButton": {
                display: "inline-block" 
            }
        },
        "& header": {
            overflow: "auto",
            minHeight: minHeaderHeight,
            transition: ".5s",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.mode === "dark" ? "#212121" : "#88888844",
            borderLeft: "4px solid #88888888",
            backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#f9f9f9",
            "&.NEW": {
                borderLeftColor: theme.palette.success.main,
                backgroundColor: theme.palette.success.main + "22",
            },
            "&.IN_PROGRESS": {
                borderLeftColor: theme.palette.warning.main,
                backgroundColor: theme.palette.warning.main + "22",
            },
            "&.REQUIRES_INFORMATION": {
                borderLeftColor: theme.palette.info.main,
                backgroundColor: theme.palette.info.main + "22",
            },
            "&.COMPLETE": {
                borderLeftColor: "#888888",
            },
            "&.INVALID": {
                borderLeftColor: "#88888844",
            },
            "& h1": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },
    closeAction: {
        textAlign: "right",
    },
    spinner: { position: "absolute", width: "100%", zIndex: 10 },
    content: {
        borderLeft: "4px solid #88888844",
        width: "100%",
        overflow: "auto",
        flex: 1,
        "& .DataItem": { paddingTop: .8},
    },
    noteUesr: {
        textTransform: "capitalize",
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        }
    },
    workUser: {
        textTransform: "capitalize",
        "& svg": {
            verticalAlign: "text-bottom",
            height: 18,
            width: 18,
        }
    },
    unassignedUser: {
        color: theme.palette.text.hint,
    },
    abandonButton: {
        cursor: "pointer",
        "& svg:last-child": {
        },
        "&:hover svg:last-child": {
        }
    },
    siblingRow: {
        width: "95%",
        whiteSpace: "nowrap",
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        borderBottom: "1px solid " + (theme.palette.mode === "dark" ? "#212121" : "#88888844"),
        "&:first-child": {
            borderTop: "1px solid " + (theme.palette.mode === "dark" ? "#212121" : "#88888844"),
        },
        "& > div:nth-child(2)": {
            textAlign: "center"
        },
        "& svg": {
            verticalAlign: "bottom",
            height: 18,
            width: 18,
        }
    }
}));

const WorkDetailsView = ({
    readOnly = false,
    variant = "abstractor",
}) => {
    const {orgKey, implKey, workId} = useParams();
    const [searchParams] = useWorkSearchParams();
    const {
        isMyWork,
        isUnassigned,
        workDetails,
        isSuccess,
        isUpdating,
        isError,
        isTransitionOpen,
        canAbandon,
        onAbandon,
        onAddNote,
        onChangeRegistryGeneratedId,
    } = useWorkDetails();
    const onClose = () => {
        history.push(buildUrl(routeKeys.WORK_LIST, {orgKey, implKey}, searchParams));
    };
    const classes = useStyles();
    useOnboarding("WorkDetailsView", workDetailsSteps, !!workId && isSuccess);
    let body = null;
    if(isError) { // not found/no access.
        body = (
            <WorkDetailsNotFound classes={classes} workId={workId} onClose={onClose} />
        );
    }
    else if(isSuccess || isUpdating) {
        body = (
            <React.Fragment>
                {isUpdating && <Spinner sx={classes.spinner} />}
                <WorkDetailsHeader
                    classes={classes}
                    workDetails={workDetails}
                    isMyWork={isMyWork}
                    isUnassigned={isUnassigned}
                    onClose={onClose}
                    readOnly={readOnly}
                    variant={variant}
                    isTransitionOpen={isTransitionOpen}
                />
                <WorkDetailsVerificationIssues
                    workDetails={workDetails}
                    readOnly={readOnly}
                    variant={variant}
                    isTransitionOpen={isTransitionOpen}
                />
                <WorkDetailsBody
                    classes={classes}
                    orgKey={orgKey}
                    implKey={implKey}
                    workDetails={workDetails}
                    isMyWork={isMyWork}
                    isUnassigned={isUnassigned}
                    onAddNote={onAddNote}
                    onAbandon={onAbandon}
                    onChangeRegistryGeneratedId={onChangeRegistryGeneratedId}
                    canAbandon={canAbandon}
                    searchParams={searchParams}
                    readOnly={readOnly}
                    variant={variant}
                />
            </React.Fragment>
        );
    }
    else { // show loading skeleton
        body = (
            <WorkDetailsSkeleton classes={classes} />
        );
    }

    return (body);
};

const WorkDetails = (props) => {
    const classes = useStyles();
    const workId = useActiveWorkId();
    return (
        <Pane 
            className={"workDetails"} 
            show={!!workId} 
            width={"90%"} 
            height={"100%"} 
            minWidth={300} 
            component={Paper} 
            sx={classes.root}
        >
            <WorkDetailsProvider>
                <WorkDetailsView {...props}/>
            </WorkDetailsProvider>
        </Pane>
    );
};

export default WorkDetails;