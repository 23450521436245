import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormattedMessage, formatMessage, Main, makeSxStyles } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../routes";
import PageLayout from "../components/PageLayout";
import {
    useActiveImplementation,
    useActiveOrganization, useDisplayName
} from "../../providers/UserProvider";
import Typography from "@mui/material/Typography/Typography";
import ReportingFilters from "./components/ReportingFilters";
import ReportingTable from "./components/ReportingTable";

const useStyles = makeSxStyles((theme) => ({
    root: {
    }
}));

export const useReportingBreadcrumb = () => {
    const org = useActiveOrganization() || {};
    const impl = useActiveImplementation() || {};
    const userName = useDisplayName();
    return{
        label: formatMessage({
                id: "Reporting.breadcrumb",
                defaultMessage: "Reporting"},
            {orgName: org.name, implName: impl.name, userName: userName }
        ),
        url: buildUrl(routesKeys.WORK_SUMMARY, {orgKey: org.key, implKey: impl.key})
    }
};


const Reporting = () => {
    const classes = useStyles();
    const breadcrumb = useReportingBreadcrumb();
    const body = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper mb={3}>
                    <Box mb={3} p={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Typography component="h1" variant="h4">
                                    <FormattedMessage
                                        id="reporting.title"
                                        defaultMessage={"Reporting"}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <ReportingFilters />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Paper>
                    <ReportingTable />
                </Paper>
            </Grid>
        </Grid>
    );

    return (
        <PageLayout>
            <Main breadcrumbs={[breadcrumb]} fullWidth={true} fullHeight={true}>
                <Box sx={classes.root} overflow="auto" height={"100%"} p={2} pb={2}>
                    {body}
                </Box>
            </Main>
        </PageLayout>
    );
};

Reporting.propTypes = {};

Reporting.secured = {
    implKey: "adas-reporting"
};


export default Reporting;