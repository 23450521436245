import React from "react";
import Box from "@mui/material/Box";
import { EnhancedTable, formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import Paper from "@mui/material/Paper/Paper";

const useStyles = makeSxStyles((theme) => ({
    root: {
    },

}));

const ReportingTableView = (props) => {
    const {
        result,
        isLoading = true,
    } = props;
    const classes = useStyles();
    let emptyMessage = null;
    if(result.length === 0) {
        emptyMessage = (
            <Box p={6} textAlign={"center"} fontSize={20}>
                There are currently no reporting items available. <br /> Please check your filters.
            </Box>
        );
    }
    const columns = [
        {
            id: "organization",
            props: { align: "left" },
            isSortable: false,
            label: formatMessage({id: "reporting.table.headerLabel.org", defaultMessage: "Organization"}),
            Content: ({row}) => {
                return row.org
            }
        },
        {
            id: "abstractable",
            props: { align: "left" },
            isSortable: false,
            label: formatMessage({id: "reporting.table.headerLabel.abstractable", defaultMessage: "Abstractable"}),
            Content: ({row}) => {
                return row.abstractableLongname
            }
        },
        {
            id: "total",
            props: { align: "left" },
            isSortable: false,
            label: formatMessage({id: "reporting.table.headerLabel.total", defaultMessage: "Total"}),
            Content: ({row}) => {
                return row.total
            }
        },
        {
            id: "new",
            props: { align: "left" },
            isSortable: false,
            label: formatMessage({id: "reporting.table.headerLabel.new", defaultMessage: "New"}),
            Content: ({row}) => {
                return row.new
            }
        }
    ];

    return (
        <Box sx={classes.root}>
            <Paper>
                <EnhancedTable
                    sx={classes.table}
                    rows={result}
                    columns={columns}
                    isLoading={isLoading}
                    emptyMessage={emptyMessage}
                    total={result.totalElements}
                    showHeader={true}
                    dense={true}
                    selectedCell={{
                    }}
                    hover={false}
                    pagination={false}
                    fillEmptyRows={false}
                    height={"auto"}
                />
            </Paper>
        </Box>
    );
};



const ReportingTable = (props) => {
    const state = {
        result: [],
        isLoading: false
    };
    return (
        <ReportingTableView
            {...props}
            {...state}
        />
    );
};

export default ReportingTable;
