import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWorkDetailsValidationMutation } from "../lib/mutations";
import {
  useWorkDetailsVerificationQuery,
} from "../lib/queries";
import { useWorkDetails } from "./WorkDetailsProvider";

export const verificationToTypes = {
  FAILED: "FAILED",
  VERIFIED: "VERIFIED",
  PENDING_REVIEW: "PENDING_REVIEW",
  INVALID: "INVALID",
};

const getInitialState = () => {
  return {
    registryGeneratedId: "",
    transitionTo: null,
    data: {
      note: "",
      verificationState: "",
    },
  };
};

export const WorkDetailsVerificationContext = createContext({
  __state: {},
  __setState: () => {},
  __mutation: {},
});

export default function WorkDetailsVerificationProvider({ children }) {
  const { orgKey, implKey, workId } = useParams();
  const [__state, __setState] = useState(getInitialState());
  const __mutation = useWorkDetailsValidationMutation();
  const state = {
    __state,
    __setState,
    __mutation,
  };

  // reset transition state when user navigates to new details page.
  useEffect(() => __setState(getInitialState()), [orgKey, implKey, workId]);

  return (
    <WorkDetailsVerificationContext.Provider value={state}>
      {workId ? children : null}
    </WorkDetailsVerificationContext.Provider>
  );
}

export const useWorkDetailsIsUpdating = () => {
  const ctx = useContext(WorkDetailsVerificationContext);
  return ctx.__mutation.isLoading;
};

export const useWorkDetailsVerification = () => {
  const ctx = useContext(WorkDetailsVerificationContext);
  const state = ctx.__state;
  const setState = ctx.__setState;
  const mutation = ctx.__mutation;
  const workDetailsState = useWorkDetails();
  const workDetails = workDetailsState.workDetails || {};

  const workId = workDetails.id;
  const registryGeneratedId = workDetails.registryGeneratedId;
  const verificationMethod =
    (workDetails.abstractable || {}).verificationMethod || "MANUAL";
  const hasRegistryGenereatedId = !!state.registryGeneratedId || !!registryGeneratedId;
  const canAutoVerify =
    verificationMethod === "AUTOMATIC" && !!workId && hasRegistryGenereatedId;
  const verificationQuery = useWorkDetailsVerificationQuery(
    canAutoVerify,
    workId,
    registryGeneratedId !== state.registryGeneratedId
      ? state.registryGeneratedId
      : false // only send the id when its different.
  );
  const onAutoVerificationCheck = (newRegistryGeneratedId) => {
    if(!newRegistryGeneratedId) {
      verificationQuery.refetch();
    }
    else {
      setState({ ...state, registryGeneratedId: newRegistryGeneratedId });
    }
  };
  const onCancel = () => {
    setState(getInitialState());
  };
  const onAutoForceReverify = () => {
    mutation.mutate(
      {
        note: null,
        verificationStatus: "PENDING_REVIEW",
      },
      {
        onSuccess: (res) => {
          setState(getInitialState());
        },
      }
    );
  };

  
  const onChange = (key, value) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [key]: value,
      },
    });
  };

  const onStartVerification = (verificationStatus) => {
    setState({
      ...state,
      transitionTo: verificationStatus,
      data: {
        ...state.data,
        verificationStatus: verificationStatus,
      },
    });
  };

  const onManualRequestReverification = () => {
    mutation.mutate(
      {
        note: "Reverification requested by abstractor.",
        verificationStatus: "PENDING_REVIEW",
      },
      {
        onSuccess: (res) => {
          setState(getInitialState());
        },
      }
    );
  };

  const onAutoVerificationSetPending = () => {
    mutation.mutate(
      {
        verificationStatus: "PENDING_REVIEW",
      },
      {
        onSuccess: (res) => {
          setState(getInitialState());
        },
      }
    );
  };

  const onSubmit = () => {
    mutation.mutate(state.data, {
      onSuccess: (res) => {
        setState(getInitialState());
      },
    });
  };

  const issues = verificationQuery.data || [];
  const isFetching = verificationQuery.isFetching;
  const isSuccess = verificationQuery.isSuccess;
  const isVerified =
    verificationMethod === "AUTOMATIC"
      ? !isFetching && isSuccess && canAutoVerify && issues.length === 0
      : workDetails.verificationStatus; // MAUNAL
  return {
    ...state,
    isLoading: isFetching,
    canAutoVerify: canAutoVerify,
    isVerified: isVerified,
    verificationStatus:
      workDetails.verificationStatus || "PENDING_VERIFICATION",
    verificationMethod: verificationMethod,
    issues: issues,
    onAutoVerificationCheck,
    onAutoVerificationSetPending,
    onAutoForceReverify,
    onManualRequestReverification,
    onCancel,
    onSubmit,
    onChange,
    onStartVerification,
  };
};
