import React from "react";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/material";
import { Outline, makeSxStyles } from "@armus/armus-dashboard";

const useStyles = makeSxStyles(theme => ({
    root: {
        height: 70,
        "& .MuiSlider-root .MuiSlider-thumb": {
            height: 22,
            width: 22,
        }
    },
    slider: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    label: {
        "&:first-letter": {
            textTransform: "capitalize"
        }
    }
}));

const DateRangeSlider = ({ label = "", minDistance = 1, ...props }) => {
    const {min = 0, max = 100, value, onChange } = props;
    const [state, setState] = React.useState((value || [min, max]));
    const classes = useStyles();

    const handleChange = (event, v) => {
        if (!Array.isArray(v)) {
            return;
        }
        if (Math.abs(v[0] - v[1]) < minDistance) {
            v = [state[0], state[1]];
        }
        setState(v)
    };

    const handleChangeCommitted = (event) => {
        const v = [...state];
        if(v[0] === min) {
            v[0] = "";
        }
        if(v[1] === max) {
            v[1] = "";
        }
        onChange(event, v);
    };

    let message = "";

    if(state[0] !== min) {
        message += `after ${Math.abs(state[0])}`;
    }

    if(state[0] !== min && state[1] !== max) {
        message += " days ago and "
    }

    if(state[1] !== max) {
        message += `before ${Math.abs(state[1])}`
    }
    message += " days ago.";
    if(state[0] === min && state[1] === max) {
        message = `Any ${label.toLowerCase()}.`;
    }

    React.useEffect(() => {
        setState(value || [min, max]);
    }, [value, min, max]);

    return (
        <Outline label={label} fullWidth={true} margin={"dense"}>
            <Box sx={classes.root}>
                <Box sx={classes.label}>
                    {message}
                </Box>
                <Box sx={classes.slider}>
                    <Slider
                        {...props}
                        value={state}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        onChangeCommitted={handleChangeCommitted}
                    />
                </Box>
            </Box>
        </Outline>
    );
};

export default DateRangeSlider;