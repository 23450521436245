import React, {
    createContext,
    useContext,
    useMemo,
} from "react";
import { LaunchLayout, useActivityMonitor } from "@armus/armus-dashboard";
import { useLocation } from "react-router-dom";
import { useUserQuery } from "../lib/queries";
import config from "../../config";
import api from "../lib/api";
import qs from 'query-string';

const UserContext = createContext(undefined);

export const logoutUser = () => api.logoutUser();

export default function UserProvider({children}) {
    const userQuery = useUserQuery();
    const {isLoading, data} = userQuery;

    useActivityMonitor(
        () => { // ping check that the user is still active
            userQuery.refetch();
        },
        () => { // user is not active log them out
            logoutUser();
        },
        config.session
    );

    return (
        <UserContext.Provider value={data}>
            {isLoading ? <LaunchLayout title="ADAM" /> : children}
        </UserContext.Provider>
    );
};

export const getOrganizations = (user) => {
    return user.organizations || [];
};

export const getDefaultOrgKey = (user) => {
    const orgs = getOrganizations(user);
    return orgs.length ? orgs[0].key : "";
};

export const getOrganization = (user, orgKey) => {
    return getOrganizations(user).find((org) => org.key === orgKey);
};

export const getImplementations = (user, orgKey) => {
    const org = getOrganization(user, orgKey);
    if(!org) {
        return [];
    }
    return org.implementations;
};

export const getImplementation = (user, orgKey, impKey) => {
    const impls = getImplementations(user, orgKey);
    return impls.find((imp) => imp.key === impKey);
};

export const getDefaultImplementationKey = (user, orgKey) => {
    const impls = getImplementations(user, orgKey);
    return impls.length ? impls[0].key : "";
};

// deliberately NOT exported!
const useUser = () =>
    useContext(UserContext);

export const useDisplayName = () => {
    const user = useUser();
    return user.firstname || user.username || null;
};

export const useThisAppKey = () =>
    useContext(UserContext).thisAppKey;

export const useUsername = () => {
    const user = useContext(UserContext);
    return user.username || null;
};

export const useNavItems = () => {
    const user = useContext(UserContext);
    return user.navTree ? (user.navTree.items || []) : [];
};

export const useDefaultOrgKey = () =>
    getDefaultOrgKey(useUser());

export const useDefaultImplementationKey = (orgKey) =>
    getDefaultImplementationKey(useUser(), orgKey);

export const getDefaultRoutePath = (orgKey, implKey) =>
    `/${orgKey}/${implKey}`;

export const useDefaultRoutePath = () => {
    const orgKey = useDefaultOrgKey();
    const targetImpl = useImplementation(
        orgKey,
        qs.parse(window.location.search).target_implementation || ""
    );
    let implKey = useDefaultImplementationKey(orgKey);
    if(targetImpl) {
        implKey = targetImpl.key;
    }
    return orgKey && implKey && getDefaultRoutePath(orgKey, implKey);
};

export const useOrganizations = () =>
    getOrganizations(useUser());

export const useOrganization = key =>
    getOrganization(useUser(), key);

export const useImplementation = (orgKey, implKey) =>
    getImplementation(useUser(), orgKey, implKey);

export const useFindOrganization = () => {
    const user = useUser();
    return (key) =>
        getOrganization(user, key);
};

const getOrgAndImplFromLocation = (location = []) => {
    const [orgKey, implKey] = (location.pathname.replace("/","") || "").split("/");
    return {
        orgKey,
        implKey
    };
};

export const useActiveOrgKey = () => {
    return getOrgAndImplFromLocation(useLocation()).orgKey;
};

export const useActiveImplKey = () => 
    getOrgAndImplFromLocation(useLocation()).implKey;

export const useActiveOrganization = () =>
    useOrganization(useActiveOrgKey());

export const useActiveImplementation = () => 
    useImplementation(useActiveOrgKey(), useActiveImplKey());

export const useSecuredIsAccessible = () => {
    const user = useUser();
    const orgKey = useActiveOrgKey();

    return useMemo(() => {
        const org = getOrganization(user, orgKey);
        if (!org || !org.implementations) return () => false;
        const impls = org.implementations;
        return secured => {
            if (!secured) return true; // that was easy.
            const { implKey } = secured;
            if (!implKey) return true; // what?
            return !!(impls.find((it) => it.key === implKey)); // check if org has access to implKey
        };
    }, [user, orgKey]);
};