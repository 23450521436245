import React from "react";
import { Box } from "@mui/material";
import { Select, Text } from "@armus/armus-dashboard";
import history from "../../../../lib/history";
import routeKeys, { buildUrl } from "../../../../../routes";
import { useParams } from "react-router-dom";
import { useAdminAbstractor } from "../../../../providers/AdminAbstractorProvider";
import AbstractorIcon from "@mui/icons-material/Person";
import DetailsPane from "../../components/DetailsPane";

const AbstractorDetails = (props) => {
  const { orgKey, implKey } = useParams();
  const { abstractor, abstractorId, abstractorMutation, userTypesQuery, setAbstractor, onSaveAbstractor } =
    useAdminAbstractor();

  const onClose = () => {
    history.push(
      buildUrl(routeKeys.ADMIN_ABSTRACTOR_LIST, { orgKey, implKey })
    );
  };

  const onChange = (key, value) => {
    setAbstractor({
      ...abstractor,
      [key]: value
    });
  };

  const onSubmit = () => {
    onSaveAbstractor();
  };

  const isValid = !!abstractor?.username && !!abstractor?.firstName && !!abstractor?.lastName && !!abstractor?.email && !!abstractor?.abstractorType;

  const userTypeOptions = userTypesQuery.data || [];
  return (
    <DetailsPane
      id={abstractorId}
      fakeLoading={true}
      show={!!abstractorId}
      minWidth={300}
      minHeight={450}
      isSuccess={!!abstractor}
      isError={!abstractor && abstractorId}
      isUpdating={abstractorMutation.isLoading}
      onClose={onClose}
      disabled={!isValid}
      onSubmit={onSubmit}
      submitText="Save"
      title={
        <>
          <Box style={{ verticalAlign: "sub", display: "inline-block" }}>
            <AbstractorIcon fontSize={"large"} />
          </Box>
          &nbsp;
          {abstractorId === "new" ? "Add" : abstractor?.username}
        </>
      }
    >
      {abstractorId === "new" && (
        <Text
          descriptor={{
            label: "Username",
            config: { dense: true },
          }}
          value={abstractor?.username}
          required
          error={!abstractor?.username}
          onChange={(e,v) => {
            onChange("username", v);
          }}
        />
      )}
      <Text
        descriptor={{
          label: "First Name",
        }}
        onChange={(e,v) => {
          onChange("firstName", v);
        }}
        value={abstractor?.firstName}
        error={!abstractor?.firstName}
      />
      <Text
        descriptor={{
          label: "Last Name",
        }}
        onChange={(e,v) => {
          onChange("lastName", v);
        }}
        value={abstractor?.lastName}
        error={!abstractor?.lastName}
      />
      <Text
        descriptor={{
          label: "Email",
        }}
        onChange={(e,v) => {
          onChange("email", v);
        }}
        value={abstractor?.email}
        error={!abstractor?.email}
      />
      <Select
        isLoading={userTypesQuery.isLoading}
        descriptor={{
          label: "Type",
          config: {
            groupBy: false,
            dense: true,
            multivalued: false,
            options: userTypeOptions
          },
        }}
        value={abstractor?.abstractorType}
        onChange={(e, v) => {
          onChange("abstractorType", v);
        }}
      />
    </DetailsPane>
  );
};

export default AbstractorDetails;
