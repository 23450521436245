import React from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SubmitIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import {transitionToTypes, useWorkDetailsTransition} from "../../../../providers/WorkDetailsTransitionProvider";
import {useWorkDetailsVerification} from "../../../../providers/WorkDetailsVerificationProvider";
import { Select, DatePicker, formatMessage, FormattedMessage, Outline, makeSxStyles } from "@armus/armus-dashboard";
import config from "../../../../../config";
import moment from "moment-timezone";
import WorkDetailsActionButtons from "./WorkDetailsTransitionActions";

const useStyles = makeSxStyles((theme) => ({
    root: {
        transition: ".5s",
        overflow: "auto",
        maxHeight: 0,
        "&.opened": {
            maxHeight: 500,
        }
    }
}));

const WorkDetailsTransitionResolver = ({workDetails, isMyWork, isUnassigned}) => {
    const transitionState = useWorkDetailsTransition();
    const verificationState = useWorkDetailsVerification(workDetails);
    const classes = useStyles();
    const handleCancel = () => {
        transitionState.onCancel();
        verificationState.onCancel();
    };

    const handleSubmit = () => {
        transitionState.onSubmit();
    };

    const handleDataChange = (key, value) => {
        transitionState.onChange(key, value);
    };

    const handleAutoVerificationCheck = (registryGeneratedId) => {
        verificationState.onAutoVerificationCheck(registryGeneratedId);
    }
    if(transitionState.transitionTo === null) {
        return (
            <WorkDetailsActionButtons
              isMyWork={isMyWork}
              isUnassigned={isUnassigned}
              workDetails={workDetails}
            />
        )
    }


    let content = null;
    let submitDisabled = false;
    let actionLabel = formatMessage({id: "workDetails.transitionTo.actionLabel", defaultMessage: "submit"});
    const noteLabel = formatMessage({id: "workDetails.transitionTo.noteInputLabel", defaultMessage: "Note"});
    switch(transitionState.transitionTo) {
        case transitionToTypes.IN_PROGRESS:
            actionLabel = formatMessage({id: "workDetails.transitionTo.inProgress.actionLabel", defaultMessage: "Assign To Me"});
            submitDisabled = transitionState.data.otherWorkToStart.length === 0;
            const siblings = workDetails.siblingList.filter(it => it.assignedAbstractor === null && moment(it.visibleAfter).diff(moment()) < 0 && it.status !== "INVALID");
            if(siblings.length > 0) {
                content = (
                    <React.Fragment>
                        <Box mb={2}>
                            <FormattedMessage
                                id={"workDetails.transitionTo.inProgress.instructions"}
                                defaultMessage={"Would you like to be assigned these related work items?"}
                            />
                        </Box>
                        <Outline label={"Related Work Items"} fullWidth={true}
                                 margin={"dense"}>
                            {siblings.map((it) => {
                                return (
                                    <div key={it.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color={"primary"}
                                                    checked={transitionState.data.otherWorkToStart.includes(it.id)}
                                                    onChange={() => {
                                                        let value = [...transitionState.data.otherWorkToStart];
                                                        const index = value.indexOf(it.id);
                                                        if (index > -1) {
                                                            // remove
                                                            value.splice(index, 1);
                                                        }
                                                        else {
                                                            // add
                                                            value.push(it.id);
                                                        }
                                                        handleDataChange("otherWorkToStart", value);
                                                    }}
                                                />
                                            }
                                            label={it.abstractableLongname}
                                        />
                                    </div>
                                );
                            })}
                        </Outline>
                    </React.Fragment>
                );
            }
            break;
        case transitionToTypes.EDIT_REGISTRY_GENERATED_ID:
        case transitionToTypes.COMPLETE:
            actionLabel = formatMessage({id: "workDetails.transitionTo.complete.actionLabel", defaultMessage: "Mark As Complete"});
            let instructions = formatMessage({id: "workDetails.transitionTo.complete.instructions", defaultMessage: "Please fill out the required fields below to complete this work item."})
            if(transitionState.transitionTo === transitionToTypes.EDIT_REGISTRY_GENERATED_ID) {
                actionLabel = formatMessage({id: "workDetails.transitionTo.editRegistryGeneratedId.actionLabel", defaultMessage: "Update Registry Generated ID"});
                instructions = formatMessage({id: "workDetails.transitionTo.editRegistryGeneratedId.instructions", defaultMessage: "Please fill out the required fields below to update the registry generated ID."});
            }
            submitDisabled = !transitionState.data.registryGeneratedId;
            content = (
                <React.Fragment>
                    <Box mb={2}>
                        {instructions}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                value={transitionState.data.registryGeneratedId || ""}
                                label={formatMessage({id: "workDetails.transitionTo.scheduleFollowup.registryGeneratedIdInputLabel", defaultMessage: "Registry Generated ID"})}
                                required={true}
                                error={!transitionState.data.registryGeneratedId}
                                variant={"outlined"}
                                color={"primary"}
                                fullWidth={true}
                                onChange={(e) => handleDataChange("registryGeneratedId", e.target.value)}
                                onBlur={(e) => handleAutoVerificationCheck(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={transitionState.data.noteToAdd || ""}
                                label={noteLabel}
                                variant={"outlined"}
                                color={"primary"}
                                multiline
                                fullWidth={true}
                                onChange={(e) => handleDataChange("noteToAdd", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
            break;
        case transitionToTypes.INVALID:
            submitDisabled = !transitionState.data.noteToAdd || transitionState.data.noteToAdd === "other";
            actionLabel = formatMessage({id: "workDetails.transitionTo.abandon.actionLabel", defaultMessage: "Mark As Invalid"});
            const invalidOptions = [
                {label: "Other", value: "other"},
                {label: "Convert work to different type.", value: "Convert work to different type."},
            ];
            const isCanned = invalidOptions.findIndex(it => it.value === transitionState.data.noteToAdd) > 0; // exclude "other"
            content = (
                <React.Fragment>
                    <Box mb={2}>
                        <FormattedMessage
                            id={"workDetails.transitionTo.invalid.instructions"}
                            defaultMessage={"Please add a reason for invalidating the work item."}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                descriptor={{
                                    id: "abstractableId",
                                    label: formatMessage({id: "workDetails.transitionTo.invalid.invalidSelect", defaultMessage: "Invalid Reason"}),
                                    config: {
                                        dense: false,
                                        fullWidth: false,
                                        allowNull: false,
                                    }
                                }}
                                value={isCanned ? transitionState.data.noteToAdd : "other"}
                                data={invalidOptions}
                                onChange={(e, v) => handleDataChange("noteToAdd", v)}
                            />
                        </Grid>
                        {!isCanned && <Grid item xs={12}>
                            <TextField
                                value={transitionState.data.noteToAdd !== "other" ?  transitionState.data.noteToAdd || "" : ""}
                                label={noteLabel}
                                required={true}
                                error={submitDisabled}
                                variant={"outlined"}
                                color={"primary"}
                                multiline
                                fullWidth={true}
                                onChange={(e) => handleDataChange("noteToAdd", e.target.value)}
                            />
                        </Grid>}
                    </Grid>
                </React.Fragment>
            );
            break;
        case transitionToTypes.REQUIRES_INFORMATION:
            actionLabel = formatMessage({id: "workDetails.transitionTo.requiresInformation.actionLabel", defaultMessage: "Mark as Requires Information"});
            submitDisabled = !transitionState.data.noteToAdd;
            content = (
                <React.Fragment>
                    <Box mb={2}>
                        <FormattedMessage
                            id={"workDetails.transitionTo.requiresInformation.instructions"}
                            defaultMessage={"Please indicate what information is needed in order to continue this work item."}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={transitionState.data.noteToAdd || ""}
                                label={noteLabel}
                                required={true}
                                error={!transitionState.data.noteToAdd}
                                variant={"outlined"}
                                color={"primary"}
                                multiline
                                fullWidth={true}
                                onChange={(e) => handleDataChange("noteToAdd", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
            break;
        case transitionToTypes.ABANDON:
            actionLabel = formatMessage({id: "workDetails.transitionTo.abandon.actionLabel", defaultMessage: "Abandon Work"});
            submitDisabled = !transitionState.data.noteToAdd;
            content = (
                <React.Fragment>
                    <Box mb={2}>
                        <FormattedMessage
                            id={"workDetails.transitionTo.abandon.instructions"}
                            defaultMessage={"Please provide a reason for abandoning this work item."}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={transitionState.data.noteToAdd || ""}
                                label={noteLabel}
                                required={true}
                                error={!transitionState.data.noteToAdd}
                                variant={"outlined"}
                                color={"primary"}
                                multiline
                                fullWidth={true}
                                onChange={(e) => handleDataChange("noteToAdd", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
            break;
        case transitionToTypes.SCHEDULE_FOLLOWUP:
            actionLabel = formatMessage({id: "workDetails.transitionTo.scheduleFollowup.actionLabel", defaultMessage: "Schedule Follow-up"});
            submitDisabled = !transitionState.data.abstractableId || !moment(transitionState.data.visibleDate).isValid();
            content = (
                <React.Fragment>
                    <Box mb={2}>
                        <FormattedMessage
                            id={"workDetails.transitionTo.scheduleFollowup.instructions"}
                            defaultMessage={"Please fill out the required fields below to schedule a follow-up for this work item."}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                descriptor={{id: "abstractableId", label: formatMessage({id: "workDetails.transitionTo.scheduleFollowup.typeInputLabel", defaultMessage: "Type"}), config: {dense: false, fullWidth: false}}}
                                value={transitionState.data.abstractableId}
                                error={!transitionState.data.abstractableId}
                                data={workDetails.followUpPotentials}
                                onChange={(e, v) => handleDataChange("abstractableId", v)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                descriptor={{
                                    id: "visibleDate",
                                    label: formatMessage({id: "workDetails.transitionTo.scheduleFollowup.visibleAfterInputLabel", defaultMessage: "Visible After This Date"}),
                                    config: {
                                        format: config.ui.dateFormatInput,
                                        dense: false,
                                        disableTime: true,
                                    }
                                }}
                                onChange={(e, v) => handleDataChange("visibleDate", v)}
                                value={transitionState.data.visibleDate || null}
                                helperText={""}
                                error={!transitionState.data.visibleDate}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
            break;
        default:
            content = (
                null
            );
    }

    if(content !== null) {
        // add buttons
        content = (
            <React.Fragment>
                {content}
                <Box pt={2}>
                    <Button color={"info"} variant={"contained"} size={"medium"} disableElevation disabled={submitDisabled} onClick={handleSubmit}>
                        <SubmitIcon /> &nbsp; {actionLabel}
                    </Button>&nbsp;
                    <Button color={"default"} size={"medium"} disableElevation onClick={handleCancel}>
                        <CancelIcon /> &nbsp; Cancel
                    </Button>
                </Box>
            </React.Fragment>
        );
    }

    return (
        <Box className={(content ? " opened" : "")} sx={classes.root}>
            <Box minHeight={100}>
                {content}
            </Box>
        </Box>
    );
};

export default WorkDetailsTransitionResolver;