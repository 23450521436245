import { useQuery } from "react-query";
import {
    useActiveImplKey,
    useActiveOrgKey,
    useFindOrganization
} from "../providers/UserProvider";
import api from "./api";
import axios from "axios";
import useWorkSearchParams from "../views/components/useWorkSearchParams";
import config from "../../config";
import useReportingSearchParams
    from "../views/components/useReportingSearchParams";
import { useActiveWorkId } from "../providers/WorkDetailsProvider";

export const ABSTRACTOR_IMPLEMENTATION = "adas";
export const INVOICING_IMPLEMENTATION = "adas-invoicing";
export const REPORTING_IMPLEMENTATION = "adas-reporting";
export const VERIFICATION_IMPLEMENTATION = "adas-verification";
export const ADMIN_IMPLEMENTATION = "adas-admin";

export const useUserQuery = (options) => {
    return useQuery(
        [ "user"],
        () => {
            return api.getUserData()
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    // User is most likely not logged in...
                    // Kick the user over to sing-in page.
                    window.location = config.endpoints.login;
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            ...options,
        },
    )
};

export const useWorkSummaryQuery = (options) => {
    const orgKey = useActiveOrgKey();
    const implKey = ABSTRACTOR_IMPLEMENTATION;
    const [searchParams] = useWorkSearchParams();
    return useQuery(
        [ "workSummary", orgKey, implKey ],
        () => {
            searchParams.scope = "mywork";
            searchParams.page = 0;
            searchParams.size = 1000;
            searchParams.orderBy = "createdAt";
            searchParams.order = "asc";
            searchParams.createdAtAfter = -90; // 90 days ago
            const last90DaysWork = api.getWorkListData(orgKey, implKey, searchParams, true);

            searchParams.status = "NEW";
            searchParams.createdAtAfter = "";
            const allNewWork = api.getWorkListData(orgKey, implKey, searchParams, false);

            searchParams.status = "IN_PROGRESS";
            searchParams.createdAtAfter = "";
            const allInProgressWork = api.getWorkListData(orgKey, implKey, searchParams, false);

            searchParams.status = "REQUIRES_INFORMATION";
            searchParams.createdAtAfter = "";
            const allRequiresInformationWork = api.getWorkListData(orgKey, implKey, searchParams, false);

            searchParams.status = "";
            searchParams.createdAtAfter = "";
            searchParams.verificationStatus = "FAILED";
            const allFailedVerificationWork = api.getWorkListData(orgKey, implKey, searchParams, false);

            const lastTouched = api.getLastModifiedDetailsData(orgKey, implKey);

            return Promise.all([lastTouched, last90DaysWork, allNewWork, allInProgressWork, allRequiresInformationWork, allFailedVerificationWork])
                .then((values) => {
                    const [lastTouchedRes, last90DaysWorkRes, allNewWorkRes, allInProgressWorkRes, allRequiresInformationWorkRes, allFailedVerificationWorkRes] = values;
                    const summary = {
                        statusCounts: {
                            NEW: 0,
                            IN_PROGRESS: 0,
                            REQUIRES_INFORMATION: 0,
                            COMPLETE: 0,
                        },
                        total: 0,
                        lastModifiedWork: lastTouchedRes.data || null,
                        newWork: allNewWorkRes.data.content || [],
                        inProgressWork: allInProgressWorkRes.data.content || [],
                        requiresInformationWork: allRequiresInformationWorkRes.data.content || [],
                        failedVerificationWork: allFailedVerificationWorkRes.data.content || [],
                    };

                    last90DaysWorkRes.data.content.forEach((it) => {
                        if(it.status !== "INVALID") {
                            if (summary.statusCounts.hasOwnProperty(it.status)) {
                                summary.statusCounts[it.status]++;
                            }
                            else {
                                summary.statusCounts[it.status] = 1;
                            }
                            summary.total++;
                        }
                    });
                    return Promise.resolve(summary);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            ...options,
        },
    );
};

export const useWorkFiltersQuery = (options) => {
    const orgKey = useActiveOrgKey();
    const implKey = ABSTRACTOR_IMPLEMENTATION; // hard coded to the adas impl.
    const findOrganization = useFindOrganization();
    return useQuery(
        [ "workFilters", orgKey, implKey ],
        () => {
            return api.getFiltersData(orgKey, implKey)
                .then((res) => {
                    const data = res.data;
                    // get the long name from the userStore org list.
                    data.organizations.forEach((org) => {
                        org.label = (findOrganization(org.value) || {}).name || org.label
                    });
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    return Promise.reject(err);
                });
        },
        {
            keepPreviousData: true,
            staleTime: 1000 * 60 * 60,
            ...options,
        },
    )
};

export const useListQuery = (key, request, options) => {
    const orgKey = useActiveOrgKey();
    const implKey = useActiveImplKey();
    const [searchParams] = useWorkSearchParams();
    const findOrganization = useFindOrganization();
    return useQuery(
        [ key, orgKey, implKey, searchParams ],
        () => {
            return request(orgKey, implKey, searchParams, true)
                .then((res) => {
                    const data = res.data;
                    data.content = (data.content || []).map((row) => ({
                        ...row,
                        orgLongname: (findOrganization(row.orgShortname) || {}).name || row.orgShortname,
                    }));
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            ...options,
        },
    );
};

export const useWorkListQuery = (options) => {
    return useListQuery("workList", api.getWorkListData, options);
};

export const useCaseListQuery = (options) => {
    return useListQuery("caseList", api.getCaseListData, options);
};

export const useWorkDetailsQuery = (options) => {
    const orgKey = useActiveOrgKey();
    const implKey = ABSTRACTOR_IMPLEMENTATION;
    const workId = useActiveWorkId();
    return useQuery(
        [ "workDetails", orgKey, implKey, parseInt(workId, 10) ],
        () => {
            return api.getWorkDetailsData(orgKey, implKey, workId)
                .then((res) => {
                    const data = res.data;
                    if(!data.id) {
                        // no data
                        return data;
                    }
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            enabled: !!workId,
            keepPreviousData: false,
            refetchOnWindowFocus: true,
            cacheTime: 0,
            ...options,
        },
    );
};

export const useWorkDetailsVerificationQuery = (canVerify, workId = false, registryGeneratedId = false, options) => {
    const orgKey = useActiveOrgKey();
    const implKey = useActiveImplKey();
    return useQuery(
        [ "workDetailsVerification", orgKey, implKey, workId, registryGeneratedId ],
        () => {
            return api.getWorkDetailVerificationData(orgKey, implKey, workId, registryGeneratedId)
                .then((res) => {
                    const data = res.data;
                    if(!data.id) {
                        // no data
                        return data;
                    }
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            enabled: canVerify,
            cacheTime: 0,
            ...options,
            initialData: [],
        },
    );
};

export const useInvoicingQuery = (section, organization, abstractor, completedYear, completedMonth, options = {}) => {
    const implKey = INVOICING_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    return useQuery(
        [ "invoicing", orgKey, implKey, section, organization, abstractor, completedYear, completedMonth ],
        () => {
            return api.getInvoicingData(orgKey, implKey, section, organization, abstractor, completedYear, completedMonth)
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            enabled: !!(section === "abstractor" ? abstractor : organization) && !!completedYear && !!completedMonth,
            cacheTime: 0,
            ...options,
        },
    );
};
export const useInvoicingAbstractorsQuery = (section, options = {}) => {
    const implKey = INVOICING_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    return useQuery(
        [ "invoicing", orgKey, implKey, section],
        () => {
            return api.getInvoicingAbstractorsData(orgKey, implKey, section )
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            enabled: section === "abstractor",
            cacheTime: 0,
            ...options,
        },
    );
};

export const useReportingQuery = (options) => {
    const implKey = REPORTING_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    const [searchParams] = useReportingSearchParams();
    return useQuery(
        [ "reporting", orgKey, implKey, searchParams ],
        () => {
            return api.getReportingData(orgKey, implKey, searchParams)
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            ...options,
        },
    );
};

export const useAdminOrganizationQuery = (options) => {
    const implKey = ADMIN_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    //const findOrganization = useFindOrganization();
    return useQuery(
        [ "adminOrganization", orgKey, implKey ],
        () => {
            return api.getAdminOrginizationData(orgKey, implKey)
                .then((res) => {
                    const data = res.data;
                    /*.map(row => ({
                        ...row,
                        longname: (findOrganization(row.shortname) || {}).name || row.shortname,
                    }));*/
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            initialData: [],
            ...options,
        },
    );
};

export const useAdminRegistriesQuery = (options) => {
    const implKey = ADMIN_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    return useQuery(
        [ "adminRegistries", orgKey, implKey ],
        () => {
            return api.getAdminRegistriesData(orgKey, implKey)
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            initialData: [],
            ...options,
        },
    );
};

export const useAdminAbstractorQuery = (options) => {
    const implKey = ADMIN_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    return useQuery(
        [ "adminAbstractor", orgKey, implKey ],
        () => {
            return api.getAdminAbstractorData(orgKey, implKey)
                .then((res) => {
                    const data = res.data;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            initialData: [],
            ...options,
        },
    );
};

export const useAdminUserTypesQuery = (options) => {
    const implKey = ADMIN_IMPLEMENTATION;
    const orgKey = useActiveOrgKey();
    return useQuery(
        [ "adminUserTypes", orgKey, implKey ],
        () => {
            return api.getAdminUserTypesData(orgKey, implKey)
                .then((res) => {
                    const data = res.data.abstractorType;
                    return Promise.resolve(data);
                })
                .catch((err) => { // error!
                    if (err.response instanceof axios.Cancel) {
                        return; // request was canceled.
                    }
                    return Promise.reject(err);
                });
        },
        {
            cacheTime: 0,
            initialData: [],
            ...options,
        },
    );
};