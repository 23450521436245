import React from "react";
import { Spinner } from "@armus/armus-dashboard";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const WorkDetailsSkeleton = ({classes}) => {
    return (
        <React.Fragment>
            <Spinner overlay={true} />
            <Box component={"header"} p={2} pl={3}>
                <Skeleton variant="text" width={"60%"} height={40} />
                <Grid container>
                    <Grid item xs={12}>
                        <Skeleton variant="text" width={"100%"} height={30} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={classes.content} p={0} pl={3}>
                {[0,1,2,3,4].map((index) => {
                    return (
                        <React.Fragment key={index}>
                            <br/>
                            <Skeleton variant="text" width={"20%"} height={30}/>
                            <Skeleton variant="text" width={"80%"}/>
                            <Skeleton variant="text" width={"60%"}/>
                            <br/>
                        </React.Fragment>
                    )
                })}
            </Box>
        </React.Fragment>
    );
};

export default WorkDetailsSkeleton;