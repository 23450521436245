import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import routeKeys, { buildUrl } from "../../../../routes";
import { EnhancedTable, formatMessage, makeSxStyles, SmartTooltip } from "@armus/armus-dashboard";
import history from "../../../lib/history";
import StatusChip from "../components/StatusChip";
import config from "../../../../config";
import {safeDateFormat} from "../../../lib/dateHelpers";
import { useParams } from "react-router-dom";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import { useCaseListQuery } from "../../../lib/queries";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { useFindAbstractableLabel } from "../../components/workFilterHelpers";
import useAddWork from "../components/useAddWork";
import VerificationStatusChip from "../components/VerificationStatusChip";
import { useActiveWorkId } from "../../../providers/WorkDetailsProvider";

const useStyles = makeSxStyles((theme) => ({
    root: {
        height: "100%",
        "& table .Mui-selected": {
            // background:  "rgba(0, 0, 0, 0.04)",
        },
        "& table table .Mui-selected": {
            background:  theme.palette.info.main + "11",
        },
        "& table table .Mui-selected td:first-child": {
            borderLeft: "4px solid " + theme.palette.primary.main,
        }
    },
    assignedUser: {
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    },
    unassignedUser: {
        color: theme.palette.text.hint,
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    }
}));

const statusOrder = {
    "NEW": 0,
    "IN_PROGRESS": 1,
    "REQUIRES_INFORMATION": 2,
    "COMPLETE": 3,
    "INVALID": 4,
    "": 5,
};

const WorkItems = ({row, onClick, workId, onAddWork}) => {
    const classes = useStyles();
    if(!row) {
        return null;
    }
    const caseId = row.id;
    const items = [...row.workSummaryList || []];
    items.sort((a, b) => statusOrder[a.status || ""] - statusOrder[b.status || ""]);
    const selectedWork = items.findIndex(it => it.id.toString() === workId);

    return (
        <Box>
            <EnhancedTable
                rows={items}
                columns={[
                    {
                        id: "w.abstractable.id",
                        label: formatMessage({id: "work.list.headerLabel.type", defaultMessage: "Type"}),
                        isSortable: false,
                        Content: ({row}) => row.abstractableLongname
                    },
                    {
                        id: "createdAt",
                        label: formatMessage({id: "work.list.headerLabel.createdAt", defaultMessage: "Work Age"}),
                        isSortable: false,
                        Content: ({row}) => moment(row.createdAt).utc().fromNow()
                    },
                    {
                        id: "w.status",
                        props: { align: "center", width: "1%"  },
                        isSortable: false,
                        label: "Status",
                        Content: ({row}) => (
                            <StatusChip
                                size={"xs"}
                                status={row.status}
                            />
                        )
                    },
                    {
                        id: "assignedAbstractor",
                        props: { align: "center", },
                        label: formatMessage({id: "work.list.headerLabel.assignedAbstractor", defaultMessage: "Assigned Abstractor"}),
                        isSortable: false,
                        Content: ({row}) => (
                            row.assignedAbstractor ? (
                                <Box sx={classes.assignedUser}>
                                    <AccountIcon />&nbsp;{row.assignedAbstractor.username}
                                </Box>
                            ) : (
                                <Box sx={classes.unassignedUser}>
                                    <AccountIcon />&nbsp;Unassigned
                                </Box>
                            )
                        )
                    },
                    {
                        id: "w.verificationStatus",
                        props: { align: "center", width: "1%"  },
                        isSortable: false,
                        label: "Verification Status",
                        Content: ({row}) => row.status === "COMPLETE" ? (
                            <VerificationStatusChip
                                size={"xs"}
                                status={row.verificationStatus}
                            />
                        ) : null
                    },
                ]}
                total={row.workSummaryList.length}
                emptyMessage={<>This case has no work items. You can add work by clicking the "Add Work" button.</>}
                showHeader={false}
                dense={true}
                selectedCell={{
                    y: selectedWork
                }}
                onRowClick={onClick}
                onCellClick={() => {}}
                hover={true}
                pagination={false}
                fillEmptyRows={false}
                height={"100%"}
            />
            <Box textAlign={"center"} p={1}>
                <Button color={"secondary"} variant={"contained"} disableElevation onClick={() => onAddWork(caseId, workId)}>
                    Add Work
                </Button>
            </Box>
        </Box>
    );
};

const WorkListTableView = (props) => {
    const {
        onCaseClick,
        onWorkClick,
        onChangePage,
        onChangeRowsPerPage,
        onRequestSort,
        searchParams,
        results,
        page,
        total,
        isLoading,
        numberOfAppliedFilters,
        onViewUnassignedWork,
        onViewMyWork,
        onClearFilters,
        onShowAddWorkDialog
    } = props;
    const workId = useActiveWorkId();
    const classes = useStyles();

    const workList = results;

    let emptyMessage = "";
    switch(searchParams.scope) {
        case "mywork":
            emptyMessage = (
                <Box pt={6} textAlign={"center"}  fontSize={20}>
                    You don't have any active work items assigned to you.<br />
                    Click the button below to view all unassigned work to begin. <br /><br />
                    <Button
                        variant={"contained"}
                        disableElevation
                        color={"primary"}
                        onClick={onViewUnassignedWork}
                    >
                        View unassigned work
                    </Button>
                </Box>
            );
            break;
        case "unassigned":
            emptyMessage = (
                <Box pt={6} textAlign={"center"} fontSize={20}>
                    There are currently no unassigned work items available to start. <br />
                    Please check back soon or click the button below to continue progress on your existing work. <br /><br />
                    <Button
                        variant={"contained"}
                        disableElevation
                        color={"primary"}
                        onClick={onViewMyWork}
                    >
                        View My work
                    </Button>
                </Box>
            );
            break;
        case "all":
            emptyMessage = (
                <Box pt={6} textAlign={"center"} fontSize={20}>
                    There are currently no work items available. <br /> Please check back soon.
                </Box>
            );
            break;
        default:
    }
    if(numberOfAppliedFilters > 0) {
        // if they have any filters active and the list is empty tell them to change their filters.
        emptyMessage = (
            <Box pt={6} textAlign={"center"} fontSize={20}>
                There are no work items that match the applied filters. <br />
                Please update your filters or reset them with the button below. <br /><br />
                <Button
                    variant={"contained"}
                    disableElevation
                    color={"primary"}
                    onClick={onClearFilters}
                >
                    Reset Filters
                </Button>
            </Box>
        );
    }
    let selectedCase = undefined;
    if(searchParams.case) {
        selectedCase = workList.findIndex(it => it.id.toString() === searchParams.case);
    }
    else {
        for(let i = 0; i < workList.length; i++) {
            const c = workList[i];
            const index = c.workSummaryList.findIndex(it => it.id.toString() === workId);
            if(index !== -1) {
                selectedCase = i;
                break;
            }
        }
    }

    return (
        <Box sx={classes.root}>
            <EnhancedTable
                dataTable={false}
                rows={workList}
                columns={[
                    {
                        id: "o.shortname",
                        props: { align: "center", width: "1%" },
                        isSortable: true,
                        label: formatMessage({id: "work.list.headerLabel.org", defaultMessage: "Organization"}),
                        Content: ({row, cell, x, y}) => row.orgLongname
                    },
                    {
                        id: "lastName",
                        label: formatMessage({id: "work.list.headerLabel.name", defaultMessage: "Name"}),
                        isSortable: false,
                        Content: ({row}) => <strong>{row.lastName}, {row.firstName}</strong>
                    },
                    {
                        id: "maxCreated",
                        label: formatMessage({id: "work.list.headerLabel.maxCreated", defaultMessage: "Case Age"}),
                        isSortable: true,
                        Content: ({row}) => moment(row.maxCreated).utc().fromNow()
                    },
                    {
                        id: "admitDate",
                        label: formatMessage({id: "work.list.headerLabel.admitDate", defaultMessage: "Admit Date"}),
                        isSortable: true,
                        Content: ({row}) => safeDateFormat(row.admitDate, config.ui.dateFormat)
                    },
                    {
                        id: "mrn",
                        props: { align: "center" },
                        label: (
                            <SmartTooltip
                                arrow
                                useIcon={true}
                                title={formatMessage({id: "work.list.tooltip.mrn", defaultMessage: "Medical Record Number"})}
                            >
                                {formatMessage({id: "work.list.headerLabel.mrn", defaultMessage: "MRN"})}
                            </SmartTooltip>
                        ),
                        isSortable: true,
                        Content: ({row}) => row.mrn
                    },
                    {
                        id: "w.numberOfItems",
                        props: { align: "center" },
                        label: formatMessage({id: "work.list.headerLabel.numberOfItems", defaultMessage: "Work Items"}),
                        isSortable: false,
                        Content: ({row}) => row.workSummaryList.filter((w, i, a) => a.findIndex(t => t.abstractableId === w.abstractableId) === i && w.status !== "INVALID").map(w => w.abstractableLongname).join(", ")
                    },
                ]}
                expandedContent={(props = {}) => <WorkItems {...props} onClick={onWorkClick} workId={workId} onAddWork={onShowAddWorkDialog} />}
                isLoading={isLoading}
                emptyMessage={emptyMessage}
                total={total}
                showHeader={true}
                dense={false}
                rowsPerPage={searchParams.size}
                selectedCell={{
                    y: selectedCase
                }}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onRequestSort={onRequestSort}
                onRowClick={onCaseClick}
                onCellClick={() => {}}
                rowsPerPageOptions={[15, 25, 50, 100]}
                order={searchParams.order}
                orderBy={searchParams.orderBy}
                hover={true}
                pagination={true}
                fillEmptyRows={false}
                height={"100%"}
            />
        </Box>
    );
};

const WorkListTable = (props) => {
    const {orgKey, implKey, workId} = useParams();
    const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams();
    const workListQuery = useCaseListQuery();
    const findAbstractableLabel = useFindAbstractableLabel();
    const onAddWork = useAddWork();
    const data = workListQuery.data || {};
    const results = (data.content || []).map(row => ({
        ...row,
        workSummaryList: row.workSummaryList.map(w => ({
            ...w,
            abstractableLongname: findAbstractableLabel(w.abstractableId) || w.abstractableId
        }))
    }));

    const state = {
        results,
        total: data.totalElements,
        page: data.number,
        isLoading: workListQuery.isLoading || workListQuery.isRefetching,
        searchParams,
        numberOfAppliedFilters: activeFiltersCount,
        onCaseClick: (e, row) => {
            const items = [...row.workSummaryList];
            if(items.find(it => it.id.toString() === workId) || searchParams.case === row.id.toString()) {
                // close the open case
                history.push(buildUrl(routeKeys.WORK_LIST, {orgKey, implKey}, {...searchParams, case: ""}));
            }
            else if(items.length === 0) {
                // no work items open case
                history.push(buildUrl(routeKeys.WORK_LIST, {orgKey, implKey}, {...searchParams, case: row.id}));
            }
            else {
                // open case to first item
                items.sort((a, b) => statusOrder[a.status || ""] - statusOrder[b.status || ""]);
                history.push(buildUrl(
                    routeKeys.WORK_DETAILS, {
                    orgKey,
                    implKey,
                    workId: items[0].id
                }, {...searchParams, case: row.id}))
            }
        },
        onWorkClick: (e, row) => history.push(buildUrl(routeKeys.WORK_DETAILS, {orgKey, implKey, workId: row.id}, searchParams)),

        onShowAddWorkDialog: onAddWork
    };

    return (
        <WorkListTableView
            {...props}
            {...state}
            onChangePage={(e, value) => {onSetFilter("page", value)}}
            onChangeRowsPerPage={(e) => {onSetFilter("size", e.target.value)}}
            onRequestSort={(e, orderBy) => {onSetFilter("orderBy", orderBy)}}
            onViewUnassignedWork={() => {
                onSetFilter("scope", "unassigned");
            }}
            onViewMyWork={() => {
                onSetFilter("scope", "mywork");
            }}
            onClearFilters={() => {
                history.push("?");
            }}
        />
    );
};

export default WorkListTable;
