import React from "react";
import { Spinner } from "@armus/armus-dashboard";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const OrganizationDetailsSkeleton = ({classes}) => {
    return (
        <React.Fragment>
            <Spinner overlay={true}  />
            <Box component={"header"} p={1.5} pl={3}>
                <Skeleton variant="text" width={"60%"} height={40} />
            </Box>
            <Box sx={classes.content} p={0} pl={3}>
                {[0,1,2,3,4].map((index) => {
                    return (
                        <React.Fragment key={index}>
                            <br/>
                            <Skeleton variant="text" width={"20%"} height={30}/>
                            <Skeleton variant="text" width={"80%"}/>
                            <Skeleton variant="text" width={"60%"}/>
                            <br/>
                        </React.Fragment>
                    )
                })}
            </Box>
        </React.Fragment>
    );
};

export default OrganizationDetailsSkeleton;