import React from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormattedMessage, makeSxStyles, SmartLink } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../../../routes";
import config from "../../../../../config";
import moment from "moment-timezone";
import {safeDateFormat} from "../../../../lib/dateHelpers";
import StatusChip from "../../components/StatusChip";
import InfoIcon from "@mui/icons-material/Info";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { noteStyles } from "../../components/Notes";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import { useFindAbstractableLabel } from "../../../components/workFilterHelpers";
import VerificationStatusChip from "../../components/VerificationStatusChip";

const useStyles = makeSxStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderLeft: "4px solid ",
        "&.NEW": {
            borderLeftColor: theme.palette.success.main,
        },
        "&.IN_PROGRESS": {
            borderLeftColor: theme.palette.warning.main,
        },
        "&.REQUIRES_INFORMATION": {
            borderLeftColor: theme.palette.info.main,
        },
        "&.COMPLETE": {
            borderLeftColor: "#888888",
        },
        "&.INVALID": {
            borderLeftColor: "#88888844",
        },
        "& .WorkCardTitle": {
            fontSize: "120%",
        },
        "& .WorkCardStatus": {
            textAlign: "center"
        }
    },
    quote: {
        color: theme.palette.text.hint,
        fontSize:"300%"
    },
    noteContainer: {
        borderRadius: theme.spacing(1),
        background: theme.palette.background.default,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    ...noteStyles,
}));

const WorkCard = (props) => {
    const findAbstractableLabel = useFindAbstractableLabel();
    const classes = useStyles();
    const {data} = props;
    const params = useParams();
    const url = buildUrl(routesKeys.WORK_DETAILS, {...params, workId: data.id});
    const showDetails = props.showDetails && data.hasOwnProperty("associatedCase");
    if(showDetails) {
        // full details
        return (
            <Paper className={`${data.status}`} sx={classes.root}>
                <Grid container>
                    <Grid item xs={9} className={"WorkCardTitle"}>
                        <SmartLink to={url}>
                            <strong>{data.associatedCase.lastName}, {data.associatedCase.firstName}</strong> - {findAbstractableLabel(data.abstractable.id)}
                        </SmartLink>
                    </Grid>
                    <Grid item xs={3} className={"WorkCardStatus"}>
                        <StatusChip status={data.status} size={"xs"} />
                        {data.verificationStatus !== "PENDING_REVIEW" && <VerificationStatusChip status={data.verificationStatus} size={"xs"} />}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <strong>
                            <FormattedMessage
                                id={"home.workCard.workAge.label"}
                                defaultMessage={"Work Age:"}
                            />
                        </strong> {moment(data.createdAt).utc().fromNow()}
                    </Grid>
                    <Grid item xs={5}>
                        <strong>
                            <FormattedMessage
                                id={"home.workCard.admitDate.label"}
                                defaultMessage={"Admit Date:"}
                            />
                        </strong> {safeDateFormat(data.associatedCase.admitDate, config.ui.dateFormat)}
                    </Grid>
                    <Grid item xs={3} className={"WorkCardStatus"}>
                        <strong>
                            <FormattedMessage
                                id={"home.workCard.mrn.label"}
                                defaultMessage={"MRN:"}
                            />
                        </strong> {data.associatedCase.mrn}
                    </Grid>
                </Grid>
                {data.notes.length > 0 && (
                    <Box mt={2}>
                        <Grid container sx={classes.noteContainer}>
                            <Grid item xs={1}>
                                <Box textAlign={"center"}>
                                    <QuoteIcon sx={classes.quote} />
                                </Box>
                            </Grid>
                            <Grid item xs={11}>
                                <Box sx={classes.note} mb={1}>
                                    {data.notes[0].note}
                                </Box>
                                {data.notes[0].user === null ? (
                                    <span sx={classes.itemUesr}>
                                        <InfoIcon />{" "}
                                        <strong>System Event</strong>
                                    </span>
                                ) : (
                                    <span sx={classes.itemUesr}>
                                        <AccountIcon />{" "}
                                        <strong>{data.notes[0].user.username}</strong>
                                    </span>
                                )}
                                {" "}
                                <FormattedMessage
                                    id={"home.workCard.note.addedOn"}
                                    defaultMessage={"Added On"}
                                />
                                <strong>{safeDateFormat(data.notes[0].noteCreatedAt, config.ui.dateTimeCommentFormat)}</strong>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Paper>
        );
    }
    return (
        <Paper className={`${data.status}`} sx={classes.root}>
            <Grid container>
                <Grid item xs={9} className={"WorkCardTitle"}>
                    <SmartLink to={url}>
                        <strong>{data.lastName}, {data.firstName}</strong> - {findAbstractableLabel(data.abstractableId)}
                    </SmartLink>
                </Grid>
                <Grid item xs={3} className={"WorkCardStatus"}>
                    <StatusChip status={data.status} size={"xs"} />
                    {data.verificationStatus !== "PENDING_REVIEW" && <VerificationStatusChip status={data.verificationStatus} size={"xs"} />}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={4}>
                    <strong>
                        <FormattedMessage
                            id={"home.workCard.workAge.label"}
                            defaultMessage={"Work Age:"}
                        />
                    </strong> {moment(data.createdAt).utc().fromNow()}
                </Grid>
                <Grid item xs={5}>
                    <strong>
                        <FormattedMessage
                            id={"home.workCard.admitDate.label"}
                            defaultMessage={"Admit Date:"}
                        />
                    </strong> {safeDateFormat(data.admitDate, config.ui.dateFormat, false)}
                </Grid>
                <Grid item xs={3} className={"WorkCardStatus"}>
                    <strong>
                        <FormattedMessage
                            id={"home.workCard.mrn.label"}
                            defaultMessage={"MRN:"}
                        />
                    </strong> {data.mrn}
                </Grid>
            </Grid>
        </Paper>
    );
};

WorkCard.propTypes = {
    data: PropTypes.object.isRequired,
    showDetails: PropTypes.bool,
};

export default WorkCard;