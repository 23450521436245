import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import routeKeys, { buildUrl } from "../../../../routes";
import { EnhancedTable, formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import history from "../../../lib/history";
import { useParams } from "react-router-dom";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import { useWorkListQuery } from "../../../lib/queries";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { useFindAbstractableLabel } from "../../components/workFilterHelpers";
import VerificationStatusChip from "../../Work/components/VerificationStatusChip";
import { useActiveWorkId } from "../../../providers/WorkDetailsProvider";

const useStyles = makeSxStyles((theme) => ({
    root: {
        height: "100%",
        "& table .Mui-selected": {
            // background:  "rgba(0, 0, 0, 0.04)",
        },
        "& table table .Mui-selected": {
            background:  theme.palette.info.main + "11",
        },
        "& table table .Mui-selected td:first-child": {
            borderLeft: "4px solid " + theme.palette.primary.main,
        }
    },
    assignedUser: {
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    },
    unassignedUser: {
        color: theme.palette.text.hint,
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    }
}));

const WorkListTableView = (props) => {
    const {
        onWorkClick,
        onChangePage,
        onChangeRowsPerPage,
        onRequestSort,
        searchParams,
        results,
        page,
        total,
        isLoading,
        numberOfAppliedFilters,
        onClearFilters
    } = props;
    const workId = useActiveWorkId();
    const classes = useStyles();

    const workList = results;

    let emptyMessage = (
        <Box pt={6} textAlign={"center"} fontSize={20}>
            There are currently no work items available. <br /> Please check back soon.
            {searchParams.verificationMethod !== "" && <Button
                variant={"contained"}
                disableElevation
                color={"primary"}
                onClick={onClearFilters}
            >
                Clear All Filters
            </Button>}
        </Box>
    );
    if(numberOfAppliedFilters > 0) {
        // if they have any filters active and the list is empty tell them to change their filters.
        emptyMessage = (
            <Box pt={6} textAlign={"center"} fontSize={20}>
                There are no work items that match the applied filters. <br />
                Please update your filters or reset them with the button below. <br /><br />
                <Button
                    variant={"contained"}
                    disableElevation
                    color={"primary"}
                    onClick={onClearFilters}
                >
                    Reset Filters
                </Button>
            </Box>
        );
    }

    const selectedWork = workList.findIndex(it => it.id.toString() === workId);

    return (
        <Box sx={classes.root}>
            <EnhancedTable
                rows={workList}
                columns={[
                    {
                        id: "orgShortname",
                        label: formatMessage({id: "work.list.headerLabel.org", defaultMessage: "Organization"}),
                        isSortable: true,
                        Content: ({row}) => row.orgLongname
                    },
                    {
                        id: "assignedAbstractor.username",
                        props: { align: "center", },
                        label: formatMessage({id: "work.list.headerLabel.assignedAbstractor", defaultMessage: "Assigned Abstractor"}),
                        isSortable: true,
                        Content: ({row}) => (
                            row.assignedAbstractor ? (
                                <Box sx={classes.assignedUser}>
                                    <AccountIcon />&nbsp;{row.assignedAbstractor.username}
                                </Box>
                            ) : (
                                <Box sx={classes.unassignedUser}>
                                    <AccountIcon />&nbsp;Unassigned
                                </Box>
                            )
                        )
                    },
                    {
                        id: "completedAt",
                        label: formatMessage({id: "work.list.headerLabel.completeDate", defaultMessage: "Complete Date"}),
                        isSortable: true,
                        Content: ({row}) => moment(row.completeDate).utc().fromNow()
                    },
                    {
                        id: "abstractableId",
                        label: formatMessage({id: "work.list.headerLabel.type", defaultMessage: "Type"}),
                        isSortable: true,
                        Content: ({row}) => row.abstractableLongname
                    },
                    {
                        id: "mrn",
                        label: formatMessage({id: "work.list.headerLabel.mrn", defaultMessage: "MRN"}),
                        isSortable: true,
                        Content: ({row}) => row.mrn
                    },
                    {
                        id: "registryGeneratedId",
                        label: formatMessage({id: "work.list.headerLabel.registryGeneratedId", defaultMessage: "Registry Generated ID"}),
                        isSortable: true,
                        Content: ({row}) => row.registryGeneratedId
                    },
                    {
                        id: "verificationStatus",
                        props: { align: "center", width: "10%"  },
                        isSortable: true,
                        label: "Verification",
                        Content: ({row}) =>
                            <VerificationStatusChip
                                size={"xs"}
                                status={row.verificationStatus}
                            />
                    },
                    {
                        id: "verificationMethod",
                        props: { align: "center", width: "10%"  },
                        label: formatMessage({id: "work.list.headerLabel.verificationMethod", defaultMessage: "Method"}),
                        isSortable: true,
                        Content: ({row}) => row.verificationMethod === "AUTOMATIC" ? "Automatic" : "Manual"
                    },
                ]}
                isLoading={isLoading}
                emptyMessage={emptyMessage}
                total={total}
                showHeader={true}
                dense={false}
                rowsPerPage={searchParams.size}
                selectedCell={{
                    y: selectedWork
                }}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onRequestSort={onRequestSort}
                onRowClick={onWorkClick}
                onCellClick={() => {}}
                rowsPerPageOptions={[15, 25, 50, 100]}
                order={searchParams.order}
                orderBy={searchParams.orderBy}
                hover={true}
                pagination={true}
                fillEmptyRows={false}
                height={"100%"}
            />
        </Box>
    );
};

const WorkListTable = (props) => {
    const {orgKey, implKey} = useParams();
    const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams();
    const workListQuery = useWorkListQuery();
    const findAbstractableLabel = useFindAbstractableLabel();
    const data = workListQuery.data || {content: []};
    const results = (data.content || []).map(w => ({
        ...w,
        abstractableLongname: findAbstractableLabel(w.abstractableId) || w.abstractableId
    })).filter(it => it.status === "COMPLETE");

    const state = {
        results,
        total: data.totalElements,
        page: data.number,
        isLoading: workListQuery.isLoading || workListQuery.isRefetching,
        searchParams,
        numberOfAppliedFilters: activeFiltersCount,
        onWorkClick: (e, row) => history.push(buildUrl(routeKeys.VERIFICATION_DETAILS, {orgKey, implKey, workId: row.id}, searchParams)),
    };

    return (
        <WorkListTableView
            {...props}
            {...state}
            onChangePage={(e, value) => {onSetFilter("page", value)}}
            onChangeRowsPerPage={(e) => {onSetFilter("size", e.target.value)}}
            onRequestSort={(e, orderBy) => {onSetFilter("orderBy", orderBy)}}
            onClearFilters={() => {
                history.push("?");
            }}
        />
    );
};

export default WorkListTable;