import React  from "react";
import {
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import ErrorPage from "./views/ErrorPage";
import { useDefaultRoutePath } from "./providers/UserProvider";
import SecuredRoute from "./views/components/SecuredRoute";
import { getApplicationRoutes } from "../routes";
import PageLayout from "./views/components/PageLayout";


export default function OuterTemplate() {
    const defaultUrl = useDefaultRoutePath();
    return (
        <Switch>
            {/* Add All Application Routes */}
            {getApplicationRoutes().map((it) => {
                if(it.type === "redirect") {
                    return <Redirect key={it.key} {...it} />
                }
                return <SecuredRoute key={it.key} {...it} />
            })}

            {/* root redirect to users default org */}
            {defaultUrl && <Redirect exact from="/(api)?" to={defaultUrl} />}

            {/* Catchall Error Page */}
            <Route path="/*" component={() => <PageLayout><ErrorPage statusCode={401} /></PageLayout>} />
        </Switch>
    );
}
