import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SubmitIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import PassedIcon from "@mui/icons-material/ThumbUp";
import FailedIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Undo";
import { useWorkDetailsVerification, verificationToTypes } from "../../../../providers/WorkDetailsVerificationProvider";
import {
  formatMessage,
  FormattedMessage,
  makeSxStyles,
} from "@armus/armus-dashboard";
import WorkDetailsVerificationActions from "./WorkDetailsVerificationActions";

const useStyles = makeSxStyles((theme) => ({
  root: {
    transition: ".5s",
    overflow: "auto",
    maxHeight: 0,
    "&.opened": {
      maxHeight: 500,
    },
  },
}));

const WorkDetailsVerificationResolver = ({
  workDetails,
  isMyWork,
  isUnassigned,
}) => {
  const verificationState = useWorkDetailsVerification(workDetails);
  const classes = useStyles();
  const handleCancel = () => {
    verificationState.onCancel();
  };

  const handleSubmit = () => {
    verificationState.onSubmit();
  };

  const handleDataChange = (key, value) => {
    verificationState.onChange(key, value);
  };
  if (verificationState.transitionTo === null) {
    
    return (
      <WorkDetailsVerificationActions
        isMyWork={isMyWork}
        isUnassigned={isUnassigned}
        workDetails={workDetails}
      />
    );
  }
  let submitIcon = <SubmitIcon />;
  let content = null;
  let submitDisabled = false;
  let submitColor = "info";
  let actionLabel = formatMessage({
    id: "workDetails.transitionTo.actionLabel",
    defaultMessage: "submit",
  });
  const noteLabel = formatMessage({
    id: "workDetails.transitionTo.noteInputLabel",
    defaultMessage: "Note",
  });
  switch (verificationState.transitionTo) {
    case verificationToTypes.FAILED:
      actionLabel = formatMessage({
        id: "workDetails.transitionTo.failed.actionLabel",
        defaultMessage: "Failed Verification",
      });
      submitIcon = <FailedIcon />;
      submitDisabled = !verificationState.data.note;
      submitColor = "error";
      content = (
        <React.Fragment>
          <Box mb={2}>
            <FormattedMessage
              id={"workDetails.transitionTo.failed.instructions"}
              defaultMessage={
                "Please provide a reason for failing the work item verification status."
              }
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={verificationState.data.note || ""}
                label={noteLabel}
                required={true}
                error={!verificationState.data.note}
                variant={"outlined"}
                color={"primary"}
                multiline
                fullWidth={true}
                onChange={(e) => handleDataChange("note", e.target.value)}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
      break;
      case verificationToTypes.VERIFIED:
        submitColor = "primary";
      actionLabel = formatMessage({
        id: "workDetails.transitionTo.verified.actionLabel",
        defaultMessage: "Passed Verification",
      });
      submitIcon = <PassedIcon />;
      content = (
        <React.Fragment>
          <Box mb={2}>
            <FormattedMessage
              id={"workDetails.transitionTo.verified.instructions"}
              defaultMessage={
                "Please confirm you are passing the work item verification status."
              }
            />
          </Box>
        </React.Fragment>
      );
      break;
      case verificationToTypes.PENDING_REVIEW:
        actionLabel = formatMessage({
          id: "workDetails.transitionTo.pending.actionLabel",
          defaultMessage: "Pending Review",
        });
        submitIcon = <PendingIcon />;
        content = (
          <React.Fragment>
            <Box mb={2}>
              <FormattedMessage
                id={"workDetails.transitionTo.pending.instructions"}
                defaultMessage={
                  "Please provide a reason for resetting the work item verification status."
                }
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={verificationState.data.note || ""}
                  label={noteLabel}
                  required={false}
                  variant={"outlined"}
                  color={"primary"}
                  multiline
                  fullWidth={true}
                  onChange={(e) => handleDataChange("note", e.target.value)}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
        break;
    default:
      content = null;
  }

  if (content !== null) {
    // add buttons
    content = (
      <React.Fragment>
        {content}
        <Box pt={2}>
          <Button
            color={submitColor}
            variant={"contained"}
            size={"large"}
            disableElevation
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            {submitIcon} &nbsp; {actionLabel}
          </Button>
          &nbsp;
          <Button
            color={"default"}
            size={"large"}
            disableElevation
            onClick={handleCancel}
          >
            <CancelIcon /> &nbsp; Cancel
          </Button>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <Box className={(content ? " opened" : "")} sx={classes.root}>
      <Box minHeight={100}>{content}</Box>
    </Box>
  );
};

export default WorkDetailsVerificationResolver;
