import React from "react";
import { useLocation } from "react-router-dom";
import history from "../../lib/history";
import qs from "query-string";

export default function useReportingSearchParams() {
    const {search} = useLocation();
    const value = React.useMemo(() => {
            const params = {
                // Default filter keys and values.
                "abstractable": [],
                "afterDate": "",
                "beforeDate": "",
                "dateType": "complete",
                "reportType": "organization", // "abstractor"

                // spread the client url params here
                ...(qs.parse(search || "") || {}),
            };

            if(params.abstractable !== "") {
                if(!Array.isArray(params.abstractable)) {
                    // should be an array
                    params.abstractable = [params.abstractable];
                }
                // is an array of long ids
                params.abstractable = params.abstractable.map((v) => parseInt(v, 10));
            }
            // if(params.organization !== "") {
            //     if(!Array.isArray(params.organization)) {
            //         // should be an array
            //         params.organization = [params.organization];
            //     }
            // }
            return params;
        },
        [search]
    );

    let activeFiltersCount = 0;
    [
        "organization",
        "abstractable",
        "afterDate",
        "beforeDate",
        "dateType",
        "reportType",
    ].forEach((key) => {
        let v = value[key];
        if(v !== "" && v !== null) {
            activeFiltersCount++;
        }
    });

    const setValue = (key, v) => {
        let valueChanged = false;
        const searchParams = value;
        let spreadValue = {};

        if(typeof key === "object") {
            // multi key value
            spreadValue = key;
            Object.keys(spreadValue).forEach((key) => {
                if(spreadValue[key] !== searchParams[key]) {
                    valueChanged = true;
                }
            });
        }
        else {
            // single key value
            spreadValue[key] = v;
            if (searchParams[key] !== v) {
                valueChanged = true;
            }
        }

        if(valueChanged) {
            const query = {
                ...searchParams,
                ...spreadValue
            };
            Object.keys(query).forEach(key => {
                if(query[key] === "") {
                    // remove the empty value filters to keep the url clean.
                    delete query[key];
                }
            });
            history.push("?" + qs.stringify(query));
        }
    };
    return [value, activeFiltersCount, setValue];
}