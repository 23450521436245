import React from "react";
import { Gauge as CircularProgressWithContent, makeSxStyles } from "@armus/armus-dashboard";
import Box from "@mui/material/Box";

const useStyles = makeSxStyles((theme) => ({
    root: {
        position: "relative",
        zIndex: 1,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "top",
        textAlign: "center",
        transform: "scale(1)",
        transition: ".25s",
        "&:hover": {
            transform: "scale(1.2)",
        },
        "& .MuiCategoryContainer": {
            display: "inline-block",
            width: 200,
            textAlign: "center",
            //marginTop: theme.spacing(2),
        },
        "& .MuiGaugeContainer": {
        },

    },
    value: {
        fontWeight: "bold",
        fontSize: theme.spacing(2.5)
    },
    percent: {
        color: theme.palette.text.primary
    },
}));

const Gauge = (props) => {
    const {label, value, color, size = "small"} = props;
    let {percent} = props;
    if(isNaN(percent)) {
        percent = 0;
    }
    const classes = useStyles();
    return (
        <Box sx={classes.root}>
            <Box className={"MuiCategoryContainer"}>
                <strong>{label}</strong>
                <Box className={"MuiGaugeContainer"} mt={size === "large" ? 0 : 1} mb={size === "large" ? 0 : 1}>
                    <CircularProgressWithContent
                        variant="determinate"
                        size={size === "large" ? 140 : 120}
                        thickness={size === "large" ? 8 : 6}
                        value={percent * 100}
                        color={color}
                        disableBackground={false}
                    >
                        <Box>
                            <Box sx={classes.value}>{Math.round( ( value + Number.EPSILON ) * 100 ) / 100}</Box>
                            <Box sx={classes.percent}>{Math.round( (( (percent * 100) + Number.EPSILON ) * 10 ) / 10) }%</Box>
                        </Box>
                    </CircularProgressWithContent>
                </Box>
            </Box>
        </Box>
    );
};

export default Gauge;