import moment from 'moment-timezone';

const baseEl = window.document.querySelector('base');
let basePath = ((baseEl && baseEl.href) || '').replace(window.location.origin, '');
if(process.env.NODE_ENV === "development") {
    // context path for local dev is always the root.
    baseEl.href = "/";
    basePath = "/";
}
export const contextPath = basePath;

// these config defaults will be overloaded by "window.AppConfig" which gets
// set by config "public/config.js" included in the index.html file.

const defaultConfig = {
    contextPath: contextPath,
    endpoints: {
        apiRoot: "api/",
        login: "api/",
        logout: "api/logout",
        support: "https://armussupport.zendesk.com",
    },
    analytics: {},
    cache: {
        useCache: true,
    },
    session: {
        timeout: 45 * 60 * 1000,
        interval: 5 * 60 * 1000,
        keepAlive: true,
        autoLogout: true,
        CSRF_COOKIE_KEY: "XSRF-TOKEN",
        CSRF_POST_KEY: "_csrf",
    },
    ui: {
        timeFormat: "h:mm A",
        dateFormat: "MM/DD/YYYY",
        dateFormatInput: "MM/DD/YYYY",
        dateCommentFormat: "MMMM Do YYYY",
        dateTimeFormat: "MM/DD/YYYY h:mm A",
        dateTimeFormatInput: "MM/DD/YYYY h:mm",
        dateTimeCommentFormat: "MMMM Do YYYY, h:mm A",
        timeZoneName: undefined,
    },
    mocks: {}
};

const config = {...defaultConfig, ...window.AppConfig};

moment.tz.setDefault(config.ui.timeZoneName || Intl.DateTimeFormat().resolvedOptions().timeZone);
moment.defaultFormat = config.ui.dateFormat || moment.defaultFormat;

if(process.env.NODE_ENV === "development") {
    config.session.keepAlive = false;
    config.session.autoLogout = false;
    config.session.timeout  = 5000; // log out after 10 seconds
    config.session.interval = 1000; // ping interval every 1 second

    config.cache.useCache = true;

    config.mocks.baseUrl = "/static/mocks";
    config.mocks.useMocks = true;
    config.mocks.showMockMenu = true;
    config.mocks.logRequests = true;
    config.mocks.loadingDelay = 500; // between 0 - 1000 ms

    // For local server-less development
    // initialize with a mockset selected.
    // or use ?mockset=trekkie
    config.mocks.useMocksetKey = "admin";
}

export default config;
