import { formatMessage } from "@armus/armus-dashboard";
import moment from "moment-timezone";
import { useWorkFiltersQuery } from "../../lib/queries";
import config from "../../../config";

const statuses = [
  {
    label: formatMessage({
      id: "statuses.anyLabel",
      defaultMessage: "Any",
    }),
    value: "",
  },
  {
    label: formatMessage({
      id: "statuses.newLabel",
      defaultMessage: "New",
    }),
    value: "NEW",
    color: "success",
  },
  {
    label: formatMessage({
      id: "statuses.inProgressLabel",
      defaultMessage: "In Progress",
    }),
    value: "IN_PROGRESS",
    color: "warning",
  },
  {
    label: formatMessage({
      id: "statuses.requiresInformationLabel",
      defaultMessage: "Requires Information",
    }),
    value: "REQUIRES_INFORMATION",
    color: "info",
  },
  {
    label: formatMessage({
      id: "statuses.invalidLabel",
      defaultMessage: "Invalid",
    }),
    value: "INVALID",
    color: "default",
  },
  {
    label: formatMessage({
      id: "statuses.completeLabel",
      defaultMessage: "Complete",
    }),
    value: "COMPLETE",
    color: "secondary",
  },
];

const verificationStatuses = [
  {
    label: formatMessage({
      id: "verificationStatuses.anyLabel",
      defaultMessage: "Any",
    }),
    value: "",
  },
  {
    label: formatMessage({
      id: "verificationStatuses.pendingReviewLabel",
      defaultMessage: "Pending Review",
    }),
    value: "PENDING_REVIEW",
    color: "secondary",
  },
  {
    label: formatMessage({
      id: "verificationStatuses.failedLabel",
      defaultMessage: "Failed",
    }),
    value: "FAILED",
    color: "error",
  },
  {
    label: formatMessage({
      id: "verificationStatuses.verifiedLabel",
      defaultMessage: "Verified",
    }),
    value: "VERIFIED",
    color: "success",
  },
  {
    label: formatMessage({
      id: "verificationStatuses.invalidLabel",
      defaultMessage: "Invalid",
    }),
    value: "INVALID",
    color: "default",
  },
];

const scopes = [
  {
    label: formatMessage({
      id: "scope.myWorkLabel",
      defaultMessage: "My Work",
    }),
    value: "mywork",
  },
  {
    label: formatMessage({
      id: "scope.unassignedLabel",
      defaultMessage: "Unassigned Work",
    }),
    value: "unassigned",
  },
  {
    label: formatMessage({
      id: "scope.allLabel",
      defaultMessage: "All Work",
    }),
    value: "all",
  },
];

const verificationMethods = [
  {
    label: formatMessage({
      id: "verificationMethods.allLabel",
      defaultMessage: "All",
    }),
    value: "",
  },
  {
    label: formatMessage({
      id: "verificationMethods.manualLabel",
      defaultMessage: "Manual",
    }),
    value: "MANUAL",
  },
  {
    label: formatMessage({
      id: "verificationMethods.automaticLabel",
      defaultMessage: "Automatic",
    }),
    value: "AUTOMATIC",
  },
];

export const getStatusInfo = (status) => {
  const props = {
    label: "Unknown",
    color: "default",
    variant: "outlined",
  };
  const statusInfo = statuses.find((it) => it.value === status && it.value !== "");
  if (statusInfo) {
    props.label = statusInfo.label;
    props.color = statusInfo.color;
    if (status === "COMPLETE") {
      props.variant = "default";
    }
  } else {
    props.disabled = true;
  }
  props.title = `Work Status: ${props.label}`;
  return props;
};

export const getVerificationStatusInfo = (status) => {
  const props = {
    label: "Unknown",
    color: "default",
    variant: "default",
  };
  const statusInfo = verificationStatuses.find((it) => it.value === status && it.value !== "");
  if (statusInfo) {
    props.label = statusInfo.label;
    props.color = statusInfo.color;
  } else {
    props.disabled = true;
  }
  props.title = `Verification Status: ${props.label}`;
  return props;
};

export const buildFilterInfo = (filterData = {}, searchParams = {}) => ({
  scope: {
    visible: false,
    label: formatMessage({
      id: "scopes.scopeLabel",
      defaultMessage: "Scope",
    }),
    items: scopes,
    value: scopes.find((it) => it.value === searchParams.scope) || "",
  },
  mrn: {
    visible: true,
    label: formatMessage({ id: "filters.mrnLabel", defaultMessage: "MRN" }),
    value: {
      label: searchParams.mrn ? searchParams.mrn : "",
      value: searchParams.mrn || "",
    },
  },
  allowNoWork: {
    visible: true,
    label: formatMessage({ id: "filters.allowNoWorkLabel", defaultMessage: "Show Cases Without Work" }),
    value: {
      label: searchParams.allowNoWork === "true" ? "Yes" : "No",
      value: searchParams.allowNoWork,
    },
  },
  organization: {
    visible: true,
    label: formatMessage({
      id: "filters.organizationLabel",
      defaultMessage: "Organization",
    }),
    items: filterData.organizations || [],
    value: {
      label: (filterData.organizations || [])
        .filter((it) => (searchParams.organization || []).includes(it.value))
        .map((it) => it.label)
        .join(", "),
      value: searchParams.organization
        ? (filterData.organizations || []).filter((it) =>
            (searchParams.organization || []).includes(it.value)
          )
        : "",
    },
  },
  abstractable: {
    visible: true,
    label: formatMessage({ id: "filters.typeLabel", defaultMessage: "Type" }),
    items: filterData.abstractables || [],
    value: {
      label: (filterData.abstractables || [])
        .filter((it) => (searchParams.abstractable || []).includes(it.value))
        .map((it) => it.label)
        .join(", "),
      value: searchParams.abstractable
        ? (filterData.abstractables || []).filter((it) =>
            (searchParams.abstractable || []).includes(it.value)
          )
        : "",
    },
  },
  status: {
    visible: true,
    label: formatMessage({
      id: "statuses.statusLabel",
      defaultMessage: "Status",
    }),
    items: statuses,
    value: statuses.find((it) => it.value === searchParams.status) || "",
  },
  verificationStatus: {
    visible: true,
    label: formatMessage({
      id: "statuses.verificationStatusLabel",
      defaultMessage: "Verification Status",
    }),
    items: verificationStatuses,
    value:
      verificationStatuses.find(
        (it) => it.value === searchParams.verificationStatus
      ) || "",
  },
  createdAtAfter: {
    visible: true,
    label: formatMessage({
      id: "filters.createdAtAfter",
      defaultMessage: "Created After",
    }),
    value: {
      label:
        typeof searchParams.createdAtAfter === "number"
          ? Math.abs(searchParams.createdAtAfter) + " days ago"
          : "",
      value:
        typeof searchParams.createdAtAfter === "number"
          ? searchParams.createdAtAfter
          : "",
    },
  },
  createdAtBefore: {
    visible: true,
    label: formatMessage({
      id: "filters.createdAtBefore",
      defaultMessage: "Created Before",
    }),
    value: {
      label:
        typeof searchParams.createdAtBefore === "number"
          ? Math.abs(searchParams.createdAtBefore) + " days ago"
          : "",
      value:
        typeof searchParams.createdAtBefore === "number"
          ? searchParams.createdAtBefore
          : "",
    },
  },
  admitAfter: {
    visible: true,
    label: formatMessage({
      id: "filters.admitAfterLabel",
      defaultMessage: "Admitted After",
    }),
    value: {
      label: searchParams.admitAfter
        ? moment(searchParams.admitAfter).format(config.ui.dateFormat)
        : "",
      value: searchParams.admitAfter || "",
    },
  },
  admitBefore: {
    visible: true,
    label: formatMessage({
      id: "filters.admitBeforeLabel",
      defaultMessage: "Admitted Before",
    }),
    value: {
      label: searchParams.admitBefore
        ? moment(searchParams.admitBefore).format(config.ui.dateFormat)
        : "",
      value: searchParams.admitBefore || "",
    },
  },
  verificationMethod: {
    visible: false,
    label: formatMessage({
      id: "verificationMethods.verificationMethodLabel",
      defaultMessage: "Verification Method",
    }),
    items: verificationMethods,
    value: verificationMethods.find((it) => it.value === searchParams.verificationMethod) || "",
  },
});

export const useFindAbstractableLabel = () => {
  const workFiltersQuery = useWorkFiltersQuery();
  return (abstractableId) => {
    if (workFiltersQuery.isLoading || !workFiltersQuery.isSuccess)
      return abstractableId;
    if (typeof abstractableId === "string") {
      abstractableId = parseInt(abstractableId, 10);
    }
    return (
      (workFiltersQuery.data.abstractables || []).find(
        (it) => it.value === abstractableId
      ) || {}
    ).label;
  };
};
