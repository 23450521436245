import React from "react";
import { InfoChip } from "@armus/armus-dashboard";
import { getStatusInfo } from "../../components/workFilterHelpers";

const StatusChip = ({status, variant = "outlined", size = "small"}) => {
    return (
        <InfoChip
            variant={variant}
            size={size}
            {...getStatusInfo(status)}
        />
    );
};

export default StatusChip;