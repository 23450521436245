import React from "react";
import config from "../../../../config";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AccountIcon from "@mui/icons-material/AccountCircle";
import CommentIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Check";
import {safeDateFormat} from "../../../lib/dateHelpers";
import { FormattedMessage, SectionExpando, makeSxStyles } from "@armus/armus-dashboard";
import { getStatusInfo } from "../../components/workFilterHelpers";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';

export const noteStyles = {
    itemUesr: {
        textTransform: "capitalize",
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        }
    },
    note: {
        whiteSpace: "pre-line",

    }
};
const useStyles = makeSxStyles((theme) => ({
    ...noteStyles,
    addSeperator: {
        opacity: .25,
    },
    timeline: {
        padding: 0,
        "& .status-changed": {
            //transform: "scale(1.5)"
        },
        "& .status-unchanged": {
            transform: "scale(1)",
            backgroundColor: "transparent!important"
        },
        "& .success": {
            backgroundColor: theme.palette.success.main,
            borderColor: theme.palette.success.main,
        },
        "& .warning": {
            backgroundColor: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
        },
        "& .error": {
            backgroundColor: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
        "& .info": {
            backgroundColor: theme.palette.info.main,
            borderColor: theme.palette.info.main,
        },
        "& .default, .secondary": {
            backgroundColor: theme.palette.grey["500"],
            borderColor: theme.palette.grey["500"],
        }
    }
}));


const getStatusFromNote = (note) => {
    if(note.note.indexOf("Status changed from") !== -1) {
        const [status] = note.note.replace("Status changed from ", "").split(" to ");
        return status;
    }
    return null;
};

const Notes = (props) => {
    const {title, items, onAdd, canAdd, status} = props;
    const classes = useStyles();
    const [state, setState] = React.useState({
        message: "",
        isEditing: false
    });
    const { isEditing, message } = state;

    const onToggleEditing = () => {
        setState({...state, isEditing: !isEditing, message: ""});
    };

    const onChange = (e) => {
        setState({...state, message: e.target.value});
    };
    const singularTitle = title.substr(0, title.length-1);
    let currentStatus = status;
    let lastStatus;
    let statusProps = getStatusInfo(currentStatus);
    return (
        <SectionExpando 
            title={title} 
            mb={3}
            indentContent={2}
        >
            <Timeline align="left" sx={classes.timeline}>
                {!canAdd ? null : (
                    <TimelineItem>
                        <TimelineOppositeContent style={{flex: .25}}>
                            <Typography color="textSecondary">
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator sx={classes.addSeperator}>
                            {items.length > 0 && <React.Fragment>
                                <TimelineDot
                                    className={(statusProps.color ? " status-unchanged " + statusProps.color : "")}
                                    title={statusProps.label}
                                    style={{marginTop: 18}}
                                />
                                <TimelineConnector
                                    className={(statusProps.color ? " " + statusProps.color : "")}
                                    title={statusProps.label}
                                />
                            </React.Fragment>}
                        </TimelineSeparator>
                        <TimelineContent>
                            {(!isEditing ? (
                                <div sx={classes.addContainer}>
                                    <Button color="secondary" variant="contained" onClick={onToggleEditing}>
                                        <CommentIcon /> {" "} &nbsp;
                                        <FormattedMessage
                                            id={"note.addButtonLabel"}
                                            defaultMessage={"Add {title}"}
                                            values={{title: singularTitle}}
                                        />
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <TextField
                                        label="Message"
                                        placeholder={`Type your ${singularTitle.toLowerCase()} here.`}
                                        multiline
                                        fullWidth={true}
                                        variant="outlined"
                                        value={message}
                                        onChange={onChange}
                                    />
                                    <Box mt={1}>
                                        <Button color="primary" disabled={!message.length} disableElevation={true} variant="contained" onClick={() => {onToggleEditing(); onAdd(message);}}>
                                            <SaveIcon /> {" "}
                                            <FormattedMessage
                                                id={"note.saveButtonLabel"}
                                                defaultMessage={"Add {title}"}
                                                values={{title: singularTitle}}
                                            />
                                        </Button> {" "}
                                        <Button color="default" onClick={onToggleEditing}>
                                            <CloseIcon /> {" "}
                                            <FormattedMessage
                                                id={"note.cancelButtonLabel"}
                                                defaultMessage={"Cancel"}
                                            />
                                        </Button>
                                    </Box>
                                </div>
                            ))}
                        </TimelineContent>
                    </TimelineItem>
                )}
                {items.length > 0 ? (
                    items.map((item, index) => {
                        lastStatus = currentStatus;
                        const itemStatus = getStatusFromNote(item);
                        currentStatus = itemStatus || currentStatus;
                        const statusChanged = (itemStatus && lastStatus !== currentStatus) || (status && index === items.length-1);
                        statusProps = getStatusInfo(currentStatus);
                        const lastStatusProps = getStatusInfo(lastStatus);
                        return (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent style={{flex: .25}}>
                                    <Typography color="textSecondary">
                                        {safeDateFormat(item.noteCreatedAt, config.ui.dateCommentFormat)}
                                        <br />
                                        {safeDateFormat(item.noteCreatedAt, config.ui.timeFormat)}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot
                                        className={(statusChanged ? " status-changed" : " status-unchanged" ) + (lastStatusProps.color ? " " + lastStatusProps.color : " ")}
                                        title={lastStatusProps.label}
                                    />
                                    <TimelineConnector
                                        className={(statusProps.color ? " " + statusProps.color : "")}
                                        title={statusProps.label}
                                    />
                                </TimelineSeparator>
                                <TimelineContent>
                                    {item.user === null ? (
                                        <Box sx={classes.itemUesr}>
                                            <InfoIcon />{" "}
                                            <strong>System Event</strong>
                                        </Box>
                                    ) : (
                                        <Box sx={classes.itemUesr}>
                                            <AccountIcon />{" "}
                                            <strong>{item.user.username}</strong>
                                        </Box>
                                    )}
                                    <Typography sx={classes.note}>
                                        {item.note}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                ) : (
                    <Box mb={2}>
                        <FormattedMessage
                            id={"note.noNotesToShowLabel"}
                            defaultMessage={"There are no {title} available to show."}
                            values={{title: title.toLowerCase()}}
                        />
                    </Box>
                )}
            </Timeline>
        </SectionExpando>
    );
};

export default Notes;