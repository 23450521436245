import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import WorkListFilters from "../../Work/WorkList/WorkListFilters";
import { FormattedMessage, makeSxStyles, Pane } from "@armus/armus-dashboard";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import WorkListTable from "./WorkListTable";
import WorkFiltersProvider from "../../../providers/WorkFiltersProvider";
import useChangeDebounce from "../../components/useChangeDebounce";
import { Tab, Tabs } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
  root: {},
  header: {
    transition: "0.5s",
    overflow: "hidden",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.mode === "dark" ? "#515151" : "#e0e0e0",
  },
  content: {
    transition: "0.5s",
  },
  search: {
    display: "inline-block",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.15)" : "rgba(255,255,255,.75)",
    marginLeft: 0,
    marginTop: .25,
    padding: theme.spacing(0, 2),
    width: "auto",
    height: 34,
  },
  searchIcon: {
    paddingRight: 1,
    height: "100%",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    width: "20ch",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
}));

const WorkListPaneView = (props) => {
  const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams();
  const handleDebounceChange = useChangeDebounce(onSetFilter);
  const [state, setState] = React.useState({ showFilters: false, registryGeneratedId: searchParams.registryGeneratedId });
  const classes = useStyles();
  let filterHeight = state.showFilters ? 279+38 : 117;
  if (activeFiltersCount) {
    filterHeight += 38;
  }

  const setStateKey = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <Pane component={Paper}>
      <Box sx={classes.header} height={filterHeight}>
        <Box sx={classes.title} p={2} pb={1}>
          <Typography component="h1" variant="h4">
            <FormattedMessage
              id="verification.list.title"
              defaultMessage={"Verification List"}
            />
          </Typography>
        </Box>
        <WorkListFilters
          show={state.showFilters}
          onOpen={() => setStateKey("showFilters", !state.showFilters)}
          lockedValues={props.lockedValues}
          extraFilters={
            <>
              <Tabs value={searchParams.verificationMethod} onChange={(e, v) => onSetFilter("verificationMethod", v)} sx={{width: 400, display: "inline-block", verticalAlign: "top"}}>
                <Tab label="Automatic Work" value={"AUTOMATIC"} />
                <Tab label="Manual Work" value={"MANUAL"} />
                <Tab label="All Work" value={""} />
              </Tabs>
              <Box sx={classes.search}>
                <Box sx={classes.searchIcon}>
                  <SearchIcon />
                </Box>
                <InputBase
                  placeholder="Registry Generated Id"
                  sx={classes.inputRoot}
                  inputProps={{
                    sx: classes.inputInput,
                  }}
                  value={state.registryGeneratedId}
                  onChange={(e) => {
                    setStateKey("registryGeneratedId", e.target.value);
                    handleDebounceChange("registryGeneratedId", e.target.value);
                  }}
                />
              </Box>
            </>
          }
        />
      </Box>
      <Box
        sx={classes.content}
        height={"calc(100% - " + filterHeight + "px)"}
      >
        <WorkListTable />
      </Box>
    </Pane>
  );
};

const WorkList = (props) => {
  return (
    <WorkFiltersProvider defaultValues={{orderBy: "completedAt"}} lockedValues={{status: "COMPLETE"}}>
      <WorkListPaneView {...props} />
    </WorkFiltersProvider>
  );
};

export default WorkList;
