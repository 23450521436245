import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import OrganizationListTable from "./OrganizationListTable";
import { FormattedMessage, Pane, Text, makeSxStyles, Button } from "@armus/armus-dashboard";
import { useAdminOrganization } from "../../../../providers/AdminOrganizationProvider";
import useSearchState from "../../../components/useSearchState";
import OrganizationAddIcon from "@mui/icons-material/Business";
import history from "../../../../lib/history";
import routeKeys, { buildUrl } from "../../../../../routes";
import { useParams } from "react-router-dom";

const useStyles = makeSxStyles((theme) => ({
  root: {},
  header: {
    transition: "0.5s",
    overflow: "hidden",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.mode === "dark" ? "#515151" : "#e0e0e0",
  },
  content: {
    transition: "0.5s",
    height: "calc(100% - 74px)"
  },
}));

const OrganizationList = (props) => {
  const { orgKey, implKey } = useParams();
  const classes = useStyles();
  const {organizationQuery} = useAdminOrganization();
    const data = organizationQuery.data || [];
    const [queryState, setQueryState] = useSearchState({
        search: "",
        by: "shortname",
        order: "asc",
        page: 0,
        rowsPerPage: 25
      });

    const onAddOrganization = () => {
      history.push(
        buildUrl(routeKeys.ADMIN_ORGANIZATION_DETAILS, { orgKey, implKey, organizationId: "new" })
      );
    };

    // do search
    let rows = data.filter(row =>
        (row.shortname || "").indexOf(queryState.search) >= 0);

    // do sort
    if(!!queryState.by && ["shortname"].includes(queryState.by) && ["asc","desc"].includes(queryState.order)) {
        rows.sort((a, b) => {
            const dir = queryState.order === "asc" ? 1 : -1;
            a = a[queryState.by];
            b = b[queryState.by];
            return a > b ? 1 * dir : a < b ? -1 * dir : 0;
        });
    }

    // do paging
    rows = rows.slice(queryState.page * queryState.rowsPerPage, (queryState.page+1) * queryState.rowsPerPage);

  return (
    <Pane 
      component={Paper} 
      className="OrganizationList"
      minWidth={"60%"}
    >
      <Box sx={classes.header}>
        <Box sx={classes.title} p={2} pb={1}>
          <Grid container>
            <Grid item xs={6}>
                <Typography component="h1" variant="h4">
                  <FormattedMessage
                    id="Organization.list.title"
                    defaultMessage={"Organization List"}
                  />
                </Typography>
            </Grid>
            <Grid item xs={3}>
              <Text 
                descriptor={{
                  label: "Search",
                  config: {dense: true}}
                }
                value={queryState.search}
                onChange={(e,v,d) => setQueryState({...queryState, search: v})}
              />
            </Grid>
            <Grid item xs={3}>
              <Box textAlign={"right"}>
                <Button color="info" variant="contained" disableElevation onClick={() => onAddOrganization()} isResolver={true}>
                  <OrganizationAddIcon />&nbsp;Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={classes.content}
      >
        <OrganizationListTable
          rows={rows}
          isLoading={organizationQuery.isLoading || organizationQuery.isRefetching}
          queryState={queryState}
          setQueryState={setQueryState}
        />
      </Box>
    </Pane>
  );
};

export default OrganizationList;
