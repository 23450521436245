/* step shape
  {
    target: "", // * required
    content: "", // * required
    disableBeacon: false,
    event: "click", // "click or hover"
    isFixed: false,
    offset: 16,
    placement: "bottom", // top, top-start, top-end, bottom, bottom-start, bottom-end, left, left-start, left-end, right, right-start, right-end, auto (it will choose the best position), center (set the target to body)
    placementBeacon: "bottom",
    styles: {}, 
    title: ""
  }
*/

const onboardingMessages = {
  "summaryViewSteps": [
      {
          "title": "Summary Tour",
          "target": "body",
          "content": "Welcome to the Abstractor Work Summary. Let's show you around.",
          "placement": "center"
      },
      {
          "target": ".workActivity",
          "content": "On the left, you will see a summary of your active work items.",
          "placement": "right"
      },
      {
          "target": ".workListQuickLinks",
          "content": "Use these quick links to browse for My Work, All Unassigned Work, and All Work.",
          "placement": "left"
      },
      {
          "target": ".workProgressAtAGlance",
          "content": "These gauges indicate your progress across all your work items and their various statuses. Click a gauge to view the specific work items.",
          "placement": "left"
      }
  ],
  "workListSteps": [
      {
          "title": "Work List Tour",
          "target": ".workList",
          "content": "Welcome to the Case List. Let's show you around.",
          "placement": "bottom"
      },
      {
          "target": ".workListExtraFilters",
          "content": "Use these filters to quickly find work items.",
          "placement": "bottom"
      },
      {
          "target": ".workListMoreFilters",
          "content": "Click More Filters to view advanced filtering options. This includes options for cases without work items.",
          "placement": "bottom"
      },
      {
          "target": ".workList .MuiTableHead-root",
          "content": "Click a column header to change the sorting order.",
          "placement": "top"
      },
      {
          "target": ".workList .MuiTablePagination-root",
          "content": "If you have a lot of work items, you can change the number of rows per page or click through the pages.",
          "placement": "top"
      }
  ],
  "workDetailsSteps": [
      {
          "title": "Work Details Tour",
          "target": ".workDetails",
          "content": "Welcome to the Work Details. Let's show you around.",
          "placement": "left"
      },
      {
          "target": ".workDetailsActions",
          "content": "Here you will find actions, including starting progress or completing a work item.",
          "placement": "left"
      },
      {
          "target": ".workDetailsWorkInfo",
          "content": "Review Work Details about a specific work item in this section.",
          "placement": "left"
      },
      {
          "target": ".workDetailsCaseInfo",
          "content": "Review the overall Case Summary for the patient in this section.",
          "placement": "left"
      },
      {
          "target": ".workDetailsSiblings",
          "content": "Review other work items assigned to this case here.",
          "placement": "left"
      },
      {
          "target": ".workDetailsNotes",
          "content": "Add and review work notes here. Work notes display in a timeline.",
          "placement": "left"
      }
  ]
};


export const summaryViewSteps = onboardingMessages.summaryViewSteps;
export const workListSteps = onboardingMessages.workListSteps;
export const workDetailsSteps = onboardingMessages.workDetailsSteps;