import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SmartLink from "./SmartLink";
import {
  IconButton,
  Icon,
  Snackbar,
  SnackbarContent,
  Button,
  Box,
} from "@mui/material";

const variantIcon = {
  success: "check_circle_icon",
  warning: "warning_icon",
  error: "error_icon",
  info: "info_icon",
};

const classes = {
  snackbarContent: {
    p: 1, pl: 2, pr: 2,
    "& .MuiSnackbarContent-message": {
      padding: 0
    }
  },
  success: {
    backgroundColor: "success.main",
    color: "white",
  },
  error: {
    backgroundColor: "error.main",
    color: "white",
  },
  info: {
    backgroundColor: "info.main",
    color: "white",
  },
  warning: {
    backgroundColor: "warning.main",
    color: "black",
  },
  icon: {
    lineHeight: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 1,
  },
  message: {
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
  small: {p: .5, pl: 1, pr: 1},
  medium: {p: 1, pl: 2, pr: 2},
  close: {
    padding: 0
  }
};

const NotificationItem = React.forwardRef((props, ref) => {
  const { message, actions, onClose, variant, size = "medium" } = props;
  const iconNanme = variantIcon[variant];
  const allActions = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      size="small"
      sx={classes.close}
      onClick={onClose}
    >
      <Icon sx={classes.icon}>close_icon</Icon>
    </IconButton>,
  ];
  if (actions !== undefined) {
    // extra actions
    actions.forEach((action) => {
      allActions.unshift(
        <Button
          color="inherit"
          key="other-action"
          size="small"
          component={SmartLink}
          {...action}
        >
          {action.label}
        </Button>
      );
    });
  }
  return (
    <SnackbarContent
      ref={ref}
      sx={{...classes.snackbarContent, ...classes[variant], ...(classes[size] || {})}}
      aria-describedby="client-snackbar"
      message={
        <Box id="client-snackbar" sx={classes.message}>
          {!!iconNanme && <Icon sx={{ ...classes.icon, ...classes.iconVariant }}>
            {iconNanme}
          </Icon>}
          {message}
        </Box>
      }
      action={allActions}
    />
  );
});

NotificationItem.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  message: PropTypes.node,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["success", "warning", "error", "info", "default"]).isRequired,
};

const Notifications = (props) => {
  const { queue, onHideNotification, onRemoveNotification } = props;
  let hideTimeoutRef = useRef();
  const handleClearTimeout = () => {
    clearTimeout(hideTimeoutRef.current);
  };

  const handleSetTimeout = () => {
    // hide one notification every 5 seconds
    clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = setTimeout(() => {
      onHideNotification();
    }, 5000);
  };
  useEffect(() => {
    if (queue.length) {
      handleSetTimeout();
    }
  });
  
  return (
    <>
      {queue.map((notification, index) => (
        <Snackbar
          key={notification.message + index}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={notification.open}
          autoHideDuration={null}
          style={{ marginTop: (notification.stacked ? 48 * index : 0 ) + 55 }}
          onMouseEnter={handleClearTimeout}
          onMouseLeave={handleSetTimeout}
          TransitionProps={{ onExited: () => onRemoveNotification() }}
        >
          <NotificationItem
            onClose={() => onHideNotification(index)}
            variant={notification.type}
            message={notification.message}
            actions={notification.actions}
            size={notification.size}
            index={index}
          />
        </Snackbar>
      ))}
    </>
  );
};

Notifications.propTypes = {
  queue: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          onClick: PropTypes.func,
          to: PropTypes.string,
          href: PropTypes.string,
        })
      ),
      stacked: PropTypes.bool,
      size: PropTypes.oneOf(["small", "medium"]),
      type: PropTypes.oneOf(["success", "warning", "error", "info", "default"]).isRequired,
      message: PropTypes.node.isRequired,
      open: PropTypes.bool,
    })
  ),
  onHideNotification: PropTypes.func.isRequired, // (index) => set open to false
  onRemoveNotification: PropTypes.func.isRequired, // (index) => set open to false
};

export default Notifications;
