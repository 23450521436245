import React from "react";
import { Route } from "react-router-dom";
import { useSecuredIsAccessible } from "../../providers/UserProvider";
import ErrorPage from "../ErrorPage";
import PropTypes from "prop-types";

function ComponentWithCheck({
                                children,
                                component: Component,
                                secured = Component?.secured,
                                ...passthrough
                            }) {
    if (useSecuredIsAccessible()(secured)) {
        return Component
            ? <Component {...passthrough} />
            : children;
    } else {
        return <ErrorPage statusCode={403} />;
    }
}

export default function SecuredRoute(props) {
    const {
        children,
        component: Component,
        secured = Component?.secured,
        ...passthrough
    } = props;
    return <Route {...passthrough}>
        {secured
            ? <ComponentWithCheck {...props} />
            : Component
                ? <Component {...passthrough} />
                : children}
    </Route>
}

SecuredRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.func,
    secured: PropTypes.shape({
        implKey: PropTypes.string.isRequired,
    }),
    children: PropTypes.node,
};