import React from "react";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import PassedIcon from "@mui/icons-material/ThumbUp";
import { Button, makeSxStyles, Spinner } from "@armus/armus-dashboard";
import { useWorkDetailsVerification } from "../../../../providers/WorkDetailsVerificationProvider";

const useStyles = makeSxStyles((theme) => ({
  root: {
    position: "relative",
    borderBottom: "1px solid",
    borderLeft: "4px solid #88888888",
    backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#f9f9f9",
    borderBottomColor: theme.palette.mode === "dark" ? "#212121" : "#88888844",
    width: "100%",
    overflow: "auto",
    height: 0,
    "& ol, & ul": {
      marginLeft: 1,
      paddingLeft: 1,
    },
    "&.hasIssues": {
      borderLeft: "4px solid #bb0000",
      backgroundColor: "#bb000011",
    },
    "&.noIssues": {
      borderLeft: "4px solid " + theme.palette.success.main,
      backgroundColor: theme.palette.success.main + "11",
      minHeight: 100
    },
  },
  retryButton: {
    float: "right",
  },
}));

const WorkDetailsVerificationIssues = ({ workDetails, readOnly, variant, isTransitionOpen }) => {
  const classes = useStyles();
  const {
    isLoading,
    canAutoVerify,
    issues = [],
    verificationStatus,
    verificationMethod,
    onAutoVerificationCheck,
    onManualRequestReverification,
    onAutoVerificationSetPending,
  } = useWorkDetailsVerification(workDetails);
  let content = null;
  let flexHeight = ".08";
  let className = "";
  if (verificationMethod === "MANUAL") {
    if (verificationStatus === "FAILED" && variant !== "verification") {
      className = "hasIssues";
      flexHeight = .11;
      content = (
        <>
          {!readOnly && (
            <Button
              sx={classes.retryButton}
              variant={"outlined"}
              color="default"
              dense={true}
              onClick={() => onManualRequestReverification()}
            >
              <RefreshIcon /> Request Reverification
            </Button>
          )}
          <strong>Verification Issues</strong>
          <ul>
            <li>Check the work notes below for verification issues.</li>
          </ul>
        </>
      );
    }
  } else if (verificationMethod === "AUTOMATIC") {
    if (!canAutoVerify) {
      return null;
    }
    content = null;
    if (!isLoading) {
      if (issues.length) {
        flexHeight = .08 + (issues.length * .04);
        className = "hasIssues";
        content = (
          <>
            {!readOnly && (
              <Button
                sx={classes.retryButton}
                variant={"outlined"}
                color="default"
                dense={true}
                onClick={() => onAutoVerificationCheck()}
              >
                <RefreshIcon />&nbsp;Refresh
              </Button>
            )}
            <strong>Verification Issues ({issues.length})</strong>
            <ol>
              {issues.map((it, index) => {
                return <li key={index}>{it.description}</li>;
              })}
            </ol>
          </>
        );
      }
      else if(workDetails.verificationStatus === 'FAILED')  {
        className = "noIssues";
        content = (
          <>
            {!readOnly && !isTransitionOpen && (
              <Button
                sx={classes.retryButton}
                variant={"outlined"}
                color="default"
                dense={true}
                onClick={() => onAutoVerificationSetPending()}
              >
                <PassedIcon />&nbsp;Mark as Verified
              </Button>
            )}
            <strong>No Verification Issues Detected</strong>
            <ol>
                <li>There are no issues with this work item. To update this items verification status please click "mark as verified"</li>
            </ol>
          </>
        );
      }
    } else {
      content = (
        <>
          <Spinner color="secondary" size={20} />
          Checking Verification...
        </>
      );
    }
  }

  if (content === null) {
    return null;
  }

  return (
    <Box
      className={className}
      sx={classes.root}
      minHeight={35 * issues.length + 50}
      style={{flex: flexHeight}}
    >
      <Box p={2} pl={1}>
        {content}
      </Box>
    </Box>
  );
};

export default WorkDetailsVerificationIssues;
