import config from "../../config";
import axios from "axios";
import qs from "query-string";
import {formatMessage, cookie} from "@armus/armus-dashboard";
import moment from "moment-timezone";

// Create an axios instance for api requests
const api = axios.create({
    baseURL: config.endpoints.apiRoot,
    timeout: 60000,
    headers: { "X-Custom-Header": "armus" }
});

// Set up Axios Interceptors.
// These functions will get called before every request/response.
if(process.env.NODE_ENV === "development") {
    if (config.mocks.useMocks) {
        const mock = require("./mocks").default;
        mock.mockInterceptor(api); // adds the mock interceptor to the axios instance
    }
}

api.interceptors.request.use(function (request) {
    switch(request.method) {
        case "put":
        case "post":
            request.headers = {
                ...(request.headers || {}),
                "Content-Type": "application/json",
                [config.session.CSRF_POST_KEY]: cookie.get(config.session.CSRF_COOKIE_KEY) // add CSRF token to header.
            };
            break;
        default:
    }
    return request;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

const defaultErrorMessage = formatMessage({id: "apiErrorMessage", defaultMessage: "Something unexpected happened with the request. Please try again in a few moments."});

api.interceptors.response.use(function (response) {
    // Do something with Status Codes in the 200's range
    switch(response.status) {
        case 200:
            break;
        default:
            const error = {
                response,
                message: defaultErrorMessage
            };
            return Promise.reject(error);
    }
    return response;
}, function (error) {
    const response = error.response || {};
    let message = false;
    let link = undefined;
    if (response instanceof axios.Cancel) {
        return Promise.reject(error); // request was canceled.
    }
    // Do something with all other Status Codes.
    if([undefined, 401, 403].includes(response.status)) {
        // status code 4xx or (canceled/aborted undefined)
        message = "You are not authorized to make that request.";
        link = { label: "Sign in", href: config.endpoints.login};
        if(response.status === undefined) {
            // set the status code to 401
            response.status = 401;
        }
    }
    else if(response.status >= 500) {
        message = defaultErrorMessage;
    }
    if(message) {
        console.error(`API Error: Status Code "${response.status}"`, error.message, response.config);
    }
    return Promise.reject({
        notifyError: !!message,
        message: message,
        link: link,
        error
    });
});

// now for the actual API helper functions.
const getUserData = () => {
    // request the user data
    return api.get("/user");
};

const logoutUser = () => {
    // create form and submit post to logout
    var form = document.createElement("form");
    form.method = "POST";
    form.action = config.endpoints.logout;

    // Add the CSRF token
    var _csrf = document.createElement("input");
    _csrf.setAttribute("type", "hidden");
    _csrf.setAttribute("name", "_csrf");
    _csrf.setAttribute("value", cookie.get(config.session.CSRF_COOKIE_KEY));
    form.appendChild(_csrf);

    document.body.appendChild(form);
    form.submit();
    // cleanup DOM
    form.parentNode.removeChild(form);
    return Promise.resolve(true);
};

let workListCancel = null;
const getListData = (type = "work", org, impl, query, cancelable = true) => {
    let url = `/${impl}/${org}/${type}`;
    // add querystring params to URL
    if(query) {
        query = {...query};
        query.sort = query.orderBy + "," + query.order.toUpperCase();
        delete query.orderBy;
        delete query.order;
        // createdAtAfter, createdAtBefore are ints on the client but the server
        // expects exact date.
        if(query.createdAtBefore !== "") {
            query.createdAtBefore = moment().add(query.createdAtBefore, 'days').utc().endOf('day').toISOString(false);
        }
        if(query.createdAtAfter !== "") {
            query.createdAtAfter = moment().add(query.createdAtAfter, 'days').utc().startOf('day').toISOString(false);
        }

        if(query.admitBefore !== "") {
            query.admitBefore = moment(query.admitBefore).utc().endOf('day').toISOString(false);
        }

        if(query.admitAfter !== "") {
            query.admitAfter = moment(query.admitAfter).utc().startOf('day').toISOString(false);
        }

        Object.keys(query).forEach(key => {
            if(query[key] === "") {
                // remove the empty value filters to keep the url clean.
                delete query[key];
            }
        });
        url += "?" + qs.stringify(query);
    }
    if(workListCancel !== null && cancelable) {
        workListCancel('canceled');
        workListCancel = null;
    }
    return api.get(
        url,
        {cancelToken: new axios.CancelToken(function executor(c) {
                workListCancel = c;
        })}

    );
};

const getWorkListData = (org, impl, query, cancelable = true) => {
    return getListData("work", org, impl, query, cancelable);
};

const getCaseListData = (org, impl, query, cancelable = true) => {
    return getListData("case", org, impl, query, cancelable);
};

let workDetailsCancel = null;
const getWorkDetailsData = (org, impl, id) => {
    if(workDetailsCancel !== null) {
        workDetailsCancel('canceled');
        workDetailsCancel = null;
    }
    let url = `/${impl}/${org}/work/${id}`;
    return api.get(
        url,
        {cancelToken: new axios.CancelToken(function executor(c) {
            workDetailsCancel = c;
        })}
    );
};

let getWorkDetailsVerificationCancel = null;
const getWorkDetailVerificationData = (org, impl, id, registryGeneratedId = false) => {
    if(getWorkDetailsVerificationCancel !== null) {
        getWorkDetailsVerificationCancel('canceled');
        getWorkDetailsVerificationCancel = null;
    }
    let url = `/${impl}/${org}/work/${id}/verification`;
    if(registryGeneratedId) {
        url += `?registryGeneratedId=${registryGeneratedId}`;
    }
    return api.get(
        url, 
        {cancelToken: new axios.CancelToken(function executor(c) {
            getWorkDetailsVerificationCancel = c;
        })}
    );
}

const getAdminOrginizationData = (org, impl) => {
    let url = `/${impl}/${org}/organization`;
    return api.get(url);
};

const putAdminOrginizationData = (org, impl, orgId, payload) => {
    let url = `/${impl}/${org}/organization/${orgId}`;
    return api.put(url, payload);
};

const postAdminOrginizationData = (org, impl, payload) => {
    let url = `/${impl}/${org}/organization`;
    return api.post(url, payload);
};

const getAdminRegistriesData = (org, impl) => {
    let url = `/${impl}/${org}/registry`;
    return api.get(url);
};

const getAdminAbstractorData = (org, impl) => {
    let url = `/${impl}/${org}/abstractor`;
    return api.get(url);
};

const putAdminAbstractorData = (org, impl, orgId, payload) => {
    let url = `/${impl}/${org}/abstractor/${orgId}`;
    return api.put(url, payload);
};

const postAdminAbstractorData = (org, impl, payload) => {
    let url = `/${impl}/${org}/abstractor`;
    return api.post(url, payload);
};

const getAdminUserTypesData = (org, impl) => {
    let url = `/${impl}/${org}/model/user`;
    return api.get(url);
};

const getLastModifiedDetailsData = (org, impl) => {
    let url = `/${impl}/${org}/work/lastModified`;
    return api.get(url);
};

const getFiltersData = (org, impl) => {
    let url = `/${impl}/${org}/filters`;
    return api.get(url);
};

const postWorkDetailChange = (org, impl, workId, payload) => {
    let url = `/${impl}/${org}/work/${workId}/change`;
    return api.post(url, payload);
};

const postAddWorkData = (org, impl, payload) => {
    let url = `/${impl}/${org}/work`;
    if(payload.visibleDate !== "") {
        payload.visibleAfter = moment(payload.visibleAfter).utc().startOf('day').toISOString(false);
    }
    return api.post(url, payload);
};


const getInvoicingData = (org, impl, section, organization, abstractor, completedYear, completedMonth) => {
    let url = `/${impl}/${org}/items`;
    const query = {
        completedYear,
        completedMonth,
    };
    if(section === "abstractor") {
        url = `/${impl}/${org}/abstractor-items`;
        query.abstractor = abstractor;
    }
    else {
        query.organization = organization;
    }
    url += "?" + qs.stringify(query);
    return api.get(url);
};
const getInvoicingAbstractorsData = (org, impl, payload) => {
    let url = `/${impl}/${org}/abstractor`;
    return api.get(url, payload);
};

let reportingCancel = null;
const getReportingData = (org, impl, query, cancelable = true) => {
    let url = `/${impl}/${org}/work`;
    // add querystring params to URL
    if(query) {
        if(query.beforeDate !== "") {
            query.beforeDate = moment(query.beforeDate).endOf('day').toISOString(false);
        }
        if(query.afterDate !== "") {
            query.afterDate = moment(query.afterDate).startOf('day').toISOString(false);
        }

        Object.keys(query).forEach(key => {
            if(query[key] === "") {
                // remove the empty value filters to keep the url clean.
                delete query[key];
            }
        });
        url += "?" + qs.stringify(query);
    }
    if(reportingCancel !== null && cancelable) {
        reportingCancel('canceled');
        reportingCancel = null;
    }
    return api.get(
        url,
        {cancelToken: new axios.CancelToken(function executor(c) {
                reportingCancel = c;
            })}

    );
};



const servicesAPI = {
    api,
    getUserData,
    logoutUser,
    getCaseListData,
    getWorkListData,
    getWorkDetailsData,
    getWorkDetailVerificationData,
    getLastModifiedDetailsData,
    getFiltersData,
    postAddWorkData,
    getInvoicingData,
    getInvoicingAbstractorsData,
    getReportingData,
    postWorkDetailChange,
    getAdminOrginizationData,
    postAdminOrginizationData,
    putAdminOrginizationData,
    getAdminRegistriesData,
    getAdminAbstractorData,
    putAdminAbstractorData,
    postAdminAbstractorData,
    getAdminUserTypesData,
};

export default servicesAPI;