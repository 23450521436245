import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "@armus/armus-dashboard";
import Grid from "@mui/material/Grid/Grid";

const WorkDetailsNotFound = ({classes, workId, onClose}) => {
    return (
        <React.Fragment>
            <header>
                <Box p={2} pl={3}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography component="h1" variant="h4">
                                <FormattedMessage
                                    id="work.details.notFound.title"
                                    defaultMessage={"Work Item Not Found"}
                                    values={{workId}}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={classes.closeAction}>
                            <IconButton size="small" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </header>
            <Box sx={classes.content}>
                <Box p={2} pl={3}>
                    <Typography>
                        <FormattedMessage
                            id="work.details.notFound.subTitle"
                            defaultMessage={"Please verify you have the correct licensing to view the requested work item.\n <br/>\n Also verify you have have provided the correct work item number \"{workId}\".\n <br/>\n If you continue to get this message please contact support."}
                            values={{workId}}
                        />
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default WorkDetailsNotFound;