import React from "react";
import { Box } from "@mui/material";
import { Text, Typeahead } from "@armus/armus-dashboard";
import history from "../../../../lib/history";
import routeKeys, { buildUrl } from "../../../../../routes";
import { useParams } from "react-router-dom";
import { useAdminOrganization } from "../../../../providers/AdminOrganizationProvider";
import OrginizationIcon from "@mui/icons-material/Business";
import DetailsPane from "../../components/DetailsPane";

const OrganizationDetails = (props) => {
  const { orgKey, implKey } = useParams();
  const { organization, organizationId, organizationMutation, registryQuery, setOrganization, onSaveOrganization } =
    useAdminOrganization();

  const onClose = () => {
    history.push(
      buildUrl(routeKeys.ADMIN_ORGANIZATION_LIST, { orgKey, implKey })
    );
  };

  const onChange = (key, value) => {
    setOrganization({
      ...organization,
      [key]: value
    });
  };

  const onSubmit = () => {
    onSaveOrganization();
  };

  const isValid = !!organization?.shortname;

  const registryOptions = (registryQuery.data || []).map((it) => ({
    label: it.shortname,
    value: it.id,
  }));
  const registryValue =
    organization?.registryParticipation.map((it) => it.registry.id) || [];
  return (
    <DetailsPane
      id={organizationId}
      fakeLoading={true}
      show={!!organizationId}
      minWidth={300}
      minHeight={450}
      isSuccess={!!organization}
      isError={!organization && organizationId}
      isUpdating={organizationMutation.isLoading}
      onClose={onClose}
      disabled={!isValid}
      onSubmit={onSubmit}
      submitText="Save"
      title={
        <>
          <Box style={{ verticalAlign: "sub", display: "inline-block" }}>
            <OrginizationIcon fontSize={"large"} />
          </Box>
          &nbsp;
          {organizationId === "new" ? "Add" : organization?.shortname}
        </>
      }
    >
      {organizationId === "new" && (
        <Text
          descriptor={{
            label: "Short Name",
            config: { dense: true },
          }}
          value={organization?.shortname}
          required
          error={!organization?.shortname}
          onChange={(e,v) => {
            onChange("shortname", v);
          }}
        />
      )}
      <Text
        descriptor={{
          label: "Source Group ID Label",
          config: { dense: true },
        }}
        onChange={(e,v) => {
          onChange("sourceGroupIDLabel", v);
        }}
        helperText={"Defaults to \"Registry Generated ID\" if left blank."}
        value={organization?.sourceGroupIDLabel}
      />
      <Typeahead
        isLoading={registryQuery.isLoading}
        descriptor={{
          label: "Registry Participation",
          config: {
            groupBy: false,
            dense: true,
            multivalued: true,
          },
        }}
        options={registryOptions}
        value={registryValue}
        onChange={(e, v) => {
          const value =
            v?.map((it) => ({
              id: null,
              registry: registryQuery.data.find((a) => a.id === it),
            })) || [];
          onChange("registryParticipation", value);
        }}
      />
    </DetailsPane>
  );
};

export default OrganizationDetails;
