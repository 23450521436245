import React, {
    createContext,
    useContext,
    useState,
    useRef,
} from "react";
import Notifications from "../common/Notifications";

const getInitialState = () => {
    return {
        queue: [],
    };
};

const NotificationsContext = createContext(getInitialState());

const NotificationsProvider = ({children}) => {
    const [state, setState] = useState(getInitialState());
    const stateRef = useRef();
    const setStateRef = useRef();
    stateRef.current = state;
    setStateRef.current = setState;

    const getQueue = () => [...stateRef.current.queue];
    const setQueue = (queue) => setStateRef.current({...stateRef.current, queue});
    const onPush = (item) => {
        const queue = getQueue();
        const newItem = {
            key: null,
            type: "default",
            message: "",
            size: "medium",
            stacked: true,
            ...item,
            open: true
        };
        const index = queue.findIndex(it => item.key === it.key);
        if(index !== -1) {
            // replace
            queue[index] = newItem;
        }
        else {
            // insert
            queue.push(newItem);
        }
        setQueue(queue);
    };
    const onPop = () => {
        const queue = getQueue();
        if(queue.length) {
            queue.pop();
        }
        setQueue(queue);
    };
    const onHide = (index) => {
        // remove item from queue
        const queue = getQueue();
        if(index === undefined) {
            index = queue.findLastIndex(it => it.open);
        }
        if(index < queue.length && index >= 0) {
            queue[index] = {...queue[index], open: false}; // for hide animation
            setQueue(queue);
        }
    };
    const onRemove = () => {
        // remove item from queue
        const queue = getQueue();
        const newQueue = queue.filter(it => it.open);
        setQueue(newQueue);
    };
    const onCleanup = () => {
        // remove all open=false from queue
        const queue = getQueue();
        setQueue(
            queue.filter((n) => (n.open === true))
        );
    };
    const onReset = () => {
        setQueue(getInitialState().queue);
    };
    const value = {
        getQueue,
        setQueue,
        onPush,
        onPop,
        onHide,
        onRemove,
        onCleanup,
        onReset,
    };

    return (
        <NotificationsContext.Provider value={value}>
            <Notifications
                queue={getQueue()}
                onHideNotification={onHide}
                onRemoveNotification={onRemove}
            />
            {children}
        </NotificationsContext.Provider>
    );
};


export const useNotifications = () => {
    const notificationsState = useContext(NotificationsContext);
    return {
        queue: notificationsState.getQueue(),
        ...notificationsState
    };
};

export default NotificationsProvider;