import React, { createContext, useContext } from "react";
import { useParams } from "react-router";
import { useAdminAbstractorMutation } from "../lib/mutations";
import { useAdminAbstractorQuery, useAdminUserTypesQuery } from "../lib/queries";

export const AdminAbstractorContext = createContext({
  __query: {data: [], isSuccess: false},
  __mutation: {},
});



function AdminAbstractorProvider({ children }) {
  const __abstractorQuery = useAdminAbstractorQuery();
  const __userTypes = useAdminUserTypesQuery();
  const __abstractorMutation = useAdminAbstractorMutation();
  const ctx = {
    __abstractorQuery,
    __abstractorMutation,
    __userTypes,
  };
  return (
    <AdminAbstractorContext.Provider value={ctx}>
      {children}
    </AdminAbstractorContext.Provider>
  );
}

export default AdminAbstractorProvider;

const getAbstractor = ({data}, id) => {
  if(id === "new") {
    return {
      id: "new",
      username: "",
      firstName: "",
      lastName: "",
      abstractorType: "UNKNOWN",
    };
  }
  if(Array.isArray(data)) {
    return data.find((it) => it.id === id);
  }
  return null;
};

export const useAdminAbstractor = () => {
  const { abstractorId } = useParams();
  const ctx = useContext(AdminAbstractorContext);
  const id = abstractorId !== "new" ? parseInt(abstractorId, 10) : abstractorId;
  const [abstractor, setAbstractor] = React.useState(getAbstractor(ctx.__abstractorQuery, id));
  const isSuccess = !ctx.__abstractorQuery.isLoading && ctx.__abstractorQuery.isSuccess;
  React.useEffect(() => {
    if(isSuccess && id !== undefined) {
      if(abstractor === null || abstractor?.id !== id) {
        setAbstractor(getAbstractor(ctx.__abstractorQuery, id));
      }
    }
    else {
      setAbstractor(null);
    }
  }, [
    abstractor,
    ctx.__abstractorQuery,
    id,
    isSuccess
  ]);
  return {
    abstractorId,
    abstractor: abstractor,
    setAbstractor: setAbstractor,
    onSaveAbstractor: () => ctx.__abstractorMutation.mutate(abstractor),
    userTypesQuery: ctx.__userTypes,
    abstractorQuery: ctx.__abstractorQuery,
    abstractorMutation: ctx.__abstractorMutation,
  }
};
