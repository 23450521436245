import React from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import { FormattedMessage } from "@armus/armus-dashboard";
import WorkDetailsTransitionResolver from "./Abstractor/WorkDetailsTransitionResolver";
import WorkDetailsVerificationResolver from "./Verification/WorkDetailsVerificationResolver";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const WorkDetailsHeader = (props) => {
  const {
    classes,
    workDetails,
    isMyWork,
    isUnassigned,
    isTransitionOpen,
    onClose,
    readOnly,
    variant,
  } = props;
  const status = workDetails.status;
  let content = null;
  switch (variant) {
    case "verification":
      content = (
        <WorkDetailsVerificationResolver
          isMyWork={isMyWork}
          isUnassigned={isUnassigned}
          workDetails={workDetails}
        />
      );
      break;
    case "abstractor":
    default:
      content = (
        <WorkDetailsTransitionResolver
          isMyWork={isMyWork}
          isUnassigned={isUnassigned}
          workDetails={workDetails}
        />
      );
  }
  return (
    <header
      className={`${status || "INVALID"}`}
    >
      <Box p={2} pl={2}>
        <Grid container>
          <Grid item xs={11}>
            <Typography component="h1" variant="h4">
              <FormattedMessage
                id="work.details.title"
                defaultMessage={"{firstName} {lastName} - {type}"}
                values={{
                  lastName: workDetails.associatedCase.lastName || "&nbsp;",
                  firstName: workDetails.associatedCase.firstName || "&nbsp;",
                  type: workDetails.type,
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={1} sx={classes.closeAction}>
            {!isTransitionOpen && <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>}
          </Grid>
        </Grid>
        {!readOnly && <Box mt={0.5} className="workDetailsActions">{content}</Box>}
      </Box>
    </header>
  );
};

export default WorkDetailsHeader;
