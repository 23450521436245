import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { EnhancedTable, formatMessage, formatValue, makeSxStyles } from "@armus/armus-dashboard";
import {
    getRegistryInvoicingSummary
} from "../../components/useInvoicing";
import { useFindAbstractableLabel } from "../../components/workFilterHelpers";
import { getMonthNameFromInt } from "../../../lib/dateHelpers";
import writeXlsxFile from "write-excel-file";
import { Typography } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
    root: {
        "& .Mui-selected": {
            background:  theme.palette.info.main + "11",
            "& td:first-child": {
                borderLeft: "4px solid " + theme.palette.primary.main,
            }
        },
        "& table": {
            minHeight: 200,
            borderBottom: "1px solid rgba(127,127,127,.33)",
            "& th": {
                border: "none",
                borderRight: "1px solid rgba(127,127,127,.33)"
            },
            "& th:last-child": {
                border: "none",
            },
            "& td": {
                border: "none",
                borderRight: "1px solid rgba(127,127,127,.33)"
            },
            "& td:last-child": {
                border: "none",
            }
        }
    },

}));

const InvoiceTableView = (props) => {
    const {
        section,
        filters,
        result,
        isLoading,
    } = props;
    const handleDownloadExcel = useDownloadExcel();
    const classes = useStyles();
    let emptyMessage = null;
    if(result.length === 0) {
        emptyMessage = (
            <Box p={6} textAlign={"center"} fontSize={20}>
                There are currently no invoicing items available. <br /> Please check your filters.
            </Box>
        );
    }

    const monthYearLabel = `${getMonthNameFromInt(filters.completedMonth-1)} ${filters.completedYear}`;
    let total = 0;
    let totalQuantity = 0;
    result.forEach((row) => {
        total += (row.amount || {}).value || 0;
        totalQuantity += row.quantity !== undefined ? row.quantity.value : 0;
    });
    const columns = [
        {
            id: "description",
            props: { align: "left", width: "75%" },
            isSortable: false,
            label: formatMessage({id: "invoicing.table.headerLabel.description", defaultMessage: "Description"}),
            Content: ({row, cell, x, y}) => {
                return row.description !== undefined ?
                    (row.description.format.header === true ? <Typography component={"h3"} variant={"h3"}>{row.description.value}</Typography> : <>{row.description.value}</>)
                    : null;
            }
        },
        {
            id: "quantity",
            props: { align: "center" },
            label: formatMessage({id: "invoicing.table.headerLabel.quantity", defaultMessage: "Quantity"}),
            isSortable: false,
            Content: ({row}) => row.quantity !== undefined ? row.quantity.value : "",
        }
    ];
    if(section !== "abstractor") {
        columns.push(
            {
                id: "rate",
                props: { align: "right" },
                label: formatMessage({id: "invoicing.table.headerLabel.rate", defaultMessage: "Rate"}),
                isSortable: false,
                Content: ({row}) =>  row.rate !== undefined ? formatValue("currency", row.rate.value) : "",
            },
            {
                id: "amount",
                props: { align: "right" },
                label: formatMessage({id: "invoicing.table.headerLabel.amount", defaultMessage: "Amount"}),
                isSortable: false,
                Content: ({row}) => row.amount !== undefined ? formatValue("currency", row.amount.value) : ""
            });
    }
    return (
        <Box sx={classes.root}>
            <Box sx={classes.root} p={2} pt={1}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography component="h2" variant="h5" mt={1}>{monthYearLabel} Invoice</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box textAlign={"right"} >
                            {result.length > 0 && <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => handleDownloadExcel(
                                    section,
                                    result, 
                                    `${section === "abstractor" ? filters.abstractor : filters.org}-invoice-${filters.completedYear}-${filters.completedMonth}-1-${filters.org}.xlsx`
                                )}
                            >
                                Download Invoice
                            </Button>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <EnhancedTable
                sx={classes.table}
                rows={result}
                columns={columns}
                isLoading={isLoading}
                emptyMessage={emptyMessage}
                total={result.totalElements}
                showHeader={true}
                dense={true}
                selectedCell={{
                }}
                hover={false}
                pagination={false}
                fillEmptyRows={false}
                height={"auto"}
            />

            {section === "abstractor" ? (
                <Grid container>
                    <Grid item xs={5}>
                        <Box p={2}>
                            Thank you for your work.
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box p={2} textAlign={"center"}>
                            <strong>Total Absractions</strong>
                        </Box>
                    </Grid>

                    <Grid item xs={3}>
                        <Box p={2} textAlign={"center"}>
                            <strong>{totalQuantity}</strong>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid item xs={5}>
                        <Box p={2}>
                            Thank you for your business.
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <Box p={2} textAlign={"center"}>
                            <strong>Balance Due</strong>
                        </Box>
                    </Grid>

                    <Grid item xs={2}>
                        <Box p={2} textAlign={"right"}>
                            <strong>{formatValue("currency", total)}</strong>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export const useDownloadExcel = () => {
    return (section, rows, fileName = "invoice.xlsx") => {
        const header = // Row #1
        [
            // Column #1
            {
                value: 'DESCRIPTION',
                fontWeight: 'bold',
                alignVertical: "center",
                height: 20,
                color: "#2a651d",
                backgroundColor: "#d4e0d1",
                leftBorderStyle: "thin",
                topBorderStyle: "thin",
                bottomBorderStyle: "thick",
            },
            // Column #2
            {
                value: 'QTY',
                color: "#2a651d",
                align: "right",
                fontWeight: 'bold',
                alignVertical: "center",
                height: 20,
                backgroundColor: "#d4e0d1",
                topBorderStyle: "thin",
                leftBorderStyle: "thin",
                bottomBorderStyle: "thick",
            },
        ];
        if(section === "abstractor") {
            header[header.length-1].rightBorderStyle = "thin";
        }
        else {
            header.push(
                // Column #3
                {
                    value: 'RATE',
                    color: "#2a651d",
                    align: "right",
                    fontWeight: 'bold',
                    height: 20,
                    alignVertical: "center",
                    backgroundColor: "#d4e0d1",
                    topBorderStyle: "thin",
                    leftBorderStyle: "thin",
                    bottomBorderStyle: "thick",
                },
                // Column #4
                {
                    value: 'AMOUNT',
                    color: "#2a651d",
                    align: "right",
                    fontWeight: 'bold',
                    height: 20,
                    alignVertical: "center",
                    backgroundColor: "#d4e0d1",
                    topBorderStyle: "thin",
                    rightBorderStyle: "thin",
                    leftBorderStyle: "thin",
                    bottomBorderStyle: "thick",
                }
            );
        }

        const data = [
            header
        ];

        let total = 0;
        let totalQuantity = 0;
        rows.forEach((row, index) => {
            total += (row.amount || {}).value || 0;
            totalQuantity += (row.quantity || {}).value || 0;
            const columns = [
                {
                    value: row.description.value,
                    type: String,
                    fontWeight: row.description.format.header ? 'bold' : '',
                    height: row.description.format.header ? 20 : '',
                    alignVertical: row.description.format.header ?  "center" : '',
                    leftBorderStyle: "thin",
                },
                {
                    value: (row.quantity || {}).value || "",
                    type: Number,
                    leftBorderStyle: "thin",
                },
            ];
            if(section === "abstractor") {
                columns[columns.length-1].rightBorderStyle = "thin";
            }
            else {
                columns.push(
                    {
                        value: (row.rate || {}).value || "",
                        format: "$#.00",
                        type: Number,
                        leftBorderStyle: "thin",
                    },
                    {
                        value: (row.amount || {}).value || "",
                        format: "$#.00",
                        type: Number,
                        leftBorderStyle: "thin",
                        rightBorderStyle: "thin",
                    }
                );
            }
            data.push(columns);
        });

        if(section === "abstractor") {
            data.push([
                {
                    value: "Total Absractions",
                    type: String,
                    fontWeight: 'bold',
                    leftBorderStyle: "thin",
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    alignVertical: "center",
                },
                {
                    value: totalQuantity,
                    type: Number,
                    fontWeight: 'bold',
                    leftBorderStyle: "thin",
                    rightBorderStyle: "thin",
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    alignVertical: "center",
                    fontSize: 16
                }
            ]);
        }
        else {
            data.push([
                {
                    value: "Total",
                    type: String,
                    fontWeight: 'bold',
                    leftBorderStyle: "thin",
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    alignVertical: "center",
                },
                {
                    value: "Balance Due",
                    fontWeight: 'bold',
                    type: String,
                    rightBorderStyle: "thin",
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    span: 2,
                    alignVertical: "center",
                },
                {
                    value: "",
                    type: String,
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    alignVertical: "center",
                },
                {
                    value: total,
                    format: "$#.00",
                    type: Number,
                    fontWeight: 'bold',
                    leftBorderStyle: "thin",
                    rightBorderStyle: "thin",
                    bottomBorderStyle: "thin",
                    topBorderStyle: "thick",
                    height: 20,
                    alignVertical: "center",
                    fontSize: 16
                },
            ]);
        }

        if(section === "abstractor") {
        }
        else {

        }


        writeXlsxFile(data, {
            columns: [
                { width: 60 }, // in characters
                {},
                {},
                {}
            ],
            fileName: fileName
        });
    }
};

const InvoiceTable = (props) => {
    const {filters, invoicing} = props;
    const findAbstractableLabel = useFindAbstractableLabel();
    let result = [];
    if(invoicing.data.length > 0) {
        const monthYearLabel = `${getMonthNameFromInt(filters.completedMonth-1)} ${filters.completedYear}`;
        result.push({
            description: {
                value: `DATA ABSTRACTION SERVICES: Work completed in ${monthYearLabel}`,
                format: {header: true}
            }
        });
        invoicing.data.forEach((registry) => {
            const items = getRegistryInvoicingSummary(registry) || [];
            result.push({
                description: {value: registry.key, format: {header: true}},
            });
            result = result.concat(items.map(it => {
                let description = "";
                if (it.initial === "initial") {
                    const [year, month] = it.item.split("-");
                    description = `Initial - ${findAbstractableLabel(it.abstractableId) || "Missing Abstractable ID"}: ${getMonthNameFromInt(parseInt(month, 10) - 1)} ${year}`;
                }
                else { // followup
                    const [year, month] = it.item.split("-");
                    description = `Follow Up - ${findAbstractableLabel(it.abstractableId) || "Missing Abstractable ID"}: ${getMonthNameFromInt(parseInt(month, 10) - 1)} ${year}`;
                }
                return {
                    description: {value: description, format: {}},
                    quantity: {value: it.quantity, format: {}},
                    rate: {value: it.rate.billingRate, format: {}},
                    amount: {
                        value: it.quantity * it.rate.billingRate,
                        format: {}
                    },
                }
            }));
        });
    }

    const state = {
        result: result,
        isLoading: invoicing.isLoading
    };
    return (
        <InvoiceTableView
            {...props}
            {...state}
        />
    );
};

export default InvoiceTable;
