import { useRef } from "react";

const useChangeDebounce = (func, ms = 500) => {
    const cbRef = useRef(func);
    cbRef.current = func;
    const timeoutKeysRef = useRef(func);
    return (key, value) => {
        if(timeoutKeysRef.current[key]) {
            clearTimeout(timeoutKeysRef.current[key]);
        }
        timeoutKeysRef.current[key] = setTimeout(() => {
            cbRef.current(key, value);
        }, ms);
    }
}

export default useChangeDebounce;