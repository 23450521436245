import React from "react";
import {
  Box,
  Button,
  alpha,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Spinner, Pane, makeSxStyles } from "@armus/armus-dashboard";
import DetailsPaneNotFound from "./DetailsPaneNotFound";
import AbstractorDetailsSkeleton from "./DetailsPaneSkeleton";
import CloseIcon from "@mui/icons-material/Close";

const minHeaderHeight = 73.13;
const useStyles = makeSxStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    //overflow: "inherit!important",
    "& .DataItemTitle": {
      display: "inline-block",
      marginRight: theme.spacing(1),
      userSelect: "none",
    },
    "& .DataItemValue": {
      display: "inline-block",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      "& svg": {
        verticalAlign: "bottom",
      },
    },
    "& header": {
      overflow: "auto",
      height: minHeaderHeight,
      transition: ".5s",
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.mode === "dark" ? "#212121" : "#88888844",
      borderLeft: "4px solid #88888888",
      backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#f9f9f9",
      "&.DetailsLoaded": {
        borderLeft: "4px solid",
        borderLeftColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
      },
    },
    "& footer": {
      width: "100%",
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      overflow: "auto",
      height: 60,
      transition: ".5s",
      borderTop: "1px solid",
      borderLeft: "4px solid #88888844",
      borderTopColor: theme.palette.mode === "dark" ? "#212121" : "#88888844",
      backgroundColor: theme.palette.mode === "dark" ? "#2d2d2d" : "#f9f9f9",
    },
  },
  paper: {
    overflow: "auto",
    position: "relative",
  },
  closeAction: {
    textAlign: "right",
  },
  content: {
    borderLeft: "4px solid #88888844",
    width: "100%",
    height: "CALC(100% - 127px)",
    overflow: "auto",
    flex: 1,
    "& .MuiFormControl-root": {
      // marginTop: 1,
      // marginBottom: .5,
    }
  },
}));

const DetailsPaneView = ({
  id,
  title,
  isSuccess,
  isError,
  isUpdating,
  onClose,
  onSubmit,
  disabled = false,
  submitText = "Save",
  children,
  minHeight = 350,
  height = "50%",
  fakeLoading = true,
}) => {
  const [loading, setLoading] = React.useState(fakeLoading);
  const setLoadingRef = React.useRef(setLoading);
  setLoadingRef.current = setLoading;
  React.useEffect(() => {
    if(fakeLoading && id) {
      setLoadingRef.current(true);
      setTimeout(() => setLoadingRef.current(false), 250);
    }
  }, [fakeLoading, id]);
  const classes = useStyles();
  let body = null;
  if (isError) {
    // not found/no access.
    body = <DetailsPaneNotFound classes={classes} onClose={onClose} />;
  } else if ((isSuccess || isUpdating) && !loading) {
    body = (
      <>
        {isUpdating && <Spinner sx={classes.spinner} />}
        <header className="DetailsLoaded">
          <Box p={2} pl={2}>
            <Grid container>
              <Grid item xs={11}>
                <Typography component="h1" variant="h5">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={classes.closeAction}>
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </header>
        <Box className={"DetailsContent"} p={2} pt={3} sx={classes.content}>
          {children}
        </Box>
        <footer>
          <Box p={2} pt={1.5} textAlign="right">
            <Button
              color="default"
              variant="contained"
              disableElevation={true}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              color="info"
              variant="contained"
              disableElevation={true}
              disabled={disabled}
              onClick={() => onSubmit()}
            >
              {submitText}
            </Button>
          </Box>
        </footer>
      </>
    );
  } else {
    // show loading skeleton
    body = <AbstractorDetailsSkeleton classes={classes} />;
  }

  return <Paper sx={classes.paper} style={{minHeight, height}}>{body}</Paper>;
};

const DetailsPane = ({ show, minWidth, ...props }) => {
  const classes = useStyles();
  return (
    <Pane
      sx={classes.root}
      className={"DetailsPane"}
      show={show}
      minWidth={minWidth}
      mutation
    >
      <DetailsPaneView {...props} />
    </Pane>
  );
};

export default DetailsPane;
