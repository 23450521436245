import React from "react";
import history from "../../../../lib/history";
import routeKeys, { buildUrl } from "../../../../../routes";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UserIcon from "@mui/icons-material/Person";
import { EnhancedTable, formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import { useParams } from "react-router-dom";

const useStyles = makeSxStyles((theme) => ({
    root: {
        height: "100%",
        "& table .Mui-selected": {
            // background:  "rgba(0, 0, 0, 0.04)",
        },
        "& table table .Mui-selected": {
            background:  theme.palette.info.main + "11",
        },
        "& table table .Mui-selected td:first-child": {
            borderLeft: "4px solid " + theme.palette.primary.main,
        }
    },
    assignedUser: {
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    },
    unassignedUser: {
        color: theme.palette.text.hint,
        "& svg": {
            verticalAlign: "text-top",
            height: 14,
            width: 14,
        },
    }
}));

const AbstractorListTableView = (props) => {
    const {
        onAbstractorClick,
        onChangePage,
        onChangeRowsPerPage,
        onRequestSort,
        searchParams,
        results,
        page,
        total,
        isLoading,
        numberOfAppliedFilters,
        onClearFilters,
        abstractorId
    } = props;
    const classes = useStyles();
    const rows = results;

    let emptyMessage = (
        <Box pt={6} textAlign={"center"} fontSize={20}>
            There are currently no abstractor items available. <br /> Please check back soon.
        </Box>
    );
    if(numberOfAppliedFilters > 0) {
        // if they have any filters active and the list is empty tell them to change their filters.
        emptyMessage = (
            <Box pt={6} textAlign={"center"} fontSize={20}>
                There are no items that match the applied filters. <br />
                Please update your filters or clear them with the button below. <br /><br />
                <Button
                    variant={"contained"}
                    disableElevation
                    color={"primary"}
                    onClick={onClearFilters}
                >
                    Clear Filters
                </Button>
            </Box>
        );
    }
    let selectedOrg = rows.findIndex(it => it.id === abstractorId);
    return (
        <Box sx={classes.root}>
            <EnhancedTable
                rows={rows}
                columns={[
                    {
                        id: "username",
                        label: formatMessage({id: "abstractor.list.headerLabel.name", defaultMessage: "Name"}),
                        isSortable: true,
                        Content: ({row}) => <>
                            <Box display={"inline-block"} style={{verticalAlign: "sub"}} mr={.5}><UserIcon /></Box>
                            <strong>{row.username}</strong><br/>Full Name: {row.firstName || ""} {row.lastName || ""}
                        </>
                    },
                    {
                        id: "abstractorType",
                        label: formatMessage({id: "abstractor.list.headerLabel.type", defaultMessage: "Type"}),
                        isSortable: true,
                        Content: ({row}) => <>
                            {row.abstractorType}
                        </>
                    },
                ]}
                isLoading={isLoading}
                emptyMessage={emptyMessage}
                total={total}
                showHeader={true}
                dense={false}
                rowsPerPage={searchParams.size}
                selectedCell={{
                    y: selectedOrg
                }}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onRequestSort={onRequestSort}
                onRowClick={onAbstractorClick}
                onCellClick={() => {}}
                rowsPerPageOptions={[15, 25, 50, 100]}
                order={searchParams.order}
                orderBy={searchParams.orderBy}
                hover={true}
                pagination={true}
                fillEmptyRows={false}
                height={"100%"}
            />
        </Box>
    );
};

const AbstractorListTable = ({isLoading, rows, queryState, setQueryState}) => {
    const {orgKey, implKey, abstractorId} = useParams();
    const state = {
        results: rows,
        page: queryState.page,
        total: rows.length,
        searchParams: {
            order: queryState.order,
            orderBy: queryState.by,
            size: queryState.rowsPerPage,
            search: queryState.search,
        },
        numberOfAppliedFilters: queryState.search ? 1 : 0,
        isLoading: isLoading,
        abstractorId: parseInt(abstractorId, 10),
        onAbstractorClick: (e, row) => history.push(buildUrl(routeKeys.ADMIN_ABSTRACTOR_DETAILS, {orgKey, implKey, abstractorId: row.id})),
        onChangePage:(e, page) => {setQueryState({...queryState, page });},
        onChangeRowsPerPage:(e) => {setQueryState({...queryState, page: 0, rowsPerPage: e.target.value });},
        onRequestSort:(e, nextBy) => {
            setQueryState({
                ...queryState, 
                by: nextBy === queryState.by && queryState.order === "desc" ? "" : nextBy, 
                order: nextBy === queryState.by && queryState.order !== "desc" ? "desc" : "asc"
            })
        },
        onClearFilters: () => {
            history.push("?");
        }
    };

    return (
        <AbstractorListTableView
            {...state}
        />
    );
};

export default AbstractorListTable;
