import React from "react";
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { EnhancedTable, makeSxStyles } from "@armus/armus-dashboard";
import { useFindAbstractableLabel } from "../../components/workFilterHelpers";
import { getMonthNameFromInt, safeDateFormat } from "../../../lib/dateHelpers";
import VerificationStatusChip from "../../Work/components/VerificationStatusChip";
import writeXlsxFile from "write-excel-file";

const useStyles = makeSxStyles((theme) => ({
    root: {
        "& .Mui-selected": {
            background:  theme.palette.info.main + "11",
            "& td:first-child": {
                borderLeft: "4px solid " + theme.palette.primary.main,
            }
        },
        "& table": {
            minWidth: "auto",
            minHeight: 200,
        }
    },
    tableContainer: {
        minHeight: "auto",
        "& > div": {
            minHeight: "auto",

        }
    },
    table: {
        minHeight: "auto"
    }
}));

const InvoicingItemsTable = ({items, isLoading, filters}) => {
    const classes = useStyles();
    return (
        <Paper>
            <EnhancedTable
                sx={classes.table}
                rows={items || []}
                columns={[
                    {
                        id: "organization",
                        props: { align: "left", width: "" },
                        isSortable: false,
                        label: "Organization Short Name",
                        Content: ({row, cell, x, y}) => row.work.associatedCase?.organization?.shortname
                    },
                    {
                        id: "abstractableLabel",
                        props: { align: "left", width: "" },
                        isSortable: false,
                        label: "Abstractable Type",
                        Content: ({row, cell, x, y}) => row.abstractableLabel
                    },
                    {
                        id: "completeDate",
                        props: { align: "left", width: "" },
                        label: "Work Complete Date",
                        isSortable: false,
                        Content: ({row}) => safeDateFormat(row.work.completedAt),
                    },
                    {
                        id: "reportingDateLabel",
                        props: { align: "left", width: "" },
                        isSortable: false,
                        label: "Registry Date Label",
                        Content: ({row, cell, x, y}) => row.reportingDateLabel
                    },
                    {
                        id: "reportingDate",
                        props: { align: "left", width: "" },
                        isSortable: false,
                        label: "Registry Date",
                        Content: ({row, cell, x, y}) => safeDateFormat(row.reportingDate)
                    },
                    {
                        id: "idLabel",
                        props: { align: "left" },
                        label: "Registry ID Label",
                        isSortable: false,
                        Content: ({row}) =>  row.registryGeneratedIdLabel,
                    },
                    {
                        id: "id",
                        props: { align: "left" },
                        label: "Registry ID",
                        isSortable: false,
                        Content: ({row}) =>  row.work.registryGeneratedId,
                    },
                    {
                        id: "initial",
                        props: { align: "left", width: "" },
                        isSortable: false,
                        label: "Initial or Follow Up",
                        Content: ({row, cell, x, y}) => row.initialLabel
                    },
                    {
                        id: "verifiedStatus",
                        props: { align: "right", width: "" },
                        label: "Verified Status",
                        isSortable: false,
                        Content: ({row}) => <VerificationStatusChip status={row.work.verificationStatus} size="xs" />
                    }
                ]}
                isLoading={isLoading}
                emptyMessage={""}
                showHeader={true}
                dense={true}
                selectedCell={{
                }}
                hover={false}
                pagination={false}
                fillEmptyRows={false}
                height={"auto"}
            />
        </Paper>
    );
};

export const useDownloadExcel = () => {
    return (rows, fileName = "invoice.xlsx") => {
        const columns = [
            {
                value: (headerLabel, row) => headerLabel ? "Organization Short Name" : row.work.associatedCase?.organization?.shortname,
                width: 30,
            },
            {
                value: (headerLabel, row) => headerLabel ? "Abstractable Type" : row.abstractableLabel,
                width: 20,
                fontWeight: 'bold',
            },
            {
                value: (headerLabel, row) => headerLabel ? "Work Complete Date" : safeDateFormat(row.work.completedAt),
            },
            {
                value: (headerLabel, row) => headerLabel ? "Registry Date Label" : row.reportingDateLabel,
            },
            {
                value: (headerLabel, row) => headerLabel ? "Registry Date" : safeDateFormat(row.reportingDate),
            },
            {
                value: (headerLabel, row) => headerLabel ? "Registry ID Label" : row.registryGeneratedIdLabel,
                width: 20,
            },
            {
                value: (headerLabel, row) => headerLabel ? "Registry ID" : row.work.registryGeneratedId,
            },
            {
                value: (headerLabel, row) => headerLabel ? "Initial or Follow Up" : row.initialLabel,
                width: 20,
            },
            {
                value: (headerLabel, row) => headerLabel ? "Verified Status" : row.work.verificationStatus,
                width: 20,
            }
        ];

        const data = [
                columns.map((column, index) => ({
                    fontWeight: 'bold',
                    alignVertical: "center",
                    height: 20,
                    color: "#2a651d",
                    backgroundColor: "#d4e0d1",
                    leftBorderStyle: "thin",
                    rightBorderStyle: index === columns.length-1 ? "thin" : '',
                    topBorderStyle: "thin",
                    bottomBorderStyle: "thick",
                    type: String,
                    ...column,
                    value: column.value(true).toUpperCase(),
                }))
        ];

        rows.forEach((row, rowIndex) => {
            data.push(
                columns.map((column, colIndex) => ({
                    rightBorderStyle: colIndex === columns.length-1 ? "thin" : '',
                    bottomBorderStyle: rowIndex === rows.length-1 ? "thick" : 'thin',
                    type: String,
                    ...column,
                    value: column.value(false, row),
                }))
            );
        });

        writeXlsxFile(
            data, 
            {
                columns: columns.map(c => ({width: c.width || 15})),
                fileName: fileName,
            }
        );
    }
};

const InvoiceDetailsTableView = (props) => {
    const {
        section,
        filters,
        result,
        isLoading,
    } = props;
    const classes = useStyles();
    const handleDownloadExcel = useDownloadExcel();
    // if(isLoading) {
    //     return null;
    // }
    const monthYearLabel = `${getMonthNameFromInt(filters.completedMonth-1)} ${filters.completedYear}`;
    return (
        <Box sx={classes.root}>
            <Box sx={classes.root} p={2} pt={1}>
                <Grid container>
                    <Grid item xs={8}>
                    <Typography component="h2" variant="h5" mt={1}>{monthYearLabel} Itemized Abstractions</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box textAlign={"right"}>
                            {result.length > 0 && <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => handleDownloadExcel(
                                    result, 
                                    `${section === "abstractor" ? filters.abstractor : filters.org}-itemized-abstractions-${filters.completedYear}-${filters.completedMonth}-01-${filters.org}.xlsx`
                                )}
                            >
                                Download Itemized Abstractions
                            </Button>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <InvoicingItemsTable isLoading={isLoading} items={result} filters={filters}/>
        </Box>
    );
};

const InvoiceDetailsTable = (props) => {
    const {invoicing} = props;
    const findAbstractableLabel = useFindAbstractableLabel();
    // flatten the bucketing but maintain bucket order...
    const rows = [];
    invoicing.data.forEach(registry => {
        registry.items.forEach(abstractable => {
            abstractable.items.forEach(initial => {
                initial.items.forEach(bucket => {
                    const reportingDateLabel = (bucket.items[0] || {}).primaryReportingDateLabel || "Reporting Date";
                    const idLabel = (bucket.items[0] || {}).registryGeneratedIdLabel || "Registry Generated ID";
                    const abstractableLabel = findAbstractableLabel(abstractable.key) || `ID: ${abstractable.key}`;
                    let initialLabel = initial.key === "initial" ? "Initial" : "Follow Up";
                    bucket.items.forEach(item => {
                        rows.push({
                            ...item,
                            work: item.work,
                            registry, 
                            abstractable, 
                            initial, 
                            reportingDateLabel,
                            idLabel,
                            initialLabel,
                            abstractableLabel
                        });
                    });
                });
            });
        });
    });
    const state = {
        result: rows,
        isLoading: invoicing.isLoading
    };
    return (
        <InvoiceDetailsTableView
            {...props}
            {...state}
        />
    );
};

export default InvoiceDetailsTable;
