import React from "react";
import { Header as HeaderView } from '@armus/armus-dashboard';

const Header = (props) => {
    return (
        <HeaderView {...props} />
    );
};

Header.propTypes = {
    ...HeaderView.propTypes
};

export default Header;
