import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PendingIcon from "@mui/icons-material/Undo";
import RefreshIcon from "@mui/icons-material/Refresh";
import PassedIcon from "@mui/icons-material/ThumbUp";
import FailedIcon from "@mui/icons-material/ThumbDown";
import { formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import {
  useWorkDetailsVerification,
  verificationToTypes,
} from "../../../../providers/WorkDetailsVerificationProvider";
import { Box } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
  root: {
    "& button": {},
    "& div": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
    },
  },
  success: {
    "&.MuiButton-outlined": {
      color: theme.palette.success.main,
    },
    "&.MuiButton-contained": {
      backgroundColor: theme.palette.success.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
  },
  error: {
    "&.MuiButton-outlined": {
      color: theme.palette.error.main,
    },
    "&.MuiButton-contained": {
      backgroundColor: theme.palette.error.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
  primary: {
    "&.MuiButton-outlinedPrimary:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const WorkDetailsVerificationActionView = (props) => {
  const {
    onFailed,
    onVerify,
    onPending,
    onReverify,
    verificationMethod,
    verificationStatus,
  } = props;
  const classes = useStyles();
  let content = null;

  const failedButton = {
    label: formatMessage({
      id: "workDetails.actionButton.startButton",
      defaultMessage: "Failed Verification",
    }),
    sx: classes.error,
    icon: <FailedIcon />,
    onClick: onFailed,
    color: "error",
    variant: "contained"
  };
  const verifiedButton = {
    label: formatMessage({
      id: "workDetails.actionButton.startButton",
      defaultMessage: "Passed Verification",
    }),
    sx: classes.success,
    icon: <PassedIcon />,
    onClick: onVerify,
    color: "primary",
    variant: "contained"
  };
  const pendingButton = {
    label: formatMessage({
      id: "workDetails.actionButton.pendingButton",
      defaultMessage: "Set to Pending Review",
    }),
    icon: <PendingIcon />,
    onClick: onPending,
    color: "info",
  };

  const reverifyButton = {
    label: formatMessage({
      id: "workDetails.actionButton.reverifyButton",
      defaultMessage: "Force Reverification",
    }),
    icon: <RefreshIcon />,
    onClick: onReverify,
    color: "info",
  };

  let actions = [];
  let extraMessage = null;

  if (verificationMethod === "MANUAL") {
    switch (verificationStatus) {
      case verificationToTypes.VERIFIED:
        actions.push(pendingButton);
        break;
      case verificationToTypes.FAILED:
        actions.push(pendingButton);
        break;
      case verificationToTypes.PENDING_REVIEW:
        actions.push(verifiedButton, failedButton);
        break;
      default:
    }
  } else if (verificationMethod === "AUTOMATIC") {
    switch (verificationStatus) {
      case verificationToTypes.VERIFIED:
      case verificationToTypes.FAILED:
      default:
        actions.push(reverifyButton);
    }
  }

  content = (
    <ButtonGroup
      disableElevation
      variant="outlined"
      fullWidth={true}
      color="secondary"
    >
      {actions.map((btn, index) => (
        <Button
          key={index}
          size="medium"
          color={btn.color}
          onClick={btn.onClick}
          variant={btn.variant || (index === 0 ? "contained" : "outlined")}
        >
          {btn.icon}&nbsp;{btn.label}
        </Button>
      ))}
    </ButtonGroup>
  );

  return (
    <Box sx={classes.root}>
      {content}
      {extraMessage}
    </Box>
  );
};

const WorkDetailsVerificationAction = (props) => {
  const { onStartVerification, verificationMethod, verificationStatus, onAutoForceReverify } =
    useWorkDetailsVerification(props.workdetails);
  const state = {
    verificationMethod,
    verificationStatus,
    onVerify: () => {
      onStartVerification(verificationToTypes.VERIFIED);
    },
    onFailed: () => {
      onStartVerification(verificationToTypes.FAILED);
    },
    onPending: () => {
      onStartVerification(verificationToTypes.PENDING_REVIEW);
    },
    onReverify: () => {
        onAutoForceReverify();
    },
  };
  return <WorkDetailsVerificationActionView {...props} {...state} />;
};

export default WorkDetailsVerificationAction;
