import React from "react";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import {useNotifications} from "@armus/armus-dashboard";


let queryClient = null;
export default ({children, options}) => {
    const {onPush} = useNotifications();
    if(queryClient === null) {
        queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 1,
                    onError: (error) => {
                        if(error.notifyError) {
                            onPush({
                                key: "query",
                                message: error.message,
                                type: "error",
                                link: error.link,
                            });
                        }
                    }
                }
            },
        });
    }
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
}