import WorkSummary from "./app/views/Work/WorkSummary/WorkSummary";
import Work from "./app/views/Work/Work";
import { matchPath } from "react-router-dom";
import history from "./app/lib/history";
import qs from 'query-string';
import Invoicing from "./app/views/Invoicing/Invoicing";
import Reporting from "./app/views/Reporting/Reporting";
import Verification from "./app/views/Verification/Verification";
import Admin from "./app/views/Admin/Admin";

const routes = {
    ADMIN_ORGANIZATION_DETAILS: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-admin)/:section(organization)/:organizationId(new|[0-9]+)?`,
        component: Admin,
    },
    ADMIN_ORGANIZATION_LIST: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-admin)/:section(organization)/`,
        component: Admin,
    },
    ADMIN_ABSTRACTOR_DETAILS: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-admin)/:section(abstractor)/:abstractorId(new|[0-9]+)?`,
        component: Admin,
    },
    ADMIN_ABSTRACTOR_LIST: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-admin)/:section(abstractor)/`,
        component: Admin,
    },
    ADMIN: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-admin)/`,
        component: Admin,
    },
    VERIFICATION_DETAILS: {
        exact: false,
        path: `/:orgKey/:implKey(adas-verification)/work/:workId([0-9]+)?`,
        component: Verification,
    },
    VERIFICATION_LIST: {
        exact: false,
        path: `/:orgKey/:implKey(adas-verification)/work/`,
        component: Verification,
    },
    VERIFICATION_SUMMARY: {
        type: "redirect",
        exact: true,
        from: `/:orgKey/:implKey(adas-verification)/`,
        to: `/:orgKey/:implKey(adas-verification)/work/`,
    },
    REPORTING: {
        exact: false,
        path: `/:orgKey/:implKey(adas-reporting)/`,
        component: Reporting,
    },
    INVOICING_ORGANIZATION: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-invoicing)/:section(organization)`,
        component: Invoicing,
    },
    INVOICING_ABSTRACTOR: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-invoicing)/:section(abstractor)`,
        component: Invoicing,
    },
    INVOICING: {
        exact: false,
        path: `/:orgKey(armus)/:implKey(adas-invoicing)/`,
        component: Invoicing,
    },
    WORK_SUMMARY: {
        exact: true,
        path: `/:orgKey/:implKey(adas)/`,
        component: WorkSummary,
    },
    WORK_DETAILS: {
        exact: false,
        path: `/:orgKey/:implKey(adas)/work/:workId([0-9]+)?`,
        component: Work,
    },
    WORK_LIST: {
        exact: true,
        path: `/:orgKey/:implKey(adas)/work/`,
        component: Work,
    },
};

const keys = Object.keys(routes);
keys.forEach(k => routes[k].key = k);
const routeKeys = {};
keys.forEach(k => routeKeys[k] = k);

export default routeKeys;

export const getApplicationRoutes = (asArray = true) => {
    if(asArray) {
        return Object.values(routes);
    }
    return routes;
};

export const matchRoute = (location) => {
    location = location || history.location;
    const routes = getApplicationRoutes();
    for(let i = 0; i < routes.length; i++) {
        const route = routes[i];
        const match = matchPath(location.pathname, routes[i]);
        if(match !== null) {
            return {route, match}; // return first match.
        }
    }
    return {route: null, match: null};
};

export const getRouteByKey = (routeKey) => {
    const route = getApplicationRoutes(false)[routeKey];
    if(!route) {
        throw new Error(`Unknown routeKey: ${routeKey}`);
    }
    return route;
};

export const buildUrl = (routeKey, params = {}, query) => {
    const route = getRouteByKey(routeKey);
    let url = route.path;

    let routeKeysAndDefaults = url.split('/').map( key => {
        if(key.indexOf(':') === -1) {
            return [''];
        }
        return key.split('(').map(it => it.replace(')', '').replace(':', ''));
    }).filter(it => !!it[0]);

    routeKeysAndDefaults.forEach(parts => {
        const key = parts[0];
        const defaultValue = parts[1];

        const value = typeof params[key] === "string" || typeof params[key] === "number" ? params[key] : (defaultValue || "");
        const reg = new RegExp(`:${key}[^/]*`, 'g');
        url = url.replace(reg, value);
    });

    // remove params not provided...
    const reg = new RegExp(`:.+[^/]*`, 'g');
    url = url.replace(reg, "");

    if(query) {
        // add query string
        if(typeof query === "object") {
            query = {...query};
            Object.keys(query).forEach(key => {
                if(query[key] === "") {
                    // remove the empty value filters to keep the url clean.
                    delete query[key];
                }
            });
            url += "?" + qs.stringify(query);
        }
        else if(typeof query === "string") {
            url += query;
        }
    }
    return url;
};