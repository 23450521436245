import React, { createContext } from "react";

export const WorkFiltersContext = createContext({defaultValues: {}, lockedValues: {}});

function WorkFiltersProvider({ children, defaultValues = {}, lockedValues = {} }) {
  const value = {defaultValues, lockedValues};
  return (
    <WorkFiltersContext.Provider value={value}>
      { children }
    </WorkFiltersContext.Provider>
  );
}

export default WorkFiltersProvider;

export const useWorkFiltersDefaultValues = () => { 
  const ctx = React.useContext(WorkFiltersContext);
  return ctx.defaultValues || {};
};
export const useWorkFiltersLockedValues = () => { 
  const ctx = React.useContext(WorkFiltersContext);
  return ctx.lockedValues || {};
};