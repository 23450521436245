import {
    useActiveImplKey,
    useActiveOrgKey,
    useUsername,
} from "../providers/UserProvider";
import { useMutation, useQueryClient } from "react-query";
import api from "./api";
import {
    transitionToTypes
} from "../providers/WorkDetailsTransitionProvider";
import {useNotifications} from "@armus/armus-dashboard";
import history from "./history";
import routeKeys, { buildUrl } from "../../routes";
import { useLocation } from "react-router-dom";

import {ABSTRACTOR_IMPLEMENTATION, ADMIN_IMPLEMENTATION} from "./queries";
import { useWorkDetails } from "../providers/WorkDetailsProvider";

// this is a little wonky ... transition api payload started off simple then
// grew more complicated.
const requestWorkTransition = (orgKey, implKey, username, transitionState, workDetails) => {
    const { transitionTo } = transitionState;
    const data = { ...transitionState.data };
    if (!orgKey || !implKey) {
        throw new Error("requestWorkTransition has no params?!");
    }

    
    if(data.otherWorkToStart.length) {
        const items = data.otherWorkToStart.map((workId) => {
            return () => {
                const payload = {
                    changes: [
                        {
                            "type" : "assign",
                            "username" : username
                        }
                    ]
                };
                return api.postWorkDetailChange(orgKey, implKey, workId, payload);
            };
        });
        return Promise.all(items.map(p => p())).then(() => api.getWorkDetailsData(orgKey, implKey, workDetails.id));
    }
    
    const changes = [];
    switch(transitionTo) {
        case transitionToTypes.IN_PROGRESS:
            changes.push({
                "type" : "setStatus",
                "status" : "IN_PROGRESS"
            });
            changes.push({
                "type" : "assign",
                "username" : username
            });
            break;
        case transitionToTypes.INVALID:
            changes.push({
                "type" : "setStatus",
                "status" : "INVALID"
            });
            changes.push({
                "type" : "assign",
                "username" : username
            });
            break;
        case transitionToTypes.CONTINUE_PROGRESS:
            changes.push({
                "type" : "setStatus",
                "status" : "IN_PROGRESS"
            });
            changes.push({
                "type" : "assign",
                "username" : username
            });
            break;
        case transitionToTypes.REQUIRES_INFORMATION:
            changes.push({
                "type" : "setStatus",
                "status" : "REQUIRES_INFORMATION"
            });
            break;
        case transitionToTypes.COMPLETE:
            changes.push({
                "type" : "setStatus",
                "status" : "COMPLETE"
            });
            changes.push({
                "type" : "setRegId",
                "registryGeneratedId" : data.registryGeneratedId
            });
            break;
        case transitionToTypes.EDIT_REGISTRY_GENERATED_ID:
            changes.push({
                "type" : "setRegId",
                "registryGeneratedId" : data.registryGeneratedId
            });
            break;
        case transitionToTypes.ABANDON:
            // abandon does not change status.
            changes.push({
                "type" : "assign",
                "username" : null
            });
            break;
        case transitionToTypes.SCHEDULE_FOLLOWUP: {
            // followup does not change status.
            const payload = {
                "followUpOn": {
                    "id": workDetails.id
                },
                "abstractable": {
                    "id": data.abstractableId
                },
                "associatedCase": {
                    "id": workDetails.associatedCase.id
                },
                "visibleAfter": data.visibleDate
            };
            // followup uses a different endpoint.
            return api.postAddWorkData(orgKey, implKey, payload).then(() => {
                // then get the latest the work details.
                return api.getWorkDetailsData(orgKey, implKey, workDetails.id);
            });
        }
        default:
    }

    if(!!data.noteToAdd) {
        changes.push(
            {
                "type" : "addNote",
                "note" : data.noteToAdd
            }
        );
    }
    const payload = {
        changes: changes
    };
    return api.postWorkDetailChange(orgKey, implKey, workDetails.id, payload);
};

export const useCreateWorkDetailsTransitionMutation = (options = {}) => {
    const {onPush} = useNotifications();
    const orgKey = useActiveOrgKey();
    const implKey = ABSTRACTOR_IMPLEMENTATION;
    const username = useUsername();
    const {workDetails} = useWorkDetails();
    const queryClient = useQueryClient();
    return useMutation(
        (transitionState) => {
            return requestWorkTransition(orgKey, implKey, username, transitionState, workDetails);
        },
        {
            ...options,
            onSuccess: (res) => {
                // invalidate caches
                queryClient.invalidateQueries(["workList"]);
                queryClient.invalidateQueries(["caseList"]);
                queryClient.setQueryData(["workDetails", orgKey, implKey, res.data.id], () => res.data);
                onPush({key: "saveSuccess", message: "Your changes have been saved.", type: "success"});
                options.onSuccess && options.onSuccess(res);
            },
            onError: () => {
                onPush({key: "saveError", message: "Your changes could not be saved. Please try again later.", type: "error"});
            }
        },
    );
};

export const useAddWorkMutation = (options = {}) => {
    const {onPush} = useNotifications();
    const orgKey = useActiveOrgKey();
    const implKey = ABSTRACTOR_IMPLEMENTATION;
    const queryClient = useQueryClient();
    const {search} = useLocation();
    return useMutation(
        (data) => {
            const payload = {
                "abstractable": {
                    "id": data.abstractableId
                },
                "associatedCase": {
                    "id": data.associatedCaseId
                }
            };
            // followup uses a different endpoint.
            return api.postAddWorkData(orgKey, implKey, payload).then((res) => {
                // then get the latest the work details.
                const newWorkId = res.data.id;
                history.push(buildUrl(
                    routeKeys.WORK_DETAILS, {
                        orgKey,
                        implKey,
                        workId: newWorkId
                    }, search)
                );
                onPush({key: "saveSuccess", message: "Successfully added new work.", type: "success"});
                return res;
            });
        },
        {
            ...options,
            onSuccess: (res) => {
                // invalidate caches
                queryClient.invalidateQueries(["workList"]);
                queryClient.invalidateQueries(["caseList"]);
                options.onSuccess && options.onSuccess(res);
            },
            onError: () => {
                onPush({key: "saveError", message: "Your your work could not be added. Please try again later.", type: "error"});
            }
        },
    );
};


export const useWorkDetailsValidationMutation = (options = {}) => {
    const {onPush} = useNotifications();
    const orgKey = useActiveOrgKey();
    const implKey = useActiveImplKey();
    const queryClient = useQueryClient();
    const {workDetails} = useWorkDetails();
    return useMutation(
        (data) => {
            const workId = workDetails.id;
            const changes = [
                {
                    "type" : "setVerification",
                    "verificationStatus" : data.verificationStatus
                } 
            ];
            if(!!data.note) {
                changes.push(
                    {
                        "type" : "addNote",
                        "note" : data.note
                    }
                ); 
            }
            const payload = {
                changes: changes
            }

            // followup uses a different endpoint.
            return api.postWorkDetailChange(orgKey, implKey, workId, payload).then((res) => {
                onPush({key: "saveSuccess", message: "Successfully changed the verification status.", type: "success"});
                return res;
            });
        },
        {
            ...options,
            onSuccess: (res) => {
                // invalidate caches
                queryClient.invalidateQueries(["workList"]);
                queryClient.invalidateQueries(["caseList"]);
                queryClient.invalidateQueries(["workDetailsVerification"]);
                // update the work details cache.
                queryClient.setQueryData(["workDetails", orgKey, ABSTRACTOR_IMPLEMENTATION, res.data.id], () => res.data);
                options.onSuccess && options.onSuccess(res);
            },
            onError: () => {
                onPush({key: "saveError", message: "Verification status could not be saved. Please try again later.", type: "error"});
            }
        },
    );
};


export const useAdminOrganizationMutation = (options = {}) => {
    const {onPush} = useNotifications();
    const orgKey = useActiveOrgKey();
    const implKey = ADMIN_IMPLEMENTATION;
    const queryClient = useQueryClient();
    const {search} = useLocation();
    return useMutation(
        (data) => {
            const payload = {...data};
            let request;
            if(payload.id === "new") {
                delete payload.id;
                request = api.postAdminOrginizationData(orgKey, implKey, payload);
            }
            else {
                request = api.putAdminOrginizationData(orgKey, implKey, payload.id, payload)
            }
            // followup uses a different endpoint.
            return request.then((res) => {
                // then get the latest the work details.
                const newId = res.data.id;
                history.push(buildUrl(
                    routeKeys.ADMIN_ORGANIZATION_DETAILS, {
                        orgKey,
                        implKey,
                        organizationId: newId
                    }, search)
                );
                onPush({key: "saveSuccess", message: "Successfully saved.", type: "success"});
                return res;
            });
        },
        {
            ...options,
            onSuccess: (res) => {
                // invalidate caches
                queryClient.invalidateQueries(["adminOrganization"]);
                options.onSuccess && options.onSuccess(res);
            },
            onError: () => {
                onPush({key: "saveError", message: "Your details could not be saved. Please try again later.", type: "error"});
            }
        },
    );
};

export const useAdminAbstractorMutation = (options = {}) => {
    const {onPush} = useNotifications();
    const orgKey = useActiveOrgKey();
    const implKey = ADMIN_IMPLEMENTATION;
    const queryClient = useQueryClient();
    const {search} = useLocation();
    return useMutation(
        (data) => {
            const payload = {...data};
            let request;
            if(payload.id === "new") {
                delete payload.id;
                request = api.postAdminAbstractorData(orgKey, implKey, payload);
            }
            else {
                request = api.putAdminAbstractorData(orgKey, implKey, payload.id, payload)
            }
            // followup uses a different endpoint.
            return request.then((res) => {
                // then get the latest the work details.
                const newId = res.data.id;
                history.push(buildUrl(
                    routeKeys.ADMIN_ABSTRACTOR_DETAILS, {
                        orgKey,
                        implKey,
                        abstractorId: newId
                    }, search)
                );
                onPush({key: "saveSuccess", message: "Successfully saved.", type: "success"});
                return res;
            });
        },
        {
            ...options,
            onSuccess: (res) => {
                // invalidate caches
                queryClient.invalidateQueries(["adminAbstractor"]);
                options.onSuccess && options.onSuccess(res);
            },
            onError: () => {
                onPush({key: "saveError", message: "Your details could not be saved. Please try again later.", type: "error"});
            }
        },
    );
};