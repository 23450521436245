import React from "react";
import { formatMessage, PaneLayout } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../../routes";
import {
  useActiveImplementation,
  useActiveOrganization,
  useDisplayName,
} from "../../../providers/UserProvider";
import AbstractorList from "./components/AbstractorList";
import AbstractorDetails from "./components/AbstractorDetails";
import AdminAbstractorProvider from "../../../providers/AdminAbstractorProvider";

export const useAdminAbstractorBreadcrumb = () => {
  const org = useActiveOrganization() || {};
  const impl = useActiveImplementation() || {};
  const userName = useDisplayName();
  return {
    label: formatMessage(
      {
        id: "AdminAbstractor.breadcrumb",
        defaultMessage: "Abstractors",
      },
      { orgName: org.name, implName: impl.name, userName: userName }
    ),
    url: buildUrl(routesKeys.WORK_SUMMARY, {
      orgKey: org.key,
      implKey: impl.key,
    }),
  };
};

const AdminAbstractor = () => {
  return (
    <AdminAbstractorProvider>
      <PaneLayout style={{paddingTop: 0, paddingBottom: 1}}>
        <AbstractorList />
        <AbstractorDetails />
      </PaneLayout>
    </AdminAbstractorProvider>
  )
};

AdminAbstractor.propTypes = {};
AdminAbstractor.label = "Abstractors";
AdminAbstractor.url = "/armus/adas-admin/abstractor";
AdminAbstractor.breadcrumb = useAdminAbstractorBreadcrumb;
AdminAbstractor.secured = {
  implKey: "adas-admin",
};

export default AdminAbstractor;
