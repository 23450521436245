import React from "react";
import { InfoChip } from "@armus/armus-dashboard";
import { getVerificationStatusInfo } from "../../components/workFilterHelpers";

const VerificationStatusChip = ({status, variant = "default", size = "small"}) => {
    return (
        <InfoChip
            variant={variant}
            size={size}
            {...getVerificationStatusInfo(status)}
        />
    );
};

export default VerificationStatusChip;