import React, {useContext} from "react";
import WorkDetailsTransitionProvider, { useWorkDetailsTransition, WorkDetailsTransitionContext } from "./WorkDetailsTransitionProvider";
import WorkDetailsVerificationProvider, { WorkDetailsVerificationContext } from "./WorkDetailsVerificationProvider";
import {
  useFindOrganization,
  useUsername
} from "./UserProvider";
import { useWorkDetailsQuery } from "../lib/queries";
import { useFindAbstractableLabel } from "../views/components/workFilterHelpers";
import { useParams } from "react-router";

export const WorkDetailsContext = React.createContext({
  isLoading: true,
  isSuccess: false,
  isError: false,
  data: {}
});

export const useWorkDetailsIsUpdating = () => {
  const transitionCtx = useContext(WorkDetailsTransitionContext);
  const verificationCtx = useContext(WorkDetailsVerificationContext);
  return transitionCtx.__mutation.isLoading || verificationCtx.__mutation.isLoading;
};

export const useWorkDetailsIsTransitionOpen = () => {
  const transitionCtx = useContext(WorkDetailsTransitionContext);
  const verificationCtx = useContext(WorkDetailsVerificationContext);
  return transitionCtx.__state.transitionTo !== null || verificationCtx.__state.transitionTo !== null;
};

export const useIsMyWork = (workDetails = {}) => {
  const username = useUsername();
  const abstractorUsername = !!workDetails.assignedAbstractor ? workDetails.assignedAbstractor.username : null;
  return abstractorUsername === username;
};

export const useCanAbandon = (workDetails = {}) => {
  const isMyWork = useIsMyWork(workDetails);
  const status = workDetails.status;
  return isMyWork && ["NEW", "IN_PROGRESS", "REQUIRES_INFORMATION"].includes(status); // COMPLETE/INVALID cannot be abandoned
};

export const useActiveWorkId = () => {
  const {workId} = useParams();
  return workId;
}

export const useWorkDetails = () => {
  const workDetailsState = useContext(WorkDetailsContext);
  const {isLoading, isSuccess, isError, data} = workDetailsState;
  const workDetails = {...data};
  const isMyWork = useIsMyWork(workDetails);
  const canAbandon = useCanAbandon(workDetails);
  const isUpdating = useWorkDetailsIsUpdating();
  const isTransitionOpen = useWorkDetailsIsTransitionOpen();
  const {
      onAbandon,
      onAddNote,
      onChangeRegistryGeneratedId
  } = useWorkDetailsTransition();
  const findOrganization = useFindOrganization();
  const findAbstractableLabel = useFindAbstractableLabel();
  if(!isLoading && isSuccess && !isError && !!workDetails) {
      // add the synthetic abstractable type.
      workDetails.siblingList = (workDetails.siblingList || []).map(it => ({
          ...it,
          abstractableLongname: findAbstractableLabel(it.abstractableId) || it.abstractableId
      }));
      workDetails.type = ((workDetails.abstractable.registry.displayName || "") + " " + (workDetails.abstractable.displayName || ""));
      workDetails.associatedCase.organization.longname = (findOrganization(workDetails.associatedCase.organization.shortname) || {}).name || workDetails.associatedCase.organization.shortname;
  }
  return {
      workDetails,
      isLoading,
      isUpdating,
      isSuccess,
      isError,
      isMyWork,
      isTransitionOpen,
      isUnassigned: workDetails.assignedAbstractor === null,
      canAbandon,
      onAbandon,
      onAddNote,
      onChangeRegistryGeneratedId,
  }
};

export const WorkDetailsProvider = ({ children }) => {
  const workDetailsQuery = useWorkDetailsQuery();
  return (
    <WorkDetailsContext.Provider value={workDetailsQuery}>
      <WorkDetailsTransitionProvider>
        <WorkDetailsVerificationProvider>
          {children}
        </WorkDetailsVerificationProvider>
      </WorkDetailsTransitionProvider>
    </WorkDetailsContext.Provider>
  );
};
