import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import FilterIcon from "@mui/icons-material/FilterList";
import { Text, Select, Checkbox, DatePicker, Spinner, formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import moment from "moment-timezone";
import DateRangeSlider from "../../components/DateRangeSlider";
import config from "../../../../config";
import useWorkSearchParams from "../../components/useWorkSearchParams";
import { useWorkFiltersQuery } from "../../../lib/queries";
import { buildFilterInfo } from "../../components/workFilterHelpers";
import WorkListFiltersSummary from "./WorkListFiltersSummary";
import { useWorkFiltersDefaultValues, useWorkFiltersLockedValues } from "../../../providers/WorkFiltersProvider";
import useChangeDebounce from "../../components/useChangeDebounce";

const useStyles = makeSxStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.mode === "dark" ? "#212121" : "#f5f5f5",
        minHeight: 200,
        "& .MuiSelect-root": {
            "& > div": {
                overflow: "auto",
                flexWrap: "nowrap",
                "& .MuiChip-root": {
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 2
                }
            }
        }
    },
    extraFilters: {
        height: 43,
        "& .MuiTabs-root": {
            marginLeft: -2,
            marginTop: -.5,
        },
        "& .MuiTabs-indicator": {
            bottom: 4,
        }
    },
    title: {
        backgroundColor: theme.palette.background.paper,
    },
    filterButtonContainer: {
        textAlign: "right"
    },
    simpleFilters: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.7),
    },
    advancedFilters: {
        height: 0,
        overflow: "hidden",
        transition: ".5s",
        backgroundColor: theme.palette.mode === "dark" ? "#00000066" : "#ffffff88",
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        borderTop: "1px solid",
        borderTopColor: theme.palette.mode === "dark" ? "#515151" : "#e0e0e0",
        "& > div": {
            opacity: 0,
            transition: ".5s",
        },
        "&.showFilters": {
            height: 201,
            overflow: "auto",
            paddingBottom: .5,
            "& > div": {
                opacity: 1,
            }
        }

    }
}));

const WorkListFiltersView = (props) => {
    const {
        show = false,
        searchParams,
        onOpen,
        numberOfAppliedFilters,
        isLoading,
        filterData,
        onSetFilter,
        extraFilters = null,
        defaultValues = {},
        lockedValues = {}
    } = props;
    const classes = useStyles();
    const handleDebounceChange = useChangeDebounce(onSetFilter);
    const [state, setState] = React.useState({...searchParams});

    const handleChange = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleDateChange = (key, value) => {
        handleChange(key, value);
        if(!moment(value).isValid()) {
            // invalid date
            value = "";
        }
        else {
            value = moment(value).format("YYYY-MM-DD");
        }
        onSetFilter(key, value);
    };

    const handleSliderRange = (event, value) => {
        setState({...state, createdAtAfter: value[0], createdAtBefore: value[1]});
        onSetFilter({createdAtAfter: value[0], createdAtBefore: value[1]});
    };

    React.useEffect(() => {
        setState(searchParams);
    }, [searchParams]);

    const filterInfo = buildFilterInfo(filterData);

    let advancedFilters = <Spinner />;
    if(!isLoading) {
        advancedFilters = (
            <React.Fragment>
                <Grid spacing={1} container>
                    <Grid item xs={4}>
                        <Text
                            descriptor={{id: "mrn", label: filterInfo.mrn.label, config: {dense: true}}}
                            value={state.mrn}
                            onChange={(e, v) => {handleDebounceChange("mrn", v); handleChange("mrn", v);}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            descriptor={{id: "organization", label: filterInfo.organization.label, config: {multivalued: true, dense: true}}}
                            value={searchParams.organization}
                            data={filterInfo.organization.items}
                            onChange={(e, v) => onSetFilter("organization", v)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            descriptor={{id: "abstractable", label: filterInfo.abstractable.label, config: {multivalued: true, dense: true}}}
                            value={searchParams.abstractable}
                            data={filterInfo.abstractable.items}
                            onChange={(e, v) => onSetFilter("abstractable", v)}
                        />
                    </Grid>
                </Grid>
                <Grid spacing={1} container>
                    <Grid item xs={4}>
                        <DateRangeSlider
                            label={formatMessage({id: "filters.workAgeLabel", defaultMessage: "Work Age"})}
                            value={[
                                (typeof state.createdAtAfter === 'number' ? state.createdAtAfter : -125),
                                (typeof state.createdAtBefore === 'number' ? state.createdAtBefore : 5)
                            ]}
                            onChange={handleSliderRange}
                            valueLabelDisplay="off"
                            min={-125}
                            max={5}
                            step={5}
                            minDistance={10}
                            marks={[
                                {
                                    value: -120,
                                    label: formatMessage({id: "workList.filters.maxAgeInputValue", defaultMessage: "120 days"}),
                                },
                                {
                                    value: -90,
                                    label: "90",
                                },
                                {
                                    value: -60,
                                    label: "60",
                                },
                                {
                                    value: -30,
                                    label: "30",
                                },
                                {
                                    value: 0,
                                    label: formatMessage({id: "workList.filters.minAgeInputValue", defaultMessage: "0 days"}),
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            descriptor={{
                                id: "admitAfter",
                                label: filterInfo.admitAfter.label,
                                config: {
                                    format: config.ui.dateFormatInput,
                                    dense: true,
                                    disableTime: true,
                                }
                            }}
                            onChange={(e, v) => handleDateChange("admitAfter", v)}
                            disabled={!!lockedValues.admitAfter}
                            value={searchParams.admitAfter || null}
                            helperText={""}
                            error={false}
                            disableFuture={true}
                        />
                        <DatePicker
                            descriptor={{
                                id: "admitBefore",
                                label: filterInfo.admitBefore.label,
                                config: {
                                    format: config.ui.dateFormatInput,
                                    dense: true,
                                    disableTime: true,
                                }
                            }}
                            disabled={!!lockedValues.admitBefore}
                            onChange={(e, v) => handleDateChange("admitBefore", v)}
                            value={searchParams.admitBefore || null}
                            helperText={""}
                            error={false}
                            disableFuture={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            descriptor={{id: "status", label: filterInfo.status.label, config: {dense: true}}}
                            value={searchParams.status}
                            disabled={!!lockedValues.status}
                            data={filterInfo.status.items}
                            onChange={(e, v) => onSetFilter("status", v)}
                        />
                        <Select
                            descriptor={{id: "verificationStatus", label: filterInfo.verificationStatus.label, config: {dense: true}}}
                            disabled={!!lockedValues.verificationStatus}
                            value={searchParams.verificationStatus}
                            data={filterInfo.verificationStatus.items}
                            onChange={(e, v) => onSetFilter("verificationStatus", v)}
                        />
                    </Grid>
                </Grid>
                <Grid spacing={1} container>
                    <Grid item xs={4}>
                        <Checkbox 
                            descriptor={{id: "allowNoWork", label: filterInfo.allowNoWork.label, config: {dense: true}}}
                            disabled={!!lockedValues.allowNoWork}
                            value={searchParams.allowNoWork === "true"}
                            onChange={(e, v) => onSetFilter({"scope": !v  ? searchParams.scope : "all", "allowNoWork": v ? "true" : "false"})}
                        />
                    </Grid> 
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <Box sx={classes.root}>
            <Box sx={classes.simpleFilters} mt={0.5}>
                <Grid spacing={1} container>
                    <Grid item xs={9} className={" workListExtraFilters"} sx={classes.extraFilters}>
                        {extraFilters}
                    </Grid>
                    <Grid item xs={3} className={" workListMoreFilters"} sx={classes.filterButtonContainer}>
                        <Badge badgeContent={numberOfAppliedFilters} color="primary">
                            <Button onClick={onOpen} color="secondary">
                                <FilterIcon />&nbsp;More&nbsp;Filters
                            </Button>
                        </Badge>
                    </Grid>
                </Grid>
            </Box>
            <Box className={(show ? " showFilters" : "")} sx={classes.advancedFilters}>
                {show ? advancedFilters : null}
            </Box>
            {numberOfAppliedFilters > 0 && <WorkListFiltersSummary lockedValues={lockedValues} defaultValues={defaultValues}  />}
        </Box>
    );
};

const WorkListFilters = (props) => {
    const [searchParams, activeFiltersCount, onSetFilter] = useWorkSearchParams();
    const filterQuery = useWorkFiltersQuery();
    const defaultValues = useWorkFiltersDefaultValues();
    const lockedValues = useWorkFiltersLockedValues();
    const state = {
        filterData: filterQuery.data,
        isLoading: filterQuery.isLoading,
        searchParams,
        numberOfAppliedFilters: activeFiltersCount,
        onSetFilter,
        defaultValues: defaultValues,
        lockedValues: lockedValues,
    };
    return (
        <WorkListFiltersView {...props} {...state} />
    );
};

export default WorkListFilters;