import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormattedMessage, makeSxStyles, Select, Typeahead } from "@armus/armus-dashboard";
import InvoiceTable from "./InvoiceTable";
import InvoiceDetailsTable from "./InvoiceDetailsTable";
import moment from "moment-timezone";
import { useOrganizations} from "../../../providers/UserProvider";
import Typography from "@mui/material/Typography/Typography";
import { Tab, Tabs } from "@mui/material";
import { useInvoicing } from "../../components/useInvoicing";
import useSearchState from "../../components/useSearchState";

const useStyles = makeSxStyles((theme) => ({
    root: {
    }
}));

const getYearsWithMonths = (yearsBack = 3) => {
    var years = [];
    const startDate = moment().utc().add(-(yearsBack-1), 'year').startOf('year');
    const endDate = moment().utc();
    let lastYear = startDate.format("YYYY");
    let months = [];
    let year;
    while (startDate.isBefore(endDate)) {
        year = startDate.format("YYYY");
        if(lastYear !== year) {
            months.reverse();
            years.push({
                label: lastYear,
                value: lastYear,
                months,
            });
            months = [];
        }
        months.push({
            label: startDate.startOf('day').format("MMMM"),
            value: startDate.startOf('day').format("M"),
        });
        startDate.add(1, 'month');
        lastYear = year;
    }
    months.reverse();
    years.push({
        label: year,
        value: year,
        months,
    });
    years.reverse();
    return years;
};


const Invoice = ({title, section}) => {
    const classes = useStyles();
    //const orgKey = useActiveOrgKey();
    const organizations = useOrganizations();
    const years = getYearsWithMonths(3);
    const [filters, setFilters] = useSearchState({
        org: null,
        abstractor: null,
        completedMonth: years[0].months[0].value,
        completedYear: years[0].value,
        activeTab: 0
    });
    const invoicing = useInvoicing(section, filters);
    const months = (years.find((y) => y.value === filters.completedYear) || {}).months || [];
    const handleChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    };
    const body = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper mb={3}>
                    <Box mb={3} p={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={7}>
                                <Typography component="h1" variant="h4">
                                    <FormattedMessage
                                        id="invoicing.title"
                                        defaultMessage={title}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {section === "abstractor" ? (
                                        <Typeahead
                                            descriptor={{
                                                id: "org",
                                                label: "Abstractor",
                                                config: {
                                                    groupBy: null,
                                                    options: (invoicing.absractorsQuery.data || []).map(it => ({label: it.firstName + " " + it.lastName, value: it.username}))
                                                }
                                            }}
                                            value={filters.abstractor}
                                            onChange={(e,v) => handleChange("abstractor", v)}
                                        />
                                    ) : (
                                        <Select
                                            descriptor={{
                                                id: "org",
                                                label: "Organization",
                                                config: {
                                                    options: organizations.map(it => ({label: it.name, value: it.key}))
                                                }
                                            }}
                                            value={filters.org}
                                            onChange={(e,v) => handleChange("org", v)}
                                        />
                                    )
                                }
                                
                            </Grid>
                            <Grid item xs={1}>
                                <Select
                                    descriptor={{
                                        id: "year",
                                        label: "Year",
                                        config: {
                                            dense: true,
                                            options: years,
                                        }
                                    }}
                                    value={filters.completedYear}
                                    onChange={(e,v) => handleChange("completedYear", v)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    descriptor={{
                                        id: "month",
                                        label: "Month",
                                        config: {
                                            dense: true,
                                            options: months,
                                        }
                                    }}
                                    value={filters.completedMonth}
                                    onChange={(e,v) => handleChange("completedMonth", v)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                {(!!filters.org || !!filters.abstractor) ? (<>
                    <Paper>
                        <Tabs
                            value={filters.activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, v) => handleChange("activeTab", v)}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Invoice" />
                            <Tab label="Itemized Abstractions" />
                        </Tabs>
                        {filters.activeTab === 0 && <InvoiceTable invoicing={invoicing} filters={filters} section={section} />}
                        {filters.activeTab === 1 && <InvoiceDetailsTable invoicing={invoicing} filters={filters} section={section} />}
                    </Paper>
                </>) : (
                    <Typography variant="h6" component={Box} textAlign="center">
                        Please select a {section === "abstractor" ? "abstractor" : "organization"} to view their invoicing items.
                    </Typography>
                )}
            </Grid>
        </Grid>
    );

    return (
        <Box sx={classes.root} overflow="auto" height={"100%"} p={2} pb={2} pt={0}>
            {body}
        </Box>
    );
};

export default Invoice;