import React from "react";
import { formatMessage, PaneLayout } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../../routes";
import {
  useActiveImplementation,
  useActiveOrganization,
  useDisplayName,
} from "../../../providers/UserProvider";
import AbstractorList from "./components/OrganizationList";
import AbstractorDetails from "./components/OrganizationDetails";
import AdminOrganizationProvider from "../../../providers/AdminOrganizationProvider";

export const useAdminOrganizationBreadcrumb = () => {
  const org = useActiveOrganization() || {};
  const impl = useActiveImplementation() || {};
  const userName = useDisplayName();
  return {
    label: formatMessage(
      {
        id: "AdminOrganization.breadcrumb",
        defaultMessage: "Organizations",
      },
      { orgName: org.name, implName: impl.name, userName: userName }
    ),
    url: buildUrl(routesKeys.WORK_SUMMARY, {
      orgKey: org.key,
      implKey: impl.key,
    }),
  };
};

const AdminOrganization = () => {
  return (
    <AdminOrganizationProvider>
      <PaneLayout style={{paddingTop: 0, paddingBottom: 1}}>
        <AbstractorList />
        <AbstractorDetails />
      </PaneLayout>
    </AdminOrganizationProvider>
  );
};

AdminOrganization.propTypes = {};
AdminOrganization.label = "Organizations";
AdminOrganization.url = "/armus/adas-admin/organization";
AdminOrganization.breadcrumb = useAdminOrganizationBreadcrumb;
AdminOrganization.secured = {
  implKey: "adas-admin",
};

export default AdminOrganization;
