import React from "react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import CompleteIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/HelpOutline";
import InvalidIcon from "@mui/icons-material/RemoveCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StartIcon from "@mui/icons-material/PostAdd";
import AssignIcon from "@mui/icons-material/AccountCircle";
import { formatMessage, makeSxStyles } from "@armus/armus-dashboard";
import { useWorkDetailsTransition, transitionToTypes } from "../../../../providers/WorkDetailsTransitionProvider";
import moment from "moment-timezone";
import { safeDateFormat } from "../../../../lib/dateHelpers";

const useStyles = makeSxStyles(theme => ({
    root: {
        "& button": {
        },
        "& div": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    success: {
        "&.MuiButton-outlined": {
            color: theme.palette.success.main,
        },
        "&.MuiButton-contained": {
            backgroundColor: theme.palette.success.main,
            boxShadow: "none",
            "&:hover": {
                backgroundColor: theme.palette.success.dark,
            }
        }
    },
    primary: {
        "&.MuiButton-outlinedPrimary:hover": {
            borderColor: theme.palette.primary.main,
        }
    }
}));

const WorkDetailsTransitionActionView = (props) => {
    const {
        workDetails,
        isMyWork,
        isUnassigned,
        onStartProgress,
        onComplete,
        onInvalid,
        onRequestInformation,
        onScheduleFollowUp,
        onContinue
    } = props;
    const classes = useStyles();
    let content = null;

    const status = workDetails.status;

    const startButton = {
        label: formatMessage({id: "workDetails.actionButton.startButton", defaultMessage: "Start Progress"}),
        sx: classes.success,
        icon: <StartIcon />,
        onClick: onStartProgress,
        color: "success",
    };
    const invalidButton = {
        label: formatMessage({id: "workDetails.actionButton.invalidButton", defaultMessage: "Mark As Invalid"}),
        icon: <InvalidIcon />,
        onClick: onInvalid,
        color: "default",
    };
    const requestInformationButton = {
        label: formatMessage({id: "workDetails.actionButton.requestInformationButton", defaultMessage: "Request Information"}),
        sx: classes.primary,
        icon: <InfoIcon />,
        onClick: onRequestInformation,
        color: "info",
    };
    const completeButton = {
        label: formatMessage({id: "workDetails.actionButton.completeButton", defaultMessage: "Complete"}),
        icon: <CompleteIcon />,
        onClick: onComplete,
        color: "info",
    };
    const continueButton = {
        ...startButton,
        label: formatMessage({id: "workDetails.actionButton.continueButton", defaultMessage: "Continue Progress"}),
        sx: undefined,
        onClick: onContinue,
        color: "info",
    };
    const assignButton = {
        ...continueButton,
        label: formatMessage({id: "workDetails.actionButton.assignButton", defaultMessage: "Assign To Me"}),
        icon: <AssignIcon />,
    };
    const scheduleFollowUpButton = {
        label: formatMessage({id: "workDetails.actionButton.scheduleFollowUpButton", defaultMessage: "Schedule Follow-up"}),
        icon: <ScheduleIcon />,
        onClick: onScheduleFollowUp,
        color: "info",
    };

    let actions = [];
    let extraMessage = null;

    if(status === "NEW") {
        if(!workDetails.visibleAfter) {
            actions.push(startButton);
        }
        else if(moment(workDetails.visibleAfter).diff(moment()) < 0 ) {
            actions.push(startButton);
        }
        else {
            extraMessage = (
                <Box component={"p"} mt={1} mb={0}>
                    This work can be started after {safeDateFormat(workDetails.visibleAfter, undefined, true)}.
                </Box>
            );
        }
        actions.push(invalidButton);
    }
    else if(status === "INVALID") {
        // anybody can start progress on invalid work
        actions.push(startButton);
    }
    else if(isUnassigned) {
        actions.push(assignButton, invalidButton);
    }
    else if(isMyWork) {
        switch(status) {
            case "IN_PROGRESS":
                actions.push(completeButton, requestInformationButton, invalidButton);
                break;
            case "REQUIRES_INFORMATION":
                actions.push(continueButton, invalidButton);
                break;
            case "COMPLETE":
                actions.push(scheduleFollowUpButton);
                break;
            default:
        }
    }

    if(actions.length === 0) {
        content = (
            <Box component={"p"} mt={1}>
                This work item is being handled by another user.
            </Box>
        );
    }
    else {
        content = (
            <ButtonGroup disableElevation variant="outlined" fullWidth={true} color="secondary">
                {actions.map((btn, index) => (
                    <Button
                        key={index}
                        className={btn.className}
                        color={btn.color}
                        onClick={btn.onClick}
                        size="medium"
                        variant={index === 0 && btn !== invalidButton ? "contained" : "outlined"}
                    >
                        {btn.icon}&nbsp;{btn.label}
                    </Button>
                ))}
            </ButtonGroup>
        );
    }

    return (
        <Box sx={classes.root}>
            {content}
            {extraMessage}
        </Box>
    );
};

const WorkDetailsTransitionAction = (props) => {
    const {onStartTransition} = useWorkDetailsTransition();
    return (
        <WorkDetailsTransitionActionView
            {...props}
            onStartProgress={() => {
                onStartTransition(transitionToTypes.IN_PROGRESS)
            }}
            onComplete={() => {
                onStartTransition(transitionToTypes.COMPLETE)
            }}
            onInvalid={() => {
                onStartTransition(transitionToTypes.INVALID)
            }}
            onRequestInformation={() => {
                onStartTransition(transitionToTypes.REQUIRES_INFORMATION)
            }}
            onScheduleFollowUp={() => {
                onStartTransition(transitionToTypes.SCHEDULE_FOLLOWUP)
            }}
            onContinue={() => {
                onStartTransition(transitionToTypes.CONTINUE_PROGRESS)
            }}
        />
    );
};

export default WorkDetailsTransitionAction;