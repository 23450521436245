import React from "react";
import Header from "./Header";
import config from "../../../config";
import Dialog from "./Dialog";
import Notifications from "./Notifications";
import {
  getDefaultRoutePath,
  useActiveOrganization,
  useActiveImplementation,
  useDisplayName,
  useNavItems,
  useOrganizations,
  useSecuredIsAccessible,
  logoutUser,
  useActiveOrgKey,
} from "../../providers/UserProvider";
import {
  formatMessage,
  withTheme,
  Footer,
  useDialogs,
  useOnboarding,
  AboutLayout,
} from "@armus/armus-dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import OnboardingIcon from "@mui/icons-material/MyLocation";
import OrgIcon from "@mui/icons-material/Business";
import AbstractorIcon from "@mui/icons-material/Keyboard";
import ReportingIcon from "@mui/icons-material/Report";
import VerificationIcon from "@mui/icons-material/Verified";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import InvoiceIcon from "@mui/icons-material/Receipt";
import AboutIcon from "@mui/icons-material/QuestionMark";
import LightIcon from "@mui/icons-material/LightMode";
import DarkIcon from "@mui/icons-material/DarkMode";
import SupportIcon from "@mui/icons-material/Support";
import LogoutIcon from "@mui/icons-material/Logout";
import Invoicing from "../Invoicing/Invoicing";
import Work from "../Work/Work";
import Reporting from "../Reporting/Reporting";
import Verification from "../Verification/Verification";
import Admin from "../Admin/AdminAbstractor/AdminAbstractor";
import ArmusLogo from "./armus-logo-by-healthcatalyst.png";

function PageLayout({ theme, themes, setTheme, children }) {
  const orgKey = useActiveOrgKey();
  const currentOrg = useActiveOrganization();
  const currentImpl = useActiveImplementation() || {};
  const userName = useDisplayName();
  const userLinks = useNavItems();
  const organizations = useOrganizations();
  const isAccessible = useSecuredIsAccessible();
  const onboarding = useOnboarding();
  const { onShow, onHide } = useDialogs();

  const leftNavLinks = [
    {
      key: "apps",
      label: currentImpl.name || "ADAM",
      url: window.location.pathname,
      items: [],
    },
  ];
  const canViewAbstractable = isAccessible(Work.secured);
  const canViewInvoice = isAccessible(Invoicing.secured);
  const canViewReport = isAccessible(Reporting.secured);
  const canViewVerification = isAccessible(Verification.secured);
  const canViewAdmin = isAccessible(Admin.secured);
  if (canViewAbstractable) {
    leftNavLinks[0].items.push({
      key: "adas",
      label: "Abstractor",
      icon: <AbstractorIcon />,
      url: `/${orgKey}/adas`,
    });
  }
  if (canViewInvoice) {
    leftNavLinks[0].items.push({
      key: "adas-invoicing",
      label: "Invoicing",
      icon: <InvoiceIcon />,
      url: `/${orgKey}/adas-invoicing`,
    });
  }
  if (canViewReport) {
    leftNavLinks[0].items.push({
      key: "adas-reporting",
      label: "Reporting",
      icon: <ReportingIcon />,
      url: `/${orgKey}/adas-reporting`,
    });
  }
  if (canViewVerification) {
    leftNavLinks[0].items.push({
      key: "adas-verification",
      label: "Verification",
      icon: <VerificationIcon />,
      url: `/${orgKey}/adas-verification/work/`,
    });
  }
  if (canViewAdmin) {
    leftNavLinks[0].items.push(null);
    leftNavLinks[0].items.push({
      key: "adas-admin",
      label: "Admin",
      icon: <AdminIcon />,
      url: `/${orgKey}/adas-admin`,
    });
  }

  const rightNavLinks = [];
  if (process.env.NODE_ENV === "development") {
    if (config.mocks.showMockMenu) {
      // add development links
      const links = require("../../lib/mocks").default.buildMockSetNav();
      rightNavLinks.push({
        key: "mocks",
        label: "MOCKS",
        items: links,
      });
    }
  }

  const profileNavLinks = [
    {
      label: formatMessage({ id: "about", defaultMessage: "About" }),
      icon: <AboutIcon />,
      onClick: () => {
        let mockMessage = "";
        if (process.env.NODE_ENV === "development") {
          const mockSet = require("../../lib/mocks").default.getMockSet();
          mockMessage = (
            <React.Fragment>
              <strong>Mock Set:</strong> {mockSet.label}
            </React.Fragment>
          );
        }
        onShow({
          title: `${formatMessage({
            id: "about",
            defaultMessage: "About",
          })} ${formatMessage({
            id: "title",
            defaultMessage: "ARMUS ADAM",
          })}`,
          children: (
            <AboutLayout 
              title={process.env.REACT_APP_NAME} 
              version={process.env.REACT_APP_VERSION} 
              onClose={onHide}
            >
              <code>
                {mockMessage}
              </code>
            </AboutLayout>
          ),
        });
      },
    },
    {
      key: "help",
      icon: <OnboardingIcon />,
      label: "Take a guided tour",
      onClick: () => {
        onboarding.onStart(true) // clear force restart.
      }
    },
    null,
    ...themes.map((theme, themeIndex) => ({
      label: formatMessage({
        id: "themeLabel" + themeIndex,
        defaultMessage: theme.name,
      }),
      icon: [<LightIcon />, <DarkIcon />][themeIndex] || null,
      onClick: () => {
        setTheme(themeIndex);
      },
    })),
    null,
    {
      label: formatMessage({
        id: "armusSupport",
        defaultMessage: "ARMUS Support",
      }),
      icon: <SupportIcon />,
      url: config.endpoints.support,
    },
    null, // divider
    {
      label: formatMessage({ id: "logout", defaultMessage: "Log out" }),
      icon: <LogoutIcon />,
      onClick: logoutUser,
    },
  ];

  if (userLinks.length) {
    // append the users other application links
    rightNavLinks.push({
      icon: <MenuIcon />,
      items: userLinks,
    });
  }
  if (organizations && organizations.length > 1) {
    let organizationLabel = "Organizations";
    if (currentOrg) {
      organizationLabel = currentOrg.name || currentOrg.key;
    }
    const orgLinks = {
      label: "Organization: " + ((organizationLabel || "").length > 12 ? organizationLabel.substring(0, 12) + "..." : organizationLabel),
      url: "",
      items: organizations
        .map((org) => ({
          label: org.name || org.key,
          icon: <OrgIcon />,
          url: getDefaultRoutePath(org.key, org.implementations[0].key),
          selected: true,
        }))
        .sort((a, b) =>
          a.label
            .toString()
            .localeCompare(
              b.label.toString(),
              navigator.languages[0] || navigator.language,
              {
                numeric: true,
                ignorePunctuation: true,
              }
            )
        ),
    };
    leftNavLinks.unshift(orgLinks);
  }

  return (
    <React.Fragment key="PageLayout">
      <Header
        key="header"
        leftNav={leftNavLinks}
        rightNav={rightNavLinks}
        profileNav={profileNavLinks}
        userName={userName}
        loginUrl={config.endpoints.login}
        logoUrl={<>ADAM</>}
        cobrandUrl={ArmusLogo}
        cobrandColor={theme.palette.mode}
      />

      {children}

      <Footer />
      <Dialog />
      <Notifications />
    </React.Fragment>
  );
}

export default withTheme(PageLayout);
