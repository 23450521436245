
import { Main, makeSxStyles } from "@armus/armus-dashboard";
import { Box, Grid, MenuItem, MenuList, Paper, Typography, Divider } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import routeKeys, { buildUrl } from "../../../routes";
import history from "../../lib/history";
import PageLayout from "../components/PageLayout";
import Invoice from "./components/Invoice";


export const buildSectionBreadcrumb = (label, route) => () => {
  return {
      label: label,
      url: route
  }
};

const AbstractorInvoice = (props) => {
  return <Invoice {...props} />
};
AbstractorInvoice.propTypes = {};
AbstractorInvoice.label = "Abstractor Invoice";
AbstractorInvoice.url = "/armus/adas-invoicing/abstractor";
AbstractorInvoice.breadcrumb = buildSectionBreadcrumb(AbstractorInvoice.label, AbstractorInvoice.url);

const OrganizationInvoice = (props) => {
  return <Invoice {...props} />
};
OrganizationInvoice.propTypes = {};
OrganizationInvoice.label = "Organization Invoice";
OrganizationInvoice.url = "/armus/adas-invoicing/organization";
OrganizationInvoice.breadcrumb = buildSectionBreadcrumb(OrganizationInvoice.label, OrganizationInvoice.url);

const SECTIONS_MAP = {
  "organization": OrganizationInvoice,
  "abstractor": AbstractorInvoice,
};
const sections = Object.values(SECTIONS_MAP);


const useStyles = makeSxStyles((theme) => ({
  root: {
  }
}));

const SectionNotFound = () => <>404 NOT FOUND</>;
SectionNotFound.breadcrumb = () => ({
  label: "404 Not Found",
  url: "/armus/adas-invoicing/"
});

const Invoicing = () => {
  const classes = useStyles();
  const params = useParams();
  const Content = SECTIONS_MAP[params.section] || SectionNotFound;
  const section = params.section;
  const defaultUrl = buildUrl(routeKeys.INVOICING_ORGANIZATION, params);
  React.useEffect(() => {
    if(!section) {
      history.push(defaultUrl);
    }
  }, [section, defaultUrl]);

  const breadcrumb = Content.breadcrumb();
  const breadcrumbs = [
    {
      label: "Invoicing Home",
      url: "/armus/adas-Invoicing/"
    }, 
    breadcrumb
  ];

  return (
    <PageLayout key="Invoicing">
      <Main breadcrumbs={breadcrumbs} fullWidth={true} fullHeight={true}>
          <Box sx={classes.root} overflow="auto" height={"100%"} p={2}>
            <Grid container style={{height: "100%"}}>
              <Grid xs={2}>
                <Box component={Paper}>
                  <Typography variant="h6" component={Box} p={2} pb={1} pt={2}>Invoicing Menu</Typography>
                  <MenuList>
                    <Divider />
                    {sections.map((section) => {
                      const isActive = section === Content;
                      return (
                        <MenuItem component={Link} selected={isActive} to={section.url} >
                          {section.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Box>
              </Grid>
              <Grid xs={10} style={{height: "100%"}}>
                <Content title={Content.label} section={section} />
              </Grid>
            </Grid>
          </Box>
      </Main>
    </PageLayout>
  );
}

Invoicing.secured = {
  implKey: "adas-invoicing"
};

export default Invoicing;