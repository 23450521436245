import React from "react";
import { formatMessage, Main, PaneLayout } from "@armus/armus-dashboard";
import routesKeys, { buildUrl } from "../../../routes";
import PageLayout from "../components/PageLayout";
import {
  useActiveImplementation,
  useActiveOrganization,
  useDisplayName,
} from "../../providers/UserProvider";
import WorkList from "./components/WorkList";
import WorkDetails from "../Work/WorkDetails/WorkDetails";
import ErrorPage from "../ErrorPage";
import { useParams } from "react-router";
import { useWorkDetailsBreadcrumb } from "../Work/Work";

export const useVerificatyionBreadcrumb = () => {
  const org = useActiveOrganization() || {};
  const impl = useActiveImplementation() || {};
  const userName = useDisplayName();
  return {
    label: formatMessage(
      {
        id: "Verification.breadcrumb",
        defaultMessage: "Verification",
      },
      { orgName: org.name, implName: impl.name, userName: userName }
    ),
    url: buildUrl(routesKeys.VERIFICATION_LIST, {
      orgKey: org.key,
      implKey: impl.key,
    }),
  };
};

const Verification = () => {
  const org = useActiveOrganization();
  const impl = useActiveImplementation();
  const isValidURL = !!org && !!impl;

  const params = useParams();
  const { workId } = params;
  const breadcrumbs = [useVerificatyionBreadcrumb()];
  const workDetailsBreadcrumb = useWorkDetailsBreadcrumb();
  if (workId) {
    breadcrumbs.push(workDetailsBreadcrumb);
  }
  if (!isValidURL) {
    // the url is invalid show 404 page.
    return <ErrorPage statusCode={404} />;
  }

  let body = (
    <PaneLayout>
      <WorkList />
      <WorkDetails variant="verification" />
    </PaneLayout>
  );

  return (
    <PageLayout>
      <Main breadcrumbs={breadcrumbs} fullWidth={true} fullHeight={true}>
        {body}
      </Main>
    </PageLayout>
  );
};

Verification.propTypes = {};

Verification.secured = {
  implKey: "adas-verification",
};

export default Verification;
